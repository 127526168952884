import {
    ADD_NEW_MEMBER,
    GET_EXISTING_MEMBER,
    REMOVE_MEMBERS,
    UPDATE_MEMBER,
    UPDATE_REGISTER,
    REMOVE_REGISTERS,
    REMOVE_PENDING,
    SEND_INVITES,
    SUSPEND_PROPERTY_GUEST,
    CREATE_MEMBER_USER,
    ADD_EDIT_MEMBER_DEVICE,
    REMOVE_MEMBER_DEVICE,
    TRANSFER_MEMBER
} from '../actions/types';

import { all, fork, takeLatest, put, call, select } from 'redux-saga/effects';
import {
    collection,
    query,
    where,
    setDoc,
    getDocs,
    doc,
    updateDoc,
    deleteDoc,
    getDoc,
    deleteField,
    Timestamp
} from 'firebase/firestore';
import { ref, update, remove, set } from 'firebase/database';
import { getDownloadURL, ref as sRef, uploadString } from 'firebase/storage';

import {
    db,
    rtdb,
    timeStampNow,
    func,
    fsTimeStampNow,
    storage,
    timeStampNowSeconds
} from 'config/firebase';
import { httpsCallable } from 'firebase/functions';

import {
    setExistingMember,
    addingNewMember,
    addingNewMemberSuccess,
    createMemberUserSuccess,
    createMemberUserFailure,
    addEditingMemberDeviceSuccess,
    addEditingMemberDeviceFailure,
    removingMemberDeviceSuccess,
    removingMemberDeviceFailure,
    updatingRegisterSuccess,
    updatingRegisterFailure,
    transferMemberFailure,
    transferMemberSuccess
} from '../actions/Members';

import { generateInviteCode, tsFromJsDate } from '../../utils/Helpers';

import { ACCESS_KEY_SCHEMA, GUEST_INVITE_SCHEMA, unitedStates } from 'utils/constants';
import * as selectors from './Selectors';
import { openSnackbar } from 'store/actions/Snackbar';
import { log } from 'utils/Loggers';
import { addAuthUser, loginUserEmailPasswordRequest } from './Auth';

const userImagesRef = sRef(storage, 'users');

const userCollectionRef = collection(db, 'users');
const guestInvitesCollectionRef = collection(db, 'guest_invites');
const accesKeysCollectionRef = collection(db, 'access_keys');
const propertiesCollectionRef = collection(db, 'properties');
const devicesCollectionRef = collection(db, 'devices');

const updatingOccupantEmailAddress = httpsCallable(func, 'updateOccupantEmailAddress');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Add/Invite Members ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const existingByEmail = async email => {
    const q = query(userCollectionRef, where('email', '==', email));

    return getDocs(q)
        .then(querySnapshot => {
            if (querySnapshot.empty) {
                return [];
            }

            const emailExist = [];
            querySnapshot.forEach(doc => {
                emailExist.push(doc.data());
            });

            return emailExist;
        })
        .catch(error => {
            throw error;
        });
};

const existingByPhone = async phone => {
    const q = query(userCollectionRef, where('phone_number', '==', phone));

    return getDocs(q)
        .then(querySnapshot => {
            if (querySnapshot.empty) {
                return [];
            }

            const phoneExist = [];
            querySnapshot.forEach(doc => {
                phoneExist.push(doc.data());
            });

            return phoneExist;
        })
        .catch(error => {
            throw error;
        });
};

const checkExistingMemberRequest = async member => {
    const { phone, email } = member;

    try {
        const emailExistence = email ? await existingByEmail(email) : [];
        const phoneExistence = phone ? await existingByPhone(phone) : [];

        const existence = [...emailExistence, ...phoneExistence];
        const filteredExistence = existence.reduce((reducedExistence, current) => {
            if (!reducedExistence.some(x => x.uid === current.uid)) {
                reducedExistence.push(current);
            }
            return reducedExistence;
        }, []);

        return { existing: filteredExistence };
    } catch (error) {
        return { error };
    }
};

export function* checkExistingMember({ payload }) {
    const { member } = payload;
    const { existing, error } = yield call(() =>
        checkExistingMemberRequest({ email: member.email, phone: member.phone_number })
    );
    if (existing) {
        if (existing.length) {
            yield put(setExistingMember(existing));
        } else {
            yield put(
                addingNewMember({
                    member,
                    exist: false
                })
            );
        }
    } else {
        // Error Handling for sentry with put and maybe UI message
        console.log(
            'Check Existing Error: error checking for existing member when adding',
            {
                error
            }
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Add/Invite Members ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const addNewMemberRequest = async ({ invite, user, org }) => {
    const getExistingInvites = async () => {
        if (invite?.phone) {
            const phoneNumber = `${invite.phone.code}${invite.phone.number}`;
            const propertyId = invite.address.id;
            const q = query(
                guestInvitesCollectionRef,
                where('phone_number', '==', phoneNumber),
                where('property_id', '==', propertyId)
            );
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const existingInvites = [];
                querySnapshot.forEach(doc => {
                    existingInvites.push(doc.data());
                });
                return existingInvites;
            } else {
                return [];
            }
        } else {
            return [];
        }
    };

    const res = await getExistingInvites();
    const inviteExist = res.length ? res[0] : null;
    const guestInviteDocRef = inviteExist
        ? doc(guestInvitesCollectionRef, inviteExist.invite_id)
        : doc(guestInvitesCollectionRef);
    const guestInviteKeyId = guestInviteDocRef.id;
    const data = GUEST_INVITE_SCHEMA;
    const firstName = invite.first_name;
    const lastName = invite.last_name;
    const email = invite.email ? invite.email : null;
    const phone = invite.phone ? invite.phone : null;
    const phoneNumber = invite.phone_number ? invite.phone_number : null;
    const inviteCode = generateInviteCode();
    const role = 'resi';

    const address =
        role === 'resi'
            ? {
                  address_1: invite.address_1,
                  address_2:
                      invite.address.address_2 && invite.address.address_2.trim() !== ''
                          ? invite.address.address_2
                          : null,
                  city: invite.address.city,
                  full_address: invite.address.full_address,
                  latitude: invite.address.latitude,
                  longitude: invite.address.longitude,
                  state: invite.address.state,
                  zip: invite.address.zip
              }
            : org.address;
    const orgId = org.org_id;
    const orgName = org.org_name;

    const guestInviteData = Object.assign(data, {
        access_days: role === 'resi' ? null : ['mon', 'tue', 'wed', 'thu', 'fri'],
        access_end_time:
            role === 'resi'
                ? null
                : {
                      hours: 17,
                      minutes: 0
                  },
        access_expires: null,
        access_start_time:
            role === 'resi'
                ? null
                : {
                      hours: 9,
                      minutes: 0
                  },
        address: address,
        creator_first_name: user.first_name,
        creator_id: user.uid,
        creator_last_name: user.last_name,
        email: email,
        first_name: firstName,
        invite_code: inviteCode,
        invite_id: guestInviteKeyId,
        invite_status: 'pending',
        invited_at: timeStampNow(),
        last_name: lastName,
        org_id: orgId,
        org_name: orgName,
        permissions: invite.permissions,
        phone: phone,
        phone_number: phoneNumber,
        property_id: invite.address.id,
        role: role,
        suspended: false,
        type: invite.type
    });

    return setDoc(guestInviteDocRef, guestInviteData)
        .then(() => {
            return { res: true };
        })
        .catch(error => {
            throw new Error(error);
        });
};

const addExistingMemberRequest = async ({ member, user, org, exist }) => {
    const getAccessKey = async () => {
        const q = query(
            accesKeysCollectionRef,
            where('consumer_id', '==', exist.uid),
            where('property_id', '==', member.address.id)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const personalKeys = [];
            querySnapshot.forEach(doc => {
                personalKeys.push(doc.data());
            });
            return personalKeys.filter(key => key.org_id === org.org_id);
        } else {
            return [];
        }
    };

    const res = await getAccessKey();
    const keyExist = res.length ? res[0] : null;

    //  const role = exist && exist.role === 'guest' ? 'resi' : member.type; ** Revisit with Vendor additions
    const role = 'resi';
    const userDocRef = doc(userCollectionRef, exist.uid);
    const orgId = org.org_id;
    const orgName = org.org_name;
    const accessKeyDocRef = keyExist
        ? doc(accesKeysCollectionRef, keyExist.key_id)
        : doc(accesKeysCollectionRef);
    const accessKeyId = keyExist ? keyExist.key_id : accessKeyDocRef.id;
    const rtdbMembersRef = ref(rtdb, `/orgs/${orgId}/members/${exist.uid}`);
    const data = ACCESS_KEY_SCHEMA;
    const tsNow = timeStampNow();
    const type = role === 'resi' ? 'member' : 'short-term';
    const propertyId = member.address.id;
    const properties = exist?.properties
        ? {
              ...exist.properties,
              [propertyId]: {
                  address: {
                      address_1: member.address.address_1,
                      address_2: member.address.address_2,
                      city: member.address.city,
                      full_address: member.address.full_address,
                      latitude: member.address.latitude,
                      longitude: member.address.longitude,
                      state: member.address.state,
                      zip: member.address.zip
                  },
                  permissions: {
                      calls: member.permissions.calls,
                      invite: member.permissions.invite
                  },
                  id: propertyId,
                  notifications: true,
                  org_id: orgId,
                  primary: member.type === 'member' ? true : false,
                  role: role,
                  suspended: false,
                  type: member.type
              }
          }
        : {
              [propertyId]: {
                  address: {
                      address_1: member.address.address_1,
                      address_2: member.address.address_2,
                      city: member.address.city,
                      full_address: member.address.full_address,
                      latitude: member.address.latitude,
                      longitude: member.address.longitude,
                      state: member.address.state,
                      zip: member.address.zip
                  },
                  permissions: {
                      calls: member.permissions.calls,
                      invite: member.permissions.invite
                  },
                  id: propertyId,
                  notifications: true,
                  org_id: orgId,
                  primary: member.type === 'member' ? true : false,
                  role: role,
                  suspended: false,
                  type: member.type
              }
          };

    const addFrag = member.address_1.split(' ');
    const streetNum = addFrag ? addFrag[0] : '';

    const accessKeyData = Object.assign(data, {
        access_begins: tsNow,
        access_created: tsNow,
        access_days: role === 'resi' ? null : ['mon', 'tue', 'wed', 'thu', 'fri'],
        access_end_time:
            role === 'resi'
                ? null
                : {
                      hours: 17,
                      minutes: 0
                  },
        access_expires: null,
        access_start_time:
            role === 'resi'
                ? null
                : {
                      hours: 9,
                      minutes: 0
                  },
        address:
            role === 'vendor'
                ? {
                      address_1: org.address.address_1,
                      address_2: org.address.address_2,
                      city: org.address.city,
                      full_address: org.address.full_address,
                      latitude: org.address.latitude,
                      longitude: org.address.longitude,
                      state: org.address.state,
                      zip: org.address.zip
                  }
                : {
                      address_1: member.address.address_1,
                      address_2: member.address.address_2,
                      city: member.address.city,
                      full_address: member.address.full_address,
                      latitude: member.address.latitude,
                      longitude: member.address.longitude,
                      state: member.address.state,
                      zip: member.address.zip
                  },
        company_name: role === 'vendor' ? member.company_name : null,
        consumer_id: exist.uid,
        creator_first_name: user.first_name,
        creator_id: user.uid,
        creator_last_name: user.last_name,
        email: exist.email,
        first_name: exist.first_name,
        key_id: accessKeyId,
        last_name: exist.last_name,
        org_id: orgId,
        org_name: orgName,
        phone: member.phone ? member.phone : exist.phone,
        phone_number: member.phone_number ? member.phone_number : exist.phone_number,
        photo_id: exist.photo_id,
        plates:
            keyExist && keyExist.plates.length
                ? keyExist.plates
                : exist.plates && exist.plates.length
                ? exist.plates
                : [],
        property_id: propertyId,
        role: role,
        suspended: false,
        validated: false,
        active: true,
        type: type,
        vehicles:
            keyExist && keyExist.vehicles.length
                ? keyExist.vehicles
                : exist.vehicles && exist.vehicles.length
                ? exist.vehicles
                : []
    });
    ///////////////////////////////////////////// HERE!!!! /////////////////////////////////
    const filteredProperties = Object.values(properties).filter(
        property => property.org_id === orgId
    );
    let existingProperties = {};

    filteredProperties.map(property => {
        const addFrag = property.address.address_1.split(' ');
        const streetNum = addFrag ? addFrag[0] : '';
        existingProperties = {
            ...existingProperties,
            [property.id]: {
                address: property.address.address_1,
                id: property.id,
                street_num: streetNum
            }
        };
        return property;
    });

    const memberData = {
        access_group: 1,
        access_key: true,
        directory: member.permissions.calls,
        email: exist?.email ? exist.email : '',
        first_name: exist.first_name,
        last_name: exist.last_name,
        phone: exist?.phone?.number ? exist.phone.number : '',
        properties: {
            ...existingProperties,
            [propertyId]: {
                address: member.address.address_1,
                id: propertyId,
                street_num: streetNum
            }
        },
        role: role,
        suspended: false,
        uid: exist.uid
    };

    await updateDoc(userDocRef, {
        properties,
        role: role,
        type: 'member',
        property_ids: exist.property_ids.includes(propertyId)
            ? [...exist.property_ids]
            : [...exist.property_ids, propertyId],
        active_org_id: org.org_id,
        active_station_id: exist.uid,
        active_property_id: propertyId
    });

    if (keyExist) {
        await updateDoc(accessKeyDocRef, accessKeyData);
    } else {
        await setDoc(accessKeyDocRef, accessKeyData);
    }

    await set(rtdbMembersRef, memberData);

    return { res: true };
};

export function* addMember({ payload }) {
    const { member, exist } = payload;
    const userData = yield select(selectors._userData);
    const activeOrg = yield select(selectors._activeOrg);
    const selectedOrg = yield select(selectors._selectedOrg);

    const org = activeOrg || selectedOrg;

    const { res, error } = !exist
        ? yield call(() => addNewMemberRequest({ invite: member, user: userData, org }))
        : yield call(() =>
              addExistingMemberRequest({ member, user: userData, org, exist: exist[0] })
          );

    if (res) {
        yield put(addingNewMemberSuccess());
        yield put(
            openSnackbar({
                open: true,
                message: 'Success: Member has been added!',
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
    } else {
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: Member was not added successfully.`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
        log(`Error: error adding ${exist ? 'existing' : 'new'} member (FS)`, {
            error,
            member,
            exist,
            userData,
            org
        });
    }
}

export function* removeMemberRequest({ member, orgId, propertyId }) {
    const memberRef = ref(rtdb, '/');
    const userDocRef = doc(userCollectionRef, member.uid);
    const property = member.properties[propertyId];
    const role = property?.role;

    try {
        const remove = {};
        const multiOrgProperties =
            Object.values(member.properties).filter(property => property.org_id === orgId)
                .length > 1;
        const rtdbPath = multiOrgProperties
            ? `orgs/${orgId}/members/${member.uid}/properties/${propertyId}`
            : `orgs/${orgId}/members/${member.uid}`;
        remove[rtdbPath] = null;
        const keysQuery = query(
            accesKeysCollectionRef,
            where('org_id', '==', orgId),
            where('consumer_id', '==', member.uid),
            where('property_id', '==', propertyId),
            where('role', '==', `${role}`)
        );
        const keysSnapshot = yield call(getDocs, keysQuery);

        for (const doc of keysSnapshot.docs) {
            yield call(deleteDoc, doc.ref);
        }

        if (role === 'resi') {
            yield call(update, memberRef, remove);
        }

        const filteredProperties = Object.values(member.properties).filter(
            property => property.id !== propertyId
        );

        const stillResi = filteredProperties.length
            ? filteredProperties.some(property => property.role === 'resi')
            : false;

        const updatedPropertyAssign = stillResi
            ? filteredProperties.filter(property => property.role === 'resi')[0]
            : filteredProperties.length
            ? filteredProperties[0]
            : null;

        yield call(updateDoc, userDocRef, {
            active_property_id: updatedPropertyAssign ? updatedPropertyAssign.id : null,
            active_org_id: updatedPropertyAssign
                ? updatedPropertyAssign.org_id
                : member.active_org_id,
            primary_org: updatedPropertyAssign
                ? updatedPropertyAssign.org_id
                : member.primary_org,
            [`properties.${propertyId}`]: deleteField(),
            property_ids: member.property_ids.filter(id => id !== propertyId),
            role: updatedPropertyAssign ? updatedPropertyAssign.role : member.role,
            type: updatedPropertyAssign ? updatedPropertyAssign.type : member.type
        });

        /****** Per Corey - Just because a once Active property has been cleared ******/
        /****** of all members, we dont want to change the status to Inactive as ******/
        /****** that Inactive status will throw off billing because Inactive     ******/
        /****** status properties do not get billed.  Function setting status    ******/
        /****** shown below has been removed for the time being. - 01/23/25 MV   ******/

        // const remainingMembersQuery = query(
        //     userCollectionRef,
        //     where(`property_ids`, 'array-contains', propertyId)
        // );
        // const remainingMembersSnapshot = yield call(getDocs, remainingMembersQuery);
        // const hasRemainingMembers = !remainingMembersSnapshot.empty;
        // if (!hasRemainingMembers) {
        //     yield call(updatePropertyToStatus, orgId, propertyId, 'inactive');
        // }

        return { res: true };
    } catch (error) {
        throw new Error(error);
    }
}

export function* removeMember({ payload }) {
    const { member, orgId, propertyId } = payload;
    const { res, error } = yield call(() =>
        removeMemberRequest({ member, orgId, propertyId })
    );
    if (res) {
        yield put(
            openSnackbar({
                open: true,
                message: 'Success: Member has been removed!',
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
    } else {
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: Member was not removed successfully.`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
        log(`Error: error removing members (FS/RTDB)`, {
            error,
            member,
            orgId,
            propertyId
        });
    }
}

const updateMemberRequest = async ({ member, orgId, propertyId }) => {
    const rtdbMembersRef = ref(rtdb, `orgs/${orgId}/members/${member.uid}/`);
    const userDocRef = doc(userCollectionRef, member.uid);

    try {
        await update(rtdbMembersRef, {
            directory: member.directory,
            email: member.email.toLowerCase().trim(),
            first_name: member.first_name.toLowerCase().trim(),
            last_name: member.last_name.toLowerCase().trim(),
            phone: member.phone.number,
            suspended: member.suspended
        });

        if (member.emailChange)
            await updatingOccupantEmailAddress({ email: member.email, uid: member.uid });

        const keysQuery = query(
            accesKeysCollectionRef,
            where('org_id', '==', orgId),
            where('consumer_id', '==', member.uid),
            where('property_id', '==', propertyId),
            where('role', '==', 'resi')
        );

        const keysSnapshot = await getDocs(keysQuery);

        keysSnapshot.forEach(async document => {
            const keyId = document.data().key_id;
            await updateDoc(doc(accesKeysCollectionRef, keyId), {
                email: member.email.toLowerCase().trim(),
                first_name: member.first_name.toLowerCase().trim(),
                last_name: member.last_name.toLowerCase().trim(),
                phone: member.phone,
                phone_number: member.phone_number,
                suspended: member.suspended
            });
        });

        await updateDoc(userDocRef, {
            email: member.email.toLowerCase().trim(),
            first_name: member.first_name.toLowerCase().trim(),
            last_name: member.last_name.toLowerCase().trim(),
            phone: member.phone,
            phone_number: member.phone_number,
            [`properties.${propertyId}.permissions.calls`]: member.permissions.calls,
            [`properties.${propertyId}.permissions.invite`]: member.permissions.invite,
            [`properties.${propertyId}.suspended`]: member.suspended,
            [`properties.${propertyId}.type`]: member.type,
            [`properties.${propertyId}.primary`]: member.type === 'member' ? true : false
        });

        return { res: true };
    } catch (error) {
        throw new Error(error);
    }
};

export function* updateMember({ payload }) {
    const { member, orgId, propertyId } = payload;
    const { res, error } = yield call(() =>
        updateMemberRequest({ member, orgId, propertyId })
    );
    if (res) {
        yield put(
            openSnackbar({
                open: true,
                message: 'Success: Member has been updated!',
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
    } else {
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: Member was not updated successfully.`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
        log(`Error: error updating member (FS/RTDB)`, {
            error,
            member,
            orgId,
            propertyId
        });
    }
}

const transferMemberInRtdb = async (
    rtdbOrgMembersRef,
    member,
    fromPropertyId,
    toPropertyId,
    toPropertyData
) => {
    const memberPropertiesPath = `${member.uid}/properties`;
    const newPropertyData = {
        address: toPropertyData.address_1,
        id: toPropertyId,
        street_num: toPropertyData.address_1.split(/[^\d]+/)[0] || ''
    };

    const updates = {};
    updates[`${memberPropertiesPath}/${fromPropertyId}`] = null;
    updates[`${memberPropertiesPath}/${toPropertyId}`] = newPropertyData;

    await update(rtdbOrgMembersRef, updates);
};

function* transferMemberRequest({ member, fromPropertyId, toPropertyId, orgId }) {
    const userDocRef = doc(userCollectionRef, member.uid);

    const accessKeysQuery = query(
        accesKeysCollectionRef,
        where('consumer_id', '==', member.uid),
        where('property_id', '==', fromPropertyId)
    );

    const guestInvitesQuery = query(
        guestInvitesCollectionRef,
        where('creator_id', '==', member.uid),
        where('property_id', '==', fromPropertyId)
    );

    const userCreatedGuestAccessKeyQuery = query(
        accesKeysCollectionRef,
        where('creator_id', '==', member.uid),
        where('property_id', '==', fromPropertyId),
        where('role', '==', 'guest')
    );

    const devicesQuery = query(
        devicesCollectionRef,
        where('owner.uid', '==', member.uid),
        where('property_id', '==', fromPropertyId)
    );

    const toPropertyDocRef = doc(propertiesCollectionRef, toPropertyId);

    const rtdbOrgMembersRef = ref(rtdb, `orgs/${orgId}/members`);
    const rtdbInvitesRef = ref(rtdb, `orgs/${orgId}/invites`);

    try {
        const userSnapshot = yield getDoc(userDocRef);
        const toPropertySnapshot = yield getDoc(toPropertyDocRef);
        const toPropertyData = toPropertySnapshot.data();

        if (userSnapshot.exists()) {
            const userData = userSnapshot.data();

            const properties = { ...userData.properties };
            properties[toPropertyId] = { ...properties[fromPropertyId] };
            properties[toPropertyId].address = toPropertyData;
            properties[toPropertyId].id = toPropertyId;

            delete properties[fromPropertyId];

            // TODO: Check if we can transfer member from any property
            const active_property_id =
                userData.active_property_id === fromPropertyId
                    ? toPropertyId
                    : fromPropertyId;

            const property_ids = userData.property_ids
                .filter(id => id !== fromPropertyId)
                .concat(toPropertyId);

            yield updateDoc(userDocRef, {
                active_property_id,
                properties,
                property_ids
            });

            yield transferMemberInRtdb(
                rtdbOrgMembersRef,
                member,
                fromPropertyId,
                toPropertyId,
                toPropertyData
            );
        }

        const accessKeysSnapshot = yield getDocs(accessKeysQuery);
        for (const doc of accessKeysSnapshot.docs) {
            yield updateDoc(doc.ref, {
                property_id: toPropertyId,
                address: toPropertyData
            });
        }

        const guestInvitesSnapshot = yield getDocs(guestInvitesQuery);
        for (const doc of guestInvitesSnapshot.docs) {
            yield updateDoc(doc.ref, {
                property_id: toPropertyId,
                address: toPropertyData
            });
        }

        const devicesSnapshot = yield getDocs(devicesQuery);
        for (const deviceDoc of devicesSnapshot.docs) {
            yield updateDoc(deviceDoc.ref, {
                property_id: toPropertyId
            });
        }

        const userCreatedGuestAccessKeySnapshot = yield getDocs(
            userCreatedGuestAccessKeyQuery
        );

        for (const accessKeyDoc of userCreatedGuestAccessKeySnapshot.docs) {
            const accessKeyData = accessKeyDoc.data();

            const userDocRef = doc(userCollectionRef, accessKeyData.consumer_id);

            const userDocSnapshot = yield getDoc(userDocRef);

            const userData = userDocSnapshot.data();

            const properties = { ...userData.properties };
            properties[toPropertyId] = { ...properties[fromPropertyId] };
            properties[toPropertyId].address = toPropertyData;
            properties[toPropertyId].id = toPropertyId;

            delete properties[fromPropertyId];

            // TODO: Check if we can transfer member from any property
            const active_property_id =
                userData.active_property_id === fromPropertyId
                    ? toPropertyId
                    : fromPropertyId;

            const property_ids = userData.property_ids
                .filter(id => id !== fromPropertyId)
                .concat(toPropertyId);

            yield updateDoc(userDocRef, {
                active_property_id,
                properties,
                property_ids
            });

            try {
                yield transferMemberInRtdb(
                    rtdbOrgMembersRef,
                    userData,
                    fromPropertyId,
                    toPropertyId,
                    toPropertyData
                );
            } catch (e) {
                console.log('error transferring member in rtdb', e);
            }

            yield updateDoc(accessKeyDoc.ref, {
                property_id: toPropertyId,
                address: toPropertyData
            });
        }

        /****** Per Corey - Just because a once Active property has been cleared ******/
        /****** of all members, we dont want to change the status to Inactive as ******/
        /****** that Inactive status will throw off billing because Inactive     ******/
        /****** status properties do not get billed.  Function setting status    ******/
        /****** shown below has been removed for the time being. - 01/23/25 MV   ******/

        // const remainingMembersQuery = query(
        //     userCollectionRef,
        //     where('property_ids', 'array-contains', fromPropertyId)
        // );

        // const remainingMembersSnapshot = yield getDocs(remainingMembersQuery);

        // const hasRemainingMembers = !remainingMembersSnapshot.empty;

        // if (!hasRemainingMembers) {
        //     console.log('no members left');
        //     yield updatePropertyToStatus(orgId, fromPropertyId, 'inactive');
        // }

        if (toPropertyData.status === 'inactive') {
            yield updatePropertyToStatus(orgId, toPropertyId, 'active');
        }

        return { res: true };
    } catch (error) {
        console.error('Error during member transfer:', error);
        throw new Error(error.message);
    }
}

export function* transferMemberSaga({ payload }) {
    const { member, fromPropertyId, toPropertyId, orgId } = payload;

    try {
        const { res } = yield call(() =>
            transferMemberRequest({ member, fromPropertyId, toPropertyId, orgId })
        );

        if (res) {
            yield put(transferMemberSuccess());
            yield put(
                openSnackbar({
                    open: true,
                    message: 'Member transferred successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    }
                })
            );
        }
    } catch (error) {
        console.log(error);
        yield put(transferMemberFailure(error));
        yield put(
            openSnackbar({
                open: true,
                message: 'Failed to transfer member',
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
    }
}

export function* removeRegisters({ payload }) {
    const { registers } = payload;
    const org = yield select(selectors._activeOrg);
    const { res, error } = yield call(() => removeRegistersRequest({ registers, org }));

    if (res) {
        // Count successful deletions
        const successfulDeletions = registers.length - error.length;

        // Dispatch snackbar for successful deletions
        yield put(
            openSnackbar({
                open: true,
                message: `${successfulDeletions} ${
                    successfulDeletions === 1
                        ? 'register request was'
                        : 'register requests were'
                } successfully deleted`,
                variant: 'alert',
                alert: {
                    color: 'success',
                    variant: 'outlined'
                },
                close: false
            })
        );

        if (error.length > 0) {
            // If there are failed deletions, collect their names
            const failedRegisters = error.map(register => register.full_name);

            // Dispatch snackbar for failed deletions
            yield put(
                openSnackbar({
                    open: true,
                    message: `Failed to delete: ${failedRegisters.join(', ')}`,
                    variant: 'alert',
                    alert: {
                        color: 'error',
                        variant: 'outlined'
                    },
                    close: false
                })
            );
        }
    } else {
        // If none of the registers were able to be removed
        yield put(
            openSnackbar({
                open: true,
                message: 'None of the registers were able to be removed',
                variant: 'alert',
                alert: {
                    color: 'error',
                    variant: 'outlined'
                },
                close: false
            })
        );

        console.log(`Error: error removing registers (FS/RTDB)`, {
            error,
            registers,
            org
        });
    }
}

const removeRegistersRequest = async ({ registers, org }) => {
    const registerRef = ref(rtdb, '/');

    try {
        const remove = {};
        registers.forEach((member, index) => {
            remove[`registration/${org.org_id}/${member.id}`] = null;
        });

        await update(registerRef, remove);

        // If all deletions succeed, return success indicator along with empty error array
        return { res: true, error: [] };
    } catch (error) {
        console.log('Error removing registers:', error);

        // If any deletion fails, return success indicator as false and collect errors
        const errorArray = registers.map(register => ({ ...register, error }));
        return { res: false, error: errorArray };
    }
};

const updateRegisterRequest = async ({ register, org }) => {
    const registerRef = ref(rtdb, `registration/${org.org_id}/${register.id}`);

    try {
        await update(registerRef, {
            ...register
        });

        return { res: true };
    } catch (error) {
        throw new Error(error);
    }
};

export function* updateRegister({ payload }) {
    const { register } = payload;
    const org = yield select(selectors._activeOrg);
    const { res, error } = yield call(() => updateRegisterRequest({ register, org }));
    if (res) {
        yield put(updatingRegisterSuccess());
        yield put(
            openSnackbar({
                open: true,
                message: 'Success: Registration has been updated!',
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
    } else {
        yield put(updatingRegisterFailure(error));
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: Registration was not updated successfully.`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
        log(`Registration Error: updating regiastration (RTDB)`, {
            error,
            register,
            org
        });
    }
}

const removePendingRequest = async ({ pending }) => {
    const guestInvitesCollectionRef = collection(db, 'guest_invites');
    try {
        await Promise.all(
            pending.map(async invite => {
                await deleteDoc(doc(guestInvitesCollectionRef, invite.invite_id));
            })
        );

        // If all deletions succeed, return success indicator
        return { res: true, error: [] };
    } catch (error) {
        console.log('Error removing pending invites:', error);

        // If any deletion fails, return success indicator as false
        return { res: false, error: error.message }; // You can customize this error handling as needed
    }
};

export function* removePending({ payload }) {
    const { pending } = payload;
    const { res, error } = yield call(() => removePendingRequest({ pending }));
    if (res) {
        yield put(
            openSnackbar({
                open: true,
                message: `${pending.length} ${
                    pending.length === 1 ? 'pending invite was' : 'pending invites were'
                } successfully deleted`,
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
    } else {
        yield put(
            openSnackbar({
                open: true,
                message: `Failed to delete pending invites: ${error}`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
        log(`Error: error removing pending (FS/RTDB)`, {
            error,
            pending
        });
    }
}

const sendInvitesRequest = async ({ invites, user, org }) => {
    try {
        const generated = [];

        const getExistingInvites = async invite => {
            if (invite?.phone) {
                const phoneNumber = invite.phone_number;
                const propertyId = invite.property_id;
                const q = query(
                    guestInvitesCollectionRef,
                    where('phone_number', '==', phoneNumber),
                    where('property_id', '==', propertyId)
                );
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const existingInvites = [];
                    querySnapshot.forEach(doc => {
                        existingInvites.push(doc.data());
                    });
                    return existingInvites;
                } else {
                    return [];
                }
            } else {
                return [];
            }
        };

        for (const invite of invites) {
            const registrationRef = ref(rtdb, `registration/${org.org_id}/${invite.id}`);
            await remove(registrationRef);
            const exists = invite.exist
                ? await checkExistingMemberRequest({
                      email: invite.email,
                      phone: `${invite.phone.code}${invite.phone.number}`
                  })
                : null;
            if (exists) {
                const inviteCode = generateInviteCode();
                generated.push(`${inviteCode}`);
                const propertyDocRef = doc(propertiesCollectionRef, invite.property_id);
                const propertySnapshot = await getDoc(propertyDocRef);

                let propertyData = null;
                if (propertySnapshot.exists()) {
                    propertyData = propertySnapshot.data();
                    console.log('Property data:', propertyData);
                } else {
                    console.log('Document does not exist!');
                }

                const address = {
                    address_1: propertyData.address_1 ? propertyData.address_1 : null,
                    address_2: propertyData.address_2 ? propertyData.address_2 : null,
                    city: propertyData.city ? propertyData.city : null,
                    full_address: propertyData.full_address
                        ? propertyData.full_address
                        : null,
                    latitude: propertyData.latitude ? propertyData.latitude : null,
                    longitude: propertyData.longitude ? propertyData.longitude : null,
                    state: propertyData.state ? propertyData.state : null,
                    zip: propertyData.zip ? propertyData.zip : null,
                    id: invite.property_id
                };

                const member = { ...invite, address, address_1: address.address_1 };

                await addExistingMemberRequest({
                    member,
                    user,
                    org,
                    exist: exists.existing[0]
                });

                if (generated.length === invites.length) {
                    // console.log(`Total Invites Created: ${invites.length}`);
                    // console.log(JSON.stringify({ generated }, null, 0));
                    return { res: true };
                }
            } else {
                const res = await getExistingInvites(invite);
                const inviteExist = res.length ? res[0] : null;
                const guestInviteDocRef = inviteExist
                    ? doc(guestInvitesCollectionRef, inviteExist.invite_id)
                    : doc(guestInvitesCollectionRef);
                const guestInviteKeyId = guestInviteDocRef.id;
                const firstName = invite.first_name.toLowerCase();
                const lastName = invite.last_name.toLowerCase();
                const email = invite.email ? invite.email.toLowerCase() : null;
                const inviteCode = generateInviteCode();
                const propertyId = invite.property_id;
                const data = GUEST_INVITE_SCHEMA;

                generated.push(`${inviteCode}`);

                const propertyDocRef = doc(propertiesCollectionRef, invite.property_id);
                const propertySnapshot = await getDoc(propertyDocRef);

                let propertyData = null;
                if (propertySnapshot.exists()) {
                    propertyData = propertySnapshot.data();
                    console.log('Property data:', propertyData);
                } else {
                    console.log('Document does not exist!');
                }

                const address = {
                    address_1: propertyData.address_1 ? propertyData.address_1 : null,
                    address_2: propertyData.address_2 ? propertyData.address_2 : null,
                    city: propertyData.city ? propertyData.city : null,
                    full_address: propertyData.full_address
                        ? propertyData.full_address
                        : null,
                    latitude: propertyData.latitude ? propertyData.latitude : null,
                    longitude: propertyData.longitude ? propertyData.longitude : null,
                    state: propertyData.state ? propertyData.state : null,
                    zip: propertyData.zip ? propertyData.zip : null
                };
                const orgId = org.org_id;
                const orgName = org.org_name;

                const guestInviteData = Object.assign(data, {
                    address: address,
                    creator_first_name: user.first_name,
                    creator_id: user.uid,
                    creator_last_name: user.last_name,
                    email: email,
                    first_name: firstName,
                    invite_code: inviteCode,
                    invite_id: guestInviteKeyId,
                    invite_status: 'pending',
                    devices: invite?.devices ? Object.values(invite?.devices) : null,
                    invited_at: timeStampNow(),
                    last_name: lastName,
                    org_id: orgId,
                    org_name: orgName,
                    phone: invite.phone || null,
                    phone_number: invite.phone_number || null,
                    property_id: propertyId,
                    role: 'resi',
                    suspended: false,
                    type: invite.type,
                    permissions: invite.permissions
                });

                await setDoc(guestInviteDocRef, guestInviteData);

                if (generated.length === invites.length) {
                    // console.log(`Total Invites Created: ${invites.length}`);
                    // console.log(JSON.stringify({ generated }, null, 0));
                    return { res: true };
                }
            }
        }
    } catch (error) {
        console.log('Error sending invites:', error);
        throw new Error(error);
    }
};

export function* sendInvites({ payload }) {
    const { invites } = payload;
    const userData = yield select(selectors._userData);
    const org = yield select(selectors._activeOrg);
    try {
        const { res } = yield call(() =>
            sendInvitesRequest({ invites, user: userData, org })
        );

        if (res) {
            yield put(
                openSnackbar({
                    open: true,
                    message: `${invites.length} ${
                        invites.length === 1 ? 'invite was' : 'invites were'
                    } successfully sent`,
                    variant: 'alert',
                    alert: {
                        color: 'success',
                        variant: 'outlined'
                    },
                    close: false
                })
            );
            console.log('Invites successfully sent');
        } else {
            console.log('Error sending invites');
        }
    } catch (error) {
        log('Members Error: error sending invites (FS)', {
            error,
            invites,
            userData,
            org
        });
        yield put(
            openSnackbar({
                open: true,
                message: `Failed to send invites: ${error.message}`,
                variant: 'alert',
                alert: {
                    color: 'error',
                    variant: 'outlined'
                },
                close: false
            })
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////// Suspend Property Guest //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const suspendPropertyGuestRequest = async ({ member, orgId, propertyId }) => {
    const userDocRef = doc(userCollectionRef, member.uid);
    try {
        const keysQuery = query(
            accesKeysCollectionRef,
            where('org_id', '==', orgId),
            where('consumer_id', '==', member.uid),
            where('property_id', '==', propertyId),
            where('role', '==', 'guest')
        );

        const keysSnapshot = await getDocs(keysQuery);

        keysSnapshot.forEach(async document => {
            const keyId = document.data().key_id;
            await updateDoc(doc(accesKeysCollectionRef, keyId), {
                suspended: member.suspended
            });
        });

        await updateDoc(userDocRef, {
            [`properties.${propertyId}.suspended`]: member.suspended
        });

        return { res: true };
    } catch (error) {
        throw new Error(error);
    }
};

export function* suspendPropertyGuest({ payload }) {
    const { member, orgId, propertyId } = payload;
    const { res, error } = yield call(() =>
        suspendPropertyGuestRequest({ member, orgId, propertyId })
    );
    if (res) {
        yield put(
            openSnackbar({
                open: true,
                message: 'Success: Guest member has been updated!',
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
    } else {
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: Guest member was not updated successfully.`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
        log(`Error: error suspending guest member (FS/RTDB)`, {
            error,
            member,
            orgId,
            propertyId
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Create member user ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* createMemberUser({ payload }) {
    const { user, guestInvite, image, password } = payload;
    try {
        const newAuthUserId = yield addAuthUser({
            email: user.email,
            password: password
        });

        yield call(() => loginUserEmailPasswordRequest(user.email, password));
        const imageURL = yield uploadImageAndSaveUserData(newAuthUserId, image);

        const orgId = guestInvite.org_id;
        const propertyId = guestInvite?.property_id;

        if (propertyId) {
            yield updatePropertyToStatus(orgId, propertyId, 'active');
        }

        const propertyIds = propertyId ? [guestInvite.property_id] : [];

        const notificationSettingsDict = {
            comunity_news: false,
            guest_access: guestInvite?.role === 'resi',
            vendor_access: guestInvite?.role === 'resi'
        };

        const userData = {
            active_station_id: newAuthUserId,
            active_org_id: guestInvite?.org_id || '',
            active_property_id: propertyId,
            devices: {},
            email: user.email.toLowerCase(),
            first_name: user.first_name.toLowerCase(),
            last_name: user.last_name.toLowerCase(),
            notifications: notificationSettingsDict,
            orgs: null,
            phone: user.phone,
            phone_number: user.phone.code.toString() + user.phone.number.toString(),
            photo_id: imageURL,
            plates: [],
            primary_org: guestInvite?.org_id || '',
            uid: newAuthUserId,
            role: guestInvite?.role || '',
            super_suspend: false,
            type: guestInvite?.role === 'resi' ? 'member' : 'short-term',
            vehicles: [],
            properties: createPropertiesMap(guestInvite),
            property_ids: propertyIds
        };

        yield call(setDoc, doc(db, 'users', newAuthUserId), userData);
        if (
            guestInvite?.role === 'resi' ||
            (guestInvite?.role === 'tenant' && guestInvite?.type === 'long-term')
        ) {
            yield createMember(user, guestInvite, orgId, newAuthUserId);
        }
        yield createAccessLog(user, guestInvite, newAuthUserId, imageURL);
        yield put(createMemberUserSuccess());
    } catch (error) {
        console.log('error', error);
        yield put(createMemberUserFailure(error.message));
    }
}

function* createAccessLog(user, guestInvite, newAuthUserId, imageURL) {
    try {
        const accessKeysCollectionRef = collection(db, 'access_keys');
        const newDocumentRef = doc(accessKeysCollectionRef);

        const accessStartTime = {};
        const accessEndTime = {};

        if (guestInvite?.access_start_time) {
            const guestInviteAccessStartTime = guestInvite.access_start_time;
            accessStartTime.hours = guestInviteAccessStartTime.hours;
            accessStartTime.minutes = guestInviteAccessStartTime.minutes;
        }

        if (guestInvite?.access_end_time) {
            const guestInviteAccessEndTime = guestInvite.access_end_time;
            accessEndTime.hours = guestInviteAccessEndTime.hours;
            accessEndTime.minutes = guestInviteAccessEndTime.minutes;
        }

        const accessKeyData = {
            access_begins: Timestamp.now(),
            access_created: Timestamp.now(),
            access_days: guestInvite?.access_days,
            access_end_time:
                Object.keys(accessEndTime).length === 0 ? null : accessEndTime,
            access_expires: guestInvite?.access_expires,
            access_start_time:
                Object.keys(accessStartTime).length === 0 ? null : accessStartTime,
            address: guestInvite.address,
            company_name: guestInvite?.company_name,
            consumer_id: newAuthUserId,
            creator_first_name: guestInvite?.creator_first_name ?? '',
            creator_id: guestInvite?.creator_id ?? '',
            creator_last_name: guestInvite?.creator_last_name ?? '',
            email: user.email.toLowerCase(),
            first_name: user.first_name.toLowerCase(),
            key_id: newDocumentRef.id,
            last_name: user.last_name.toLowerCase(),
            org_id: guestInvite?.org_id ?? '',
            org_name: guestInvite?.org_name ?? '',
            phone_number: user.phone.code.toString() + user.phone.number.toString(),
            photo_id: imageURL,
            plates: [],
            property_id: guestInvite?.property_id ?? null,
            role: guestInvite?.role ?? '',
            suspended: false,
            type: guestInvite?.role === 'resi' ? 'member' : 'short-term',
            vehicles: []
        };

        yield setDoc(newDocumentRef, accessKeyData);
        yield deleteDoc(doc(db, 'guest_invites', guestInvite.invite_id));
    } catch (error) {
        console.error('Error creating access log:', error);
        throw error;
    }
}

function* createMember(user, guestInvite, orgId, userId) {
    try {
        const rtdbMembersRef = ref(rtdb, `/orgs/${orgId}/members/${userId}`);

        const address = guestInvite?.address?.address_1 ?? '';
        const streetNumber = guestInvite?.address?.address_1?.split(/[^\d]+/)[0];
        const propertyId = guestInvite?.property_id && null;

        const userData = {
            access_group: 1,
            access_key: true,
            directory:
                guestInvite?.permissions?.calls === undefined
                    ? true
                    : guestInvite.permissions.calls,
            email: user.email.toLowerCase(),
            first_name: guestInvite.first_name?.toLowerCase() ?? '',
            last_name: guestInvite.last_name?.toLowerCase() ?? '',
            phone: user.phone.code.toString() + user.phone.number.toString(),
            properties: {
                [propertyId]: {
                    address,
                    id: propertyId,
                    street_num: streetNumber
                }
            },
            role: guestInvite?.role ?? 'resi',
            suspended: guestInvite.suspended || null,
            uid: userId
        };

        yield set(rtdbMembersRef, userData);
    } catch (error) {
        console.error('Error creating member in RTDB:', error);
        throw error;
    }
}

function* updatePropertyToStatus(orgId, propertyId, status) {
    try {
        const propertyDocRef = doc(db, 'properties', propertyId);
        yield updateDoc(propertyDocRef, {
            status: status
        });

        const orgPropertiesDocRef = doc(db, 'org_properties', orgId);
        const orgPropertiesSnapshot = yield getDoc(orgPropertiesDocRef);
        if (orgPropertiesSnapshot.exists()) {
            const orgPropertiesData = orgPropertiesSnapshot.data();
            if (orgPropertiesData && orgPropertiesData[propertyId]) {
                orgPropertiesData[propertyId].status = status;
                yield updateDoc(orgPropertiesDocRef, orgPropertiesData);
            } else {
                console.error(
                    `Property with id ${propertyId} not found in org_properties for org with id ${orgId}`
                );
            }
        } else {
            console.error(`Org with id ${orgId} not found in org_properties collection`);
        }
    } catch (error) {
        console.error('Error updating property:', error);
        throw error;
    }
}

function* uploadImageAndSaveUserData(userId, image) {
    if (!image) return;
    const avatarRef = sRef(userImagesRef, `${userId}/photo_id.jpeg`);
    yield uploadString(avatarRef, image, 'data_url');

    const imageURL = yield call(getDownloadURL, avatarRef);

    return imageURL;
}

function createPropertiesMap(guestInvite) {
    const addressDict = {
        address_1: guestInvite.address?.address_1 || null,
        address_2: guestInvite.address?.address_2 || null,
        city: guestInvite.address?.city || null,
        full_address: guestInvite.address?.full_address || null,
        state: guestInvite.address?.state || null,
        zip: guestInvite.address?.zip || null
    };

    const propertyDetails = {
        address: addressDict,
        id: guestInvite.property_id,
        notifications: true,
        org_id: guestInvite.org_id || null,
        primary: true,
        permissions: guestInvite.permissions || null,
        suspended: guestInvite.suspended || null,
        type: guestInvite.type || null,
        role: guestInvite.role || null
    };

    return {
        [guestInvite.property_id]: propertyDetails
    };
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////// Add/Edit Member Device //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const addEditMemberDeviceRequest = async payload => {
    console.log('payload', payload);
    const { device, newVehicle, property, type, vehicles, plates, userDevices } = payload;
    const deviceKeyId = device.id ? device.id : doc(devicesCollectionRef).id;
    const deviceKeyDocRef = doc(devicesCollectionRef, deviceKeyId);
    const propertyDocRef = doc(propertiesCollectionRef, property.id);
    const userDocRef = doc(userCollectionRef, device.owner.uid);
    const rtdbOperationRef = ref(rtdb, `/device_operations/${deviceKeyId}`);

    const filteredPropertyDevices =
        property?.device_ids && property?.device_ids?.length
            ? property.device_ids.filter(id => id !== deviceKeyId)
            : [];

    const propertyDevices =
        filteredPropertyDevices && filteredPropertyDevices?.length
            ? [...filteredPropertyDevices, deviceKeyId]
            : [deviceKeyId];

    const filteredMemberDevices =
        userDevices && userDevices?.length
            ? userDevices.filter(id => id !== deviceKeyId)
            : [];

    const memberDevices =
        filteredMemberDevices && filteredMemberDevices?.length
            ? [...filteredMemberDevices, deviceKeyId]
            : [deviceKeyId];

    const operation = {
        created_at: timeStampNowSeconds(),
        device: {
            ...device,
            device_id: deviceKeyId,
            expires_at: device.expires_at ? tsFromJsDate(device.expires_at) : null
        },
        type: device.status === 'pending' ? 'add' : type,
        property
    };

    const plateState = newVehicle
        ? unitedStates.filter(
              state =>
                  state.name.trim().toLowerCase() ===
                  device.vehicle.state.trim().toLowerCase()
          )[0].value
        : null;

    const resiPlates = newVehicle
        ? [...plates, `${device.vehicle.tag}${device.vehicle.country}/${plateState}`]
        : null;

    const resiVehicles = newVehicle
        ? [...vehicles, { ...device.vehicle, updated_at: fsTimeStampNow() }]
        : null;

    try {
        (await device.id)
            ? updateDoc(deviceKeyDocRef, {
                  ...device
              })
            : setDoc(deviceKeyDocRef, {
                  ...device,
                  id: deviceKeyId,
                  property_id: property.id
              });

        await updateDoc(propertyDocRef, {
            device_ids: propertyDevices
        });

        await set(rtdbOperationRef, operation);

        if (newVehicle) {
            await updateDoc(userDocRef, {
                plates: resiPlates,
                vehicles: resiVehicles,
                [`properties.${property.id}.device_ids`]: memberDevices
            });
        } else {
            await updateDoc(userDocRef, {
                [`properties.${property.id}.device_ids`]: memberDevices
            });
        }
        return { res: true };
    } catch (error) {
        throw new Error(error);
    }
};

export function* addEditMemberDevice({ payload }) {
    const { device, newVehicle, property, type, vehicles, plates, userDevices } = payload;
    const { res, error } = yield call(() =>
        addEditMemberDeviceRequest({
            device,
            newVehicle,
            property,
            type,
            vehicles,
            plates,
            userDevices
        })
    );
    if (res) {
        yield put(
            openSnackbar({
                open: true,
                message: `Success: Device has been ${
                    type === 'add' ? 'added!' : 'updated!'
                }`,
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
        yield put(addEditingMemberDeviceSuccess());
    } else {
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: Device was not ${
                    type === 'add' ? 'added' : 'updated'
                } successfully.`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
        yield put(addEditingMemberDeviceFailure(error));
        log(`Error: error adding/updating member device (FS/RTDB)`, {
            error,
            device,
            newVehicle,
            property,
            type,
            vehicles,
            plates,
            userDevices
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Remove Member Device ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const removeMemberDeviceRequest = async ({ device, property, type, userDevices }) => {
    const deviceKeyId = device.id;
    const propertyDocRef = doc(propertiesCollectionRef, property.id);
    const userDocRef = doc(userCollectionRef, device.owner.uid);
    const rtdbOperationRef = ref(rtdb, `/device_operations/${deviceKeyId}`);

    const filteredPropertyDevices =
        property?.device_ids && property?.device_ids.length
            ? property.device_ids.filter(id => id !== deviceKeyId)
            : [];

    const filteredMemberDevices =
        userDevices && userDevices?.length
            ? userDevices.filter(id => id !== deviceKeyId)
            : [];

    const operation =
        device.status === 'pending'
            ? null
            : {
                  created_at: timeStampNowSeconds(),
                  device: { ...device, device_id: deviceKeyId },
                  type: type,
                  property
              };

    try {
        await updateDoc(propertyDocRef, {
            device_ids: filteredPropertyDevices
        });

        await updateDoc(userDocRef, {
            [`properties.${property.id}.device_ids`]: filteredMemberDevices
        });

        await deleteDoc(doc(devicesCollectionRef, deviceKeyId));

        await set(rtdbOperationRef, operation);

        return { res: true };
    } catch (error) {
        throw new Error(error);
    }
};

export function* removeMemberDevice({ payload }) {
    const { device, property, type, userDevices } = payload;
    const { res, error } = yield call(() =>
        removeMemberDeviceRequest({
            device,
            property,
            type,
            userDevices
        })
    );
    if (res) {
        yield put(
            openSnackbar({
                open: true,
                message: `Success: Device has been removed!`,
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
        yield put(removingMemberDeviceSuccess());
    } else {
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: Device was not removed successfully.`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
        yield put(removingMemberDeviceFailure(error));
        log(`Error: error removing member device (FS/RTDB)`, {
            error,
            device,
            property,
            type
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* checkingExistingMember() {
    yield takeLatest(GET_EXISTING_MEMBER, checkExistingMember);
}

export function* addingMember() {
    yield takeLatest(ADD_NEW_MEMBER, addMember);
}

export function* removingMember() {
    yield takeLatest(REMOVE_MEMBERS, removeMember);
}

export function* updatingMember() {
    yield takeLatest(UPDATE_MEMBER, updateMember);
}

export function* transferringMember() {
    yield takeLatest(TRANSFER_MEMBER, transferMemberSaga);
}

export function* removingRegisters() {
    yield takeLatest(REMOVE_REGISTERS, removeRegisters);
}

export function* updatingRegister() {
    yield takeLatest(UPDATE_REGISTER, updateRegister);
}

export function* sendingInvites() {
    yield takeLatest(SEND_INVITES, sendInvites);
}

export function* removingPending() {
    yield takeLatest(REMOVE_PENDING, removePending);
}

export function* suspendingPropertyGuest() {
    yield takeLatest(SUSPEND_PROPERTY_GUEST, suspendPropertyGuest);
}

export function* addingMemberUser() {
    yield takeLatest(CREATE_MEMBER_USER, createMemberUser);
}

export function* addEditingMemberDevice() {
    yield takeLatest(ADD_EDIT_MEMBER_DEVICE, addEditMemberDevice);
}

export function* removingMemberDevice() {
    yield takeLatest(REMOVE_MEMBER_DEVICE, removeMemberDevice);
}

export default function* rootSaga() {
    yield all([
        fork(checkingExistingMember),
        fork(addingMember),
        fork(removingMember),
        fork(updatingMember),
        fork(transferringMember),
        fork(updatingRegister),
        fork(removingRegisters),
        fork(sendingInvites),
        fork(removingPending),
        fork(suspendingPropertyGuest),
        fork(addingMemberUser),
        fork(addEditingMemberDevice),
        fork(removingMemberDevice)
    ]);
}
