// React
import React, { useEffect, useState } from 'react';

// Assets
import { CloseCircleOutlined, DeleteFilled } from '@ant-design/icons';

// Components
import IconButton from 'components/@extended/IconButton';

// Packages
import { Loader } from 'components/index';
import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import Autocomplete from '../../common/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import InputFieldErrorMessage from '../../common/InputFieldErrorMessage';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone-lite';
import store from '../../../store';

// Actions
import { checkDeviceExists } from 'store/actions/DeviceOperations';

// Styles
import 'react-widgets/scss/styles.scss';

// Utils
import { CA_OPERATIONS, errorMessage, unitedStates } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';

const CreateUpdateDeviceModal = ({
    onClose,
    selectedDevice,
    activeOrg,
    members,
    property,
    type,
    activeOrgId,
    deviceConfig,
    resetDeviceState,
    handleMemberDevice,
    deviceTypes
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const [selectedMember, setSelectedMember] = useState(null);
    const [addNewVehicle, setAddNewVehicle] = useState(false);
    const [showVehicle, setShowVehicle] = useState(false);
    const [displayFacilityCodes, setDisplayFacilityCode] = useState(null);
    const [facilityCodesForDeviceTypeExist, setFacilityCodesForDeviceTypeExist] =
        useState(null);
    const [vehicles, setVehicles] = useState([
        { key: 'addNew', value: '+ Add New Vehicle' }
    ]);

    const deviceSuccess = useSelector(state => state.device.success);
    const devicesLoading = useSelector(state => state.device.loading);

    useEffect(() => {
        if (deviceSuccess) {
            resetDeviceState();
            onClose();
        }
    }, [deviceSuccess, resetDeviceState, onClose]);

    useEffect(() => {
        if (selectedMember && selectedMember.vehicles && selectedMember.vehicles.length) {
            const memberVehicles = selectedMember.vehicles.map(vehicle => {
                return {
                    key: vehicle.tag,
                    value: `${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.tag}`
                };
            });
            setVehicles([
                { key: 'addNew', value: '+ Add New Vehicle' },
                ...memberVehicles
            ]);
        } else {
            setVehicles([{ key: 'addNew', value: '+ Add New Vehicle' }]);
        }
    }, [selectedMember, setVehicles]);

    useEffect(() => {
        if (selectedDevice?.facility_code) {
            const deviceFacility = activeOrg?.device_facility_codes?.find(dfc =>
                dfc.facility_codes.includes(Number(selectedDevice?.facility_code))
            );
            setShowVehicle(deviceFacility?.vehicle || false);
        }
        if (selectedDevice && selectedDevice.owner) {
            const selectMember = members.find(
                member =>
                    `${member.first_name} ${member.last_name}`.toLowerCase() ===
                    `${selectedDevice.owner.first_name} ${selectedDevice.owner.last_name}`.toLowerCase()
            );
            if (selectMember) {
                setSelectedMember(selectMember);
            }
        }
    }, [selectedDevice, members, setSelectedMember]);

    const submit = values => {
        const {
            access_groups,
            org_id,
            disabled,
            device_number,
            device_type,
            expires_at,
            facility_code,
            activate_at,
            owner,
            vehicle,
            vehicle_color,
            vehicle_make,
            vehicle_model,
            vehicle_state,
            vehicle_tag,
            vehicle_year
        } = values;

        const getResident = () => {
            return members.find(
                member =>
                    `${member.first_name} ${member.last_name}`.trim().toLowerCase() ===
                    owner.trim().toLowerCase()
            );
        };

        const resi = getResident();

        const isDeviceNumberChanged = (oldDeviceNumber, newDeviceNumber) => {
            if (oldDeviceNumber === newDeviceNumber) {
                return null;
            } else {
                return oldDeviceNumber;
            }
        };

        const getVehicle = () => {
            if (showVehicle) {
                return null;
            } else if (addNewVehicle) {
                return {
                    color: vehicle_color,
                    country: 'USA',
                    make: vehicle_make,
                    model: vehicle_model,
                    primary: !resi.vehicles.length,
                    state: vehicle_state,
                    tag: vehicle_tag.toUpperCase(),
                    year: vehicle_year
                };
            } else {
                const foundVehicle = resi.vehicles.filter(
                    memberVehicle =>
                        `${memberVehicle.year} ${memberVehicle.make} ${memberVehicle.model} ${memberVehicle.tag}`
                            .trim()
                            .toLowerCase() === vehicle.trim().toLowerCase()
                )[0];
                return {
                    color: foundVehicle.color,
                    country: foundVehicle.country,
                    make: foundVehicle.make,
                    model: foundVehicle.model,
                    primary: foundVehicle.primary,
                    state: foundVehicle.state,
                    tag: foundVehicle.tag,
                    year: foundVehicle.year
                };
            }
        };

        const deviceNumber = device_number.toString().padStart(5, '0');

        const device = {
            access_groups,
            org_id,
            device_number: deviceNumber,
            device_type,
            created_at: type === 'edit' ? selectedDevice?.created_at : null,
            property_id: property.id,
            expires_at: expires_at || null,
            activate_at: activate_at || null,
            facility_code: facility_code.toString(),
            group_name: deviceConfig.group_name,
            access_type: resi?.role || '',
            id: type === 'edit' ? selectedDevice.id : null,
            operation: type === 'edit' ? CA_OPERATIONS.EDIT : CA_OPERATIONS.ADD,
            old_badge_number:
                type === 'edit'
                    ? isDeviceNumberChanged(
                          `${selectedDevice.facility_code}${selectedDevice.device_number}`,
                          `${facility_code.toString()}${deviceNumber}`
                      )
                    : null,
            owner: {
                first_name: resi.first_name,
                last_name: resi.last_name,
                uid: resi.uid
            },
            disabled,
            vehicle:
                (vehicle.trim() === '' && !addNewVehicle) ||
                (addNewVehicle && vehicle_tag.trim() === '')
                    ? null
                    : getVehicle()
        };

        handleMemberDevice({
            device,
            newVehicle:
                addNewVehicle && vehicle_tag.trim() !== '' ? addNewVehicle : false,
            property,
            type,
            vehicles: resi.vehicles,
            plates: resi.plates,
            userDevices: resi.properties[property?.id]?.device_ids
                ? resi.properties[property.id].device_ids
                : []
        });
    };

    const handleRemove = () => {
        const getResident = () => {
            return members.find(
                member =>
                    `${member.first_name} ${member.last_name}`.trim().toLowerCase() ===
                    `${selectedDevice.owner.first_name} ${selectedDevice.owner.last_name}`
                        .trim()
                        .toLowerCase()
            );
        };

        const resi = getResident();
        handleMemberDevice({
            device: { ...selectedDevice, operation: CA_OPERATIONS.REMOVE },
            property,
            type: 'remove',
            userDevices: resi.properties[property.id]?.device_ids
                ? resi.properties[property.id].device_ids
                : []
        });
    };

    const initialValues = {
        access_groups: selectedDevice?.access_groups || [],
        org_id: activeOrgId,
        group_name: activeOrg.device_config.group_name,
        disabled: type === 'add' ? false : selectedDevice.disabled,
        device_number: selectedDevice?.device_number || '',
        device_type: selectedDevice?.device_type || '',
        expires_at: selectedDevice?.expires_at
            ? new Date(selectedDevice.expires_at)
            : null,
        facility_code: selectedDevice?.facility_code || null,
        activate_at: selectedDevice?.activate_at
            ? new Date(selectedDevice.activate_at)
            : null,
        owner: selectedDevice?.owner
            ? `${selectedDevice.owner.first_name} ${selectedDevice.owner.last_name}`
            : '',
        vehicle: selectedDevice?.vehicle
            ? `${selectedDevice.vehicle.year} ${selectedDevice.vehicle.make} ${selectedDevice.vehicle.model} ${selectedDevice.vehicle.tag}`
            : '',
        vehicle_color: selectedDevice?.vehicle ? selectedDevice.vehicle.color : '',
        vehicle_make: selectedDevice?.vehicle ? selectedDevice.vehicle.make : '',
        vehicle_model: selectedDevice?.vehicle ? selectedDevice.vehicle.model : '',
        vehicle_state: selectedDevice?.vehicle ? selectedDevice.vehicle.state : '',
        vehicle_tag: selectedDevice?.vehicle ? selectedDevice.vehicle.tag : '',
        vehicle_year: selectedDevice?.vehicle ? selectedDevice.vehicle.year : ''
    };

    const validationSchema = Yup.object().shape(
        {
            access_groups: Yup.array()
                .of(Yup.object())
                .min(1, errorMessage.device.access_groups.required)
                .required(errorMessage.device.access_groups.required),
            disabled: Yup.boolean(),
            device_number: Yup.number()
                .required(errorMessage.device.device_number.required)
                .test(
                    'len',
                    errorMessage.device.device_number.length,
                    val => !val || val.toString().length <= 5
                )
                .test(
                    'validate-device-number',
                    errorMessage.device.device_number.used,
                    async function (value) {
                        const deviceNumber = value.toString().padStart(5, '0');
                        if (
                            !value ||
                            deviceNumber.toString() ===
                                selectedDevice?.device_number.toString()
                        )
                            return true;

                        dispatch(checkDeviceExists(deviceNumber, activeOrgId));

                        const isDeviceNumberValid = await new Promise(resolve => {
                            const unsubscribe = store.subscribe(async () => {
                                const { device } = await store.getState();
                                if (device.deviceExistsValidationChecked) {
                                    resolve(device.isDeviceExists);
                                    unsubscribe();
                                }
                            });
                        });

                        const { device } = await store.getState();

                        if (isDeviceNumberValid === false) {
                            return this.createError({
                                message: device.deviceExistsValidationError
                            });
                        }

                        return true;
                    }
                ),
            device_type: Yup.string()
                .trim()
                .required(errorMessage.device.device_type.required)
                .test(
                    'facilityCodesExist',
                    errorMessage.device.device_type.facility_code_missing,
                    function () {
                        return facilityCodesForDeviceTypeExist !== false;
                    }
                ),
            activate_at: Yup.date().nullable(),
            expires_at: Yup.date().nullable(),
            facility_code: Yup.number().required(
                errorMessage.device.facility_code.required
            ),
            has_expire: Yup.boolean(),
            owner: Yup.string()
                .trim()
                .lowercase()
                .test('check-members', errorMessage.device.owner.notExists, function () {
                    if (!members || members.length === 0) {
                        return this.createError({
                            message: errorMessage.device.owner.notExists
                        });
                    }
                    return true;
                })
                .when([], {
                    is: () => members && members.length > 0,
                    then: schema => schema.required(errorMessage.device.owner.required)
                }),
            vehicle: Yup.string(),
            vehicle_color: Yup.string(),
            vehicle_make: Yup.string(),
            vehicle_model: Yup.string(),
            vehicle_state: Yup.string(),
            vehicle_tag: Yup.string(),
            vehicle_year: Yup.string()
        },
        [
            ['expires_at', 'activate_at'],
            ['vehicle', 'device_type'],
            ['device_type', 'vehicle'],
            ['device_type', 'vehicle_color'],
            ['vehicle_color', 'device_type'],
            ['device_type', 'vehicle_make'],
            ['vehicle_make', 'device_type'],
            ['device_type', 'vehicle_model'],
            ['vehicle_model', 'device_type'],
            ['device_type', 'vehicle_state'],
            ['vehicle_state', 'device_type'],
            ['device_type', 'vehicle_tag'],
            ['vehicle_tag', 'device_type'],
            ['device_type', 'vehicle_year'],
            ['vehicle_year', 'device_type']
        ]
    );
    const handleFobDevice = setFieldValue => {
        setFieldValue('vehicle', '');
        setFieldValue('vehicle_color', '');
        setFieldValue('vehicle_make', '');
        setFieldValue('vehicle_model', '');
        setFieldValue('vehicle_state', '');
        setFieldValue('vehicle_tag', '');
        setFieldValue('vehicle_year', '');
        setAddNewVehicle(false);
    };

    const handleDeviceTypeChange = (selectedDeviceType, setFieldValue) => {
        const deviceFacility = activeOrg?.device_facility_codes?.find(
            dfc => dfc.device_type === selectedDeviceType
        );

        if (deviceFacility && Array.isArray(deviceFacility.facility_codes)) {
            const deviceFacilityCode = deviceFacility?.facility_codes;

            if (deviceFacilityCode?.length > 1) {
                setDisplayFacilityCode(deviceFacilityCode);
                setFieldValue('facility_code', null);
            } else if (deviceFacilityCode?.length === 1) {
                setDisplayFacilityCode(null);
                setFieldValue('facility_code', deviceFacilityCode[0]);
            } else {
                setDisplayFacilityCode(null);
                setFieldValue('facility_code', null);
            }
            const shouldShowVehicle = deviceFacility?.vehicle || false;
            setShowVehicle(shouldShowVehicle);
            if (!shouldShowVehicle) {
                handleFobDevice(setFieldValue);
            }
        } else {
            setDisplayFacilityCode(null);
            setShowVehicle(false);
            setFieldValue('facility_code', null);
        }

        setFacilityCodesForDeviceTypeExist(deviceFacility !== undefined);
    };

    if (selectedDevice && selectedDevice.vehicle && vehicles.length === 1)
        return <Loader />;

    return (
        <>
            {devicesLoading && <Loader />}
            <DialogTitle>
                {type === 'edit' ? 'Edit Device' : 'Add New Device'}
            </DialogTitle>
            <Divider />
            <Formik
                initialValues={initialValues}
                onSubmit={submit}
                validationSchema={validationSchema}
                validateOnChange
                initialTouched={(!members || members.length === 0) && { owner: true }}
                initialErrors={
                    (!members || members.length === 0) && {
                        owner: errorMessage.device.owner.notExists
                    }
                }
            >
                {({
                    handleSubmit,
                    handleChange,
                    touched,
                    setFieldValue,
                    handleBlur,
                    errors,
                    values,
                    setFieldTouched
                }) => (
                    <>
                        <DialogContent sx={{ p: 2.5, maxHeight: '70vh' }}>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Stack spacing={0.5}>
                                        <InputLabel htmlFor="device_type">
                                            Device Type
                                        </InputLabel>
                                        <Select
                                            required
                                            variant="outlined"
                                            id="deviceTypeSelect"
                                            name="device_type"
                                            sx={{
                                                textTransform: 'capitalize'
                                            }}
                                            onChange={async event => {
                                                handleDeviceTypeChange(
                                                    event.target.value,
                                                    setFieldValue
                                                );

                                                await setFieldValue(
                                                    'device_type',
                                                    event.target.value
                                                );
                                                await setFieldTouched(
                                                    'device_type',
                                                    true
                                                );
                                            }}
                                            value={values.device_type}
                                            error={Boolean(
                                                touched.device_type &&
                                                    !!errors.device_type
                                            )}
                                        >
                                            {Object.entries(deviceTypes).map(
                                                ([key, value]) => (
                                                    <MenuItem
                                                        key={key}
                                                        value={value}
                                                        sx={{
                                                            textTransform: 'capitalize'
                                                        }}
                                                    >
                                                        {value}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                        <InputFieldErrorMessage
                                            touched={touched.device_type}
                                            error={errors.device_type}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Stack spacing={0.5}>
                                        <InputLabel htmlFor="owner">Owner</InputLabel>
                                        <Select
                                            required
                                            variant="outlined"
                                            id="ownerSelect"
                                            name="owner"
                                            sx={{
                                                textTransform: 'capitalize'
                                            }}
                                            onChange={e => {
                                                const selectMember = members.find(
                                                    member =>
                                                        `${member.first_name} ${member.last_name}` ===
                                                        e.target.value
                                                );
                                                if (selectMember) {
                                                    setFieldValue(
                                                        'owner',
                                                        `${selectMember.first_name} ${selectMember.last_name}`
                                                    );
                                                    setSelectedMember(selectMember);
                                                }
                                            }}
                                            disabled={!members || members.length === 0}
                                            value={values.owner}
                                            error={Boolean(touched.owner && errors.owner)}
                                        >
                                            {members.map(member => (
                                                <MenuItem
                                                    key={member.uid}
                                                    value={`${member.first_name} ${member.last_name}`}
                                                    sx={{
                                                        textTransform: 'capitalize'
                                                    }}
                                                >
                                                    {`${member.first_name} ${member.last_name}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <InputFieldErrorMessage
                                            touched={touched.owner}
                                            error={errors.owner}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Stack spacing={0.5}>
                                        <InputLabel htmlFor="facility_code">
                                            Access Groups
                                        </InputLabel>
                                        <Box>
                                            <Autocomplete
                                                multiple
                                                id="access_groups"
                                                options={activeOrg?.access_groups || []}
                                                getOptionLabel={option => option.name}
                                                value={values.access_groups}
                                                isOptionEqualToValue={(option, value) =>
                                                    option.number === value.number
                                                }
                                                onChange={newValue => {
                                                    const updatedValue = newValue.map(
                                                        item => {
                                                            const {
                                                                reader_time_schedules,
                                                                ...rest
                                                            } = item;
                                                            return rest;
                                                        }
                                                    );

                                                    setFieldValue(
                                                        'access_groups',
                                                        updatedValue
                                                    );
                                                }}
                                                error={
                                                    touched.access_groups &&
                                                    errors.access_groups
                                                }
                                                placeholder="Select Access Groups"
                                            />
                                        </Box>
                                        <InputFieldErrorMessage
                                            touched={touched.access_groups}
                                            error={errors.access_groups}
                                        />
                                    </Stack>
                                </Grid>
                                {displayFacilityCodes && (
                                    <Grid item xs={12} sm={6}>
                                        <Stack spacing={0.5}>
                                            <InputLabel htmlFor="facility_code">
                                                Facility Code
                                            </InputLabel>
                                            <Select
                                                required
                                                variant="outlined"
                                                id="faciitySelect"
                                                name="facility_code"
                                                sx={{
                                                    textTransform: 'capitalize'
                                                }}
                                                onChange={e => {
                                                    setFieldValue(
                                                        'facility_code',
                                                        e.target.value
                                                    );
                                                }}
                                                value={values.facility_code}
                                                error={Boolean(
                                                    touched.facility_code &&
                                                        errors.facility_code
                                                )}
                                            >
                                                {displayFacilityCodes.map(code => (
                                                    <MenuItem key={code} value={code}>
                                                        {code}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <InputFieldErrorMessage
                                                touched={touched.facility_code}
                                                error={errors.facility_code}
                                            />
                                        </Stack>
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={displayFacilityCodes ? 6 : 12}>
                                    <Stack spacing={0.5}>
                                        <InputLabel>Device Number</InputLabel>
                                        <TextField
                                            required
                                            id="device_numberBasic"
                                            name="device_number"
                                            type="number"
                                            placeholder="Device Number"
                                            fullWidth
                                            autoComplete="number"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={
                                                touched.device_number &&
                                                !!errors.device_number
                                            }
                                            value={values.device_number}
                                        />
                                        <InputFieldErrorMessage
                                            touched={touched.device_number}
                                            error={
                                                errors.device_number
                                                    ? errors.device_number
                                                    : ''
                                            }
                                        />
                                    </Stack>
                                </Grid>
                                {showVehicle && (
                                    <>
                                        {!addNewVehicle ? (
                                            <Grid item xs={12} sm={12}>
                                                <Stack spacing={0.5}>
                                                    <InputLabel htmlFor="vehicle">
                                                        Vehicle Assignment
                                                    </InputLabel>
                                                    <Select
                                                        required
                                                        variant="outlined"
                                                        id="vehicleSelect"
                                                        name="vehicle"
                                                        sx={{
                                                            textTransform: 'capitalize'
                                                        }}
                                                        onChange={e => {
                                                            if (
                                                                e.target.value ===
                                                                '+ Add New Vehicle'
                                                            ) {
                                                                setFieldValue(
                                                                    'vehicle',
                                                                    ''
                                                                );
                                                                setAddNewVehicle(true);
                                                            } else {
                                                                setFieldValue(
                                                                    'vehicle',
                                                                    e.target.value
                                                                );
                                                            }
                                                        }}
                                                        value={values.vehicle}
                                                        error={Boolean(
                                                            touched.vehicle &&
                                                                errors.vehicle
                                                        )}
                                                    >
                                                        {vehicles.map(vehicle => (
                                                            <MenuItem
                                                                key={vehicle.key}
                                                                value={vehicle.value}
                                                                sx={{
                                                                    textTransform:
                                                                        'capitalize'
                                                                }}
                                                            >
                                                                {vehicle.value}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <InputFieldErrorMessage
                                                        touched={touched.vehicle}
                                                        error={errors.vehicle}
                                                    />
                                                </Stack>
                                            </Grid>
                                        ) : (
                                            <Grid item>
                                                <Box
                                                    sx={{
                                                        p: 2,
                                                        border: '1px solid',
                                                        borderColor:
                                                            theme.palette.secondary.light,
                                                        borderRadius: 1,
                                                        mb: 2
                                                    }}
                                                >
                                                    <Grid
                                                        container
                                                        columnSpacing={2}
                                                        rowSpacing={1.5}
                                                    >
                                                        <Grid item xs={12} sm={12}>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    justifyContent:
                                                                        'space-between',
                                                                    alignItems: 'center',
                                                                    mt: 1
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="h6"
                                                                    sx={{
                                                                        color: '#8c8c8c',
                                                                        textAlign:
                                                                            'center',
                                                                        fontWeight:
                                                                            'semibold'
                                                                    }}
                                                                >
                                                                    Add New Vehicle
                                                                </Typography>
                                                                <Button
                                                                    size="medium"
                                                                    color="secondary"
                                                                    onClick={() => {
                                                                        setFieldValue(
                                                                            'vehicle',
                                                                            ''
                                                                        );
                                                                        setAddNewVehicle(
                                                                            false
                                                                        );
                                                                    }}
                                                                >
                                                                    <CloseCircleOutlined />
                                                                    <Typography
                                                                        sx={{
                                                                            color: '#8c8c8c',
                                                                            marginLeft:
                                                                                '10px'
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                    </Typography>
                                                                </Button>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6}>
                                                            <Stack>
                                                                <InputLabel>
                                                                    Color
                                                                </InputLabel>
                                                                <TextField
                                                                    required
                                                                    id="vehicleColorBasic"
                                                                    name="vehicle_color"
                                                                    placeholder="Color"
                                                                    fullWidth
                                                                    autoComplete="given-name"
                                                                    onBlur={handleBlur}
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    error={
                                                                        touched.vehicle_color &&
                                                                        !!errors.vehicle_color
                                                                    }
                                                                    helperText={
                                                                        touched.vehicle_color &&
                                                                        errors.vehicle_color
                                                                            ? errors.vehicle_color
                                                                            : ''
                                                                    }
                                                                    value={
                                                                        values.vehicle_color
                                                                    }
                                                                />
                                                            </Stack>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6}>
                                                            <Stack>
                                                                <InputLabel>
                                                                    Make
                                                                </InputLabel>
                                                                <TextField
                                                                    required
                                                                    id="vehicleMakeBasic"
                                                                    name="vehicle_make"
                                                                    placeholder="Make"
                                                                    fullWidth
                                                                    autoComplete="given-name"
                                                                    onBlur={handleBlur}
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    error={
                                                                        touched.vehicle_make &&
                                                                        !!errors.vehicle_make
                                                                    }
                                                                    helperText={
                                                                        touched.vehicle_make &&
                                                                        errors.vehicle_make
                                                                            ? errors.vehicle_make
                                                                            : ''
                                                                    }
                                                                    value={
                                                                        values.vehicle_make
                                                                    }
                                                                />
                                                            </Stack>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6}>
                                                            <Stack>
                                                                <InputLabel>
                                                                    Model
                                                                </InputLabel>
                                                                <TextField
                                                                    required
                                                                    id="vehicleModelBasic"
                                                                    name="vehicle_model"
                                                                    placeholder="Model"
                                                                    fullWidth
                                                                    autoComplete="given-name"
                                                                    onBlur={handleBlur}
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    error={
                                                                        touched.vehicle_model &&
                                                                        !!errors.vehicle_model
                                                                    }
                                                                    helperText={
                                                                        touched.vehicle_model &&
                                                                        errors.vehicle_model
                                                                            ? errors.vehicle_model
                                                                            : ''
                                                                    }
                                                                    value={
                                                                        values.vehicle_model
                                                                    }
                                                                />
                                                            </Stack>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6}>
                                                            <Stack>
                                                                <InputLabel htmlFor="device_type">
                                                                    State
                                                                </InputLabel>
                                                                <Select
                                                                    required
                                                                    variant="outlined"
                                                                    id="stateSelect"
                                                                    name="vehicle_state"
                                                                    sx={{
                                                                        textTransform:
                                                                            'capitalize'
                                                                    }}
                                                                    onChange={e => {
                                                                        setFieldValue(
                                                                            'vehicle_state',
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    value={
                                                                        values.vehicle_state
                                                                    }
                                                                    error={Boolean(
                                                                        touched.vehicle_state &&
                                                                            !!errors.vehicle_state
                                                                    )}
                                                                >
                                                                    {unitedStates.map(
                                                                        state => (
                                                                            <MenuItem
                                                                                key={
                                                                                    state.name
                                                                                }
                                                                                value={
                                                                                    state.value
                                                                                }
                                                                                sx={{
                                                                                    textTransform:
                                                                                        'capitalize'
                                                                                }}
                                                                            >
                                                                                {
                                                                                    state.name
                                                                                }
                                                                            </MenuItem>
                                                                        )
                                                                    )}
                                                                </Select>
                                                            </Stack>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6}>
                                                            <Stack>
                                                                <InputLabel>
                                                                    Plate #
                                                                </InputLabel>
                                                                <TextField
                                                                    required
                                                                    id="vehicleTagBasic"
                                                                    name="vehicle_tag"
                                                                    placeholder="Plate/Tag #"
                                                                    fullWidth
                                                                    autoComplete="given-name"
                                                                    onBlur={handleBlur}
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    error={
                                                                        touched.vehicle_tag &&
                                                                        !!errors.vehicle_tag
                                                                    }
                                                                    helperText={
                                                                        touched.vehicle_tag &&
                                                                        errors.vehicle_tag
                                                                            ? errors.vehicle_tag
                                                                            : ''
                                                                    }
                                                                    value={
                                                                        values.vehicle_tag
                                                                    }
                                                                    inputProps={{
                                                                        style: {
                                                                            textTransform:
                                                                                'uppercase'
                                                                        }
                                                                    }}
                                                                />
                                                            </Stack>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6}>
                                                            <Stack>
                                                                <InputLabel>
                                                                    Year
                                                                </InputLabel>
                                                                <TextField
                                                                    required
                                                                    id="vehicleYearBasic"
                                                                    name="vehicle_year"
                                                                    placeholder="Year"
                                                                    fullWidth
                                                                    autoComplete="given-name"
                                                                    onBlur={handleBlur}
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    error={
                                                                        touched.vehicle_year &&
                                                                        !!errors.vehicle_year
                                                                    }
                                                                    helperText={
                                                                        touched.vehicle_year &&
                                                                        errors.vehicle_year
                                                                            ? errors.vehicle_year
                                                                            : ''
                                                                    }
                                                                    value={
                                                                        values.vehicle_year
                                                                    }
                                                                />
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                    <InputFieldErrorMessage
                                                        touched={touched.vehicle_state}
                                                        error={errors.vehicle_state}
                                                    />
                                                </Box>
                                            </Grid>
                                        )}
                                    </>
                                )}
                                <Grid item xs={12} sm={6}>
                                    <Stack>
                                        <InputLabel>Activation Date</InputLabel>
                                        <FormControl
                                            sx={{ width: '100%' }}
                                            error={Boolean(
                                                touched.activate_at && errors.activate_at
                                            )}
                                        >
                                            <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                            >
                                                <DatePicker
                                                    value={values.activate_at}
                                                    minDate={
                                                        type === 'add'
                                                            ? new Date()
                                                            : selectedDevice?.activate_at
                                                            ? new Date(
                                                                  selectedDevice
                                                                      .activate_at
                                                                      .seconds * 1000
                                                              )
                                                            : null
                                                    }
                                                    maxDate={
                                                        values.expires_at
                                                            ? new Date(
                                                                  new Date(
                                                                      values.expires_at
                                                                  ).setDate(
                                                                      values.expires_at.getDate() -
                                                                          1
                                                                  )
                                                              )
                                                            : null
                                                    }
                                                    onChange={newValue =>
                                                        setFieldValue(
                                                            'activate_at',
                                                            newValue
                                                        )
                                                    }
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Select Activation Date"
                                                        />
                                                    )}
                                                    slotProps={{
                                                        actionBar: {
                                                            actions: ['clear']
                                                        },
                                                        field: {
                                                            clearable: true,
                                                            onClear: () =>
                                                                setFieldValue(
                                                                    'activate_at',
                                                                    null
                                                                )
                                                        }
                                                    }}
                                                />
                                            </LocalizationProvider>
                                            <InputFieldErrorMessage
                                                touched={touched.activate_at}
                                                error={errors.activate_at}
                                            />
                                        </FormControl>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Stack>
                                        <InputLabel>Expiration Date</InputLabel>
                                        <FormControl
                                            sx={{ width: '100%' }}
                                            error={Boolean(
                                                touched.expires_at && errors.expires_at
                                            )}
                                        >
                                            <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                            >
                                                <DatePicker
                                                    value={values.expires_at}
                                                    minDate={
                                                        values.activate_at
                                                            ? new Date(
                                                                  new Date(
                                                                      values.activate_at
                                                                  ).setDate(
                                                                      values.activate_at.getDate() +
                                                                          1
                                                                  )
                                                              )
                                                            : type === 'add'
                                                            ? new Date()
                                                            : selectedDevice.created_at.toDate()
                                                    }
                                                    onChange={newValue =>
                                                        setFieldValue(
                                                            'expires_at',
                                                            newValue
                                                        )
                                                    }
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Select Expiration Date"
                                                        />
                                                    )}
                                                    slotProps={{
                                                        actionBar: {
                                                            actions: ['clear']
                                                        },
                                                        field: {
                                                            clearable: true,
                                                            onClear: () =>
                                                                setFieldValue(
                                                                    'expires_at',
                                                                    null
                                                                )
                                                        }
                                                    }}
                                                />
                                            </LocalizationProvider>
                                            <InputFieldErrorMessage
                                                touched={touched.expires_at}
                                                error={errors.expires_at}
                                            />
                                        </FormControl>
                                    </Stack>
                                </Grid>
                                {type === 'edit' && (
                                    <Grid item xs={12} sm={12} style={{ marginLeft: -8 }}>
                                        <Stack spacing={0.5}>
                                            <Box>
                                                <FormControlLabel
                                                    value="end"
                                                    control={
                                                        <Checkbox
                                                            checked={values.disabled}
                                                            onChange={() =>
                                                                setFieldValue(
                                                                    'disabled',
                                                                    !values.disabled
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            sx={{
                                                                color: '#8c8c8c'
                                                            }}
                                                        >
                                                            Disabled
                                                        </Typography>
                                                    }
                                                    labelPlacement="end"
                                                    sx={{ ml: 0 }}
                                                />
                                            </Box>
                                        </Stack>
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={6}>
                                    <Stack>
                                        {(!activeOrg?.device_facility_codes ||
                                            activeOrg.device_facility_codes.length ===
                                                0) && (
                                            <Typography color="error" sx={{ mb: 2 }}>
                                                No facility codes available. Please
                                                contact the administrator to set them up
                                                before adding a device.
                                            </Typography>
                                        )}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ p: 2.5 }}>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item>
                                    {type === 'edit' && (
                                        <Tooltip title="Delete Device" placement="top">
                                            <IconButton
                                                size="large"
                                                color="error"
                                                disabled={devicesLoading}
                                                onClick={handleRemove}
                                            >
                                                <DeleteFilled />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Button color="error" onClick={onClose}>
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            onClick={handleSubmit}
                                            disabled={
                                                devicesLoading ||
                                                !activeOrg?.device_facility_codes ||
                                                activeOrg?.device_facility_codes
                                                    ?.length === 0 ||
                                                !members ||
                                                members.length === 0
                                            }
                                        >
                                            {type === 'edit' ? 'Update' : 'Add'}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </>
    );
};

export default CreateUpdateDeviceModal;
