// Redux
import {
    all,
    call,
    cancelled,
    fork,
    put,
    race,
    select,
    take,
    takeLatest
} from 'redux-saga/effects';

import * as selectors from './Selectors';

import { eventChannel } from 'redux-saga';

// Actions
import {
    allowGateAccessFailure,
    allowGateAccessSuccess,
    disallowGateAccessFailure,
    disallowGateAccessSuccess,
    getInvitedPropertyMembersFailure,
    getInvitedPropertyMembersSuccess,
    getMemberKeysFailure,
    getMemberKeysSuccess,
    getPropertyMembersFailure,
    getPropertyMembersSuccess
} from '../actions/VirtualGuard';
import { openSnackbar } from 'store/actions/Snackbar';

// Action Types
import {
    ALLOW_GATE_ACCESS,
    DISALLOW_GATE_ACCESS,
    GET_MEMBER_KEYS,
    GET_PROPERTY_MEMBERS,
    LOGOUT_USER,
    RESET_MEMBER_KEYS,
    RESET_PROPERTY_MEMBERS
} from '../actions/types';

// Firebase
import { db, fsTools, rtdb, storage, timeStampNowSeconds } from 'config/firebase';
import { push, ref, set } from 'firebase/database';

// Loggers
import { log } from '../../utils/Loggers';
import { capitalizeFirstLetter, capitalizeFirstLetterMulti } from 'utils/Helpers';
import {getDownloadURL, ref as sRef, uploadBytes, uploadString} from 'firebase/storage';

// Tools
const { collection, query, where, onSnapshot, doc, setDoc } = fsTools;

const accessKeysCollectionRef = collection(db, 'access_keys');
const userCollectionRef = collection(db, 'users');
const guestInvitesCollectionRef = collection(db, 'guest_invites');
const visitorCollectionRef = collection(db, 'visitors');
const visitorImagesRef = sRef(storage, 'visitors');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Get Property Members ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* propertyMembersCollectionWatch({ payload }) {
    const { propertyId } = payload;
    let unsubscribePropertyMembersCollectionData;

    const propertyMembersCollectionQuery = query(
        userCollectionRef,
        where('property_ids', 'array-contains', propertyId)
    );

    const propertyMembersCollectionChannel = eventChannel(emit => {
        unsubscribePropertyMembersCollectionData = onSnapshot(
            propertyMembersCollectionQuery,
            querySnapshot => {
                const propertyMembers = [];
                querySnapshot.forEach(doc => {
                    propertyMembers.push(doc.data());
                });
                if (!querySnapshot.size) {
                    emit([]);
                } else {
                    emit(propertyMembers);
                }
            }
        );
        return unsubscribePropertyMembersCollectionData;
    });

    try {
        while (true) {
            const { userSignOut, resetPropertyMembers, propertyMembersCollectionData } =
                yield race({
                    userSignOut: take(LOGOUT_USER),
                    resetPropertyMembers: take(RESET_PROPERTY_MEMBERS),
                    propertyMembersCollectionData: take(propertyMembersCollectionChannel)
                });

            if (userSignOut || resetPropertyMembers) {
                propertyMembersCollectionChannel.close();
            } else {
                yield put(getPropertyMembersSuccess(propertyMembersCollectionData));
            }
        }
    } catch (error) {
        log('Virtual Guard Error: watching property members by property ID (FS)', {
            error,
            propertyId
        });
        yield put(getPropertyMembersFailure(error));
    } finally {
        unsubscribePropertyMembersCollectionData();
        if (yield cancelled()) {
            propertyMembersCollectionChannel.close();
            unsubscribePropertyMembersCollectionData();
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////// Get Invited Property Members ///////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* invitedPropertyMembersCollectionWatch({ payload }) {
    const { propertyId } = payload;
    let unsubscribeInvitedPropertyMembersCollectionData;

    const invitedPropertyMembersCollectionQuery = query(
        guestInvitesCollectionRef,
        where('property_id', '==', propertyId)
    );

    const invitedPropertyMembersCollectionChannel = eventChannel(emit => {
        unsubscribeInvitedPropertyMembersCollectionData = onSnapshot(
            invitedPropertyMembersCollectionQuery,
            querySnapshot => {
                const invitedMembers = [];
                querySnapshot.forEach(doc => {
                    invitedMembers.push(doc.data());
                });
                if (!querySnapshot.size) {
                    emit([]);
                } else {
                    emit(invitedMembers);
                }
            }
        );
        return unsubscribeInvitedPropertyMembersCollectionData;
    });

    try {
        while (true) {
            const {
                userSignOut,
                resetPropertyMembers,
                invitedPropertyMembersCollectionData
            } = yield race({
                userSignOut: take(LOGOUT_USER),
                resetPropertyMembers: take(RESET_PROPERTY_MEMBERS),
                invitedPropertyMembersCollectionData: take(
                    invitedPropertyMembersCollectionChannel
                )
            });

            if (userSignOut || resetPropertyMembers) {
                invitedPropertyMembersCollectionChannel.close();
            } else {
                yield put(
                    getInvitedPropertyMembersSuccess(invitedPropertyMembersCollectionData)
                );
            }
        }
    } catch (error) {
        log(
            'Virtual Guard Error: watching invited property members by property ID (FS)',
            {
                error,
                propertyId
            }
        );
        yield put(getInvitedPropertyMembersFailure(error));
    } finally {
        unsubscribeInvitedPropertyMembersCollectionData();
        if (yield cancelled()) {
            invitedPropertyMembersCollectionChannel.close();
            unsubscribeInvitedPropertyMembersCollectionData();
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Get Member Keys //////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* memberKeysCollectionWatch({ payload }) {
    const { propertyId, memberId } = payload;
    let unsubscribeMemberKeysCollectionData;
    const memberKeysCollectionQuery = query(
        accessKeysCollectionRef,
        where('property_id', '==', propertyId),
        where('consumer_id', '==', memberId)
    );

    const memberKeysCollectionChannel = eventChannel(emit => {
        unsubscribeMemberKeysCollectionData = onSnapshot(
            memberKeysCollectionQuery,
            querySnapshot => {
                const memberKeys = [];
                querySnapshot.forEach(doc => {
                    memberKeys.push(doc.data());
                });
                emit(memberKeys);
            }
        );

        return unsubscribeMemberKeysCollectionData;
    });

    try {
        while (true) {
            const { userSignOut, resetMemberKeys, memberKeysCollectionData } = yield race(
                {
                    userSignOut: take(LOGOUT_USER),
                    resetMemberKeys: take(RESET_MEMBER_KEYS),
                    memberKeysCollectionData: take(memberKeysCollectionChannel)
                }
            );

            if (userSignOut || resetMemberKeys) {
                memberKeysCollectionChannel.close();
            } else {
                yield put(getMemberKeysSuccess(memberKeysCollectionData));
            }
        }
    } catch (error) {
        log(
            'Virtual Guard Error: watching member access keys by uid and property ID (FS)',
            {
                error,
                propertyId,
                memberId
            }
        );
        yield put(getMemberKeysFailure(error));
    } finally {
        unsubscribeMemberKeysCollectionData();
        if (yield cancelled()) {
            memberKeysCollectionChannel.close();
            unsubscribeMemberKeysCollectionData();
        }
    }
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////// Upload Visitor Photo ID //////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

async function uploadVisitorPhotoId(visitorId, image) {
    if (!image) return;

    const avatarRef = sRef(visitorImagesRef, `${visitorId}/photo_id.jpeg`);
    await uploadBytes(avatarRef, image);

    return getDownloadURL(avatarRef);
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////// Allow Gate Access //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const allowingGateAccessRequest = async ({
    accessType,
    callData,
    intercom,
    activePanel,
    userData,
    member,
    consumerId,
    visitor,
    register,
    emergency,
    override,
    propertyId,
    orgName,
    accessVehicle
}) => {
    const company_name =
        visitor?.company_name && visitor?.company_name.trim() !== ''
            ? visitor.company_name
            : '';
    const name = visitor?.name && visitor?.name.trim() !== '' ? visitor.name : '';
    const org_id = activePanel.orgId;
    const panel_id = activePanel.id;
    const panel_name = activePanel.name;
    const guard_access = emergency ? true : false; // *** Revisit this key when Reservations are being integrated and new confirmed guest is entering with no UID yet ***
    const new_guest_confirm = false; // *** Revisit this key when Reservations are being integrated ***

    const accessLogEntryRef = ref(rtdb, `access_log/success`);
    const accessLogEntryObj = push(accessLogEntryRef);
    const logId = accessLogEntryObj.key;
    const accessLogRef = ref(rtdb, `access_log/success/${logId}`);

    const orgAccessLogEntryRef = ref(rtdb, `orgs/${org_id}/access_log`);
    const orgAccessLogEntryObj = push(orgAccessLogEntryRef);
    const orgLogId = orgAccessLogEntryObj.key;
    const orgAccessLogRef = ref(rtdb, `orgs/${org_id}/access_log/${orgLogId}`);
    const visitorId = doc(visitorCollectionRef).id;
    const visitorDocRef = doc(visitorCollectionRef, visitorId);

    try {
        const jsDate = new Date().toISOString();
        const logData = {
            access_time: jsDate,
            accessed_time_seconds: timeStampNowSeconds(),
            emergency,
            guard_access,
            id: logId,
            new_guest_confirm,
            org_id,
            org_log_id: orgLogId,
            override,
            panel_id,
            visitor: company_name === '' && name === '' ? null : { company_name, name }
        };

        if (visitor) {
            const tempVisitorImage = visitor.photo_id;

            await setDoc(visitorDocRef, { ...visitor, id: visitorId, photo_id: null });
            const visitorPhotoId = await uploadVisitorPhotoId(visitorId, tempVisitorImage);

            if (visitorPhotoId) {
                await setDoc(visitorDocRef, { photo_id: visitorPhotoId }, { merge: true });
            }
        }

        await set(accessLogRef, { ...logData, handled: false });

        await set(orgAccessLogRef, {
            access_type: accessType,
            ...logData,
            company: emergency
                ? userData.company_name
                    ? userData.company_name
                    : member?.company_name || ''
                : member?.company_name || '',
            consumer_id: visitor ? visitorId : consumerId,
            creator_id: userData.uid,
            first_name:
                emergency || logData.visitor ? userData.first_name : member.first_name,
            id: orgLogId,
            last_name:
                emergency || logData.visitor ? userData.last_name : member.last_name,
            org_log_id: null,
            panel_name,
            property_id: propertyId ? propertyId : '',
            role:
                emergency || logData.visitor
                    ? userData.role
                    : member?.properties[propertyId]?.role,
            // *** Revisit this key when Reservations are being integrated ***
            str: `${
                emergency || logData.visitor
                    ? capitalizeFirstLetterMulti(visitor.name)
                    : capitalizeFirstLetter(member.first_name)
            } ${
                emergency || logData.visitor
                    ? capitalizeFirstLetterMulti(visitor.company_name)
                    : capitalizeFirstLetter(member.last_name)
            } accessed ${orgName} - ${panel_name}`,
            success: true,
            system_log_id: logId,
            vehicle: accessVehicle
        });

        return { res: true };
    } catch (error) {
        return { error };
    }
};

export function* allowingGateAccess({ payload }) {
    const {
        accessType,
        callData,
        intercom,
        activePanel,
        userData,
        member,
        consumerId,
        visitor,
        register,
        emergency,
        override,
        propertyId,
        accessVehicle
    } = payload;
    const orgs = yield select(selectors._orgs);
    const orgName = activePanel
        ? orgs.filter(org => org.org_id === activePanel.orgId)[0].org_name
        : orgs.filter(org => org.org_id === userData.active_org_id)[0].org_name;
    const { res, error } = yield call(() =>
        allowingGateAccessRequest({
            accessType,
            callData,
            intercom,
            activePanel,
            userData,
            member,
            consumerId,
            visitor,
            register,
            emergency,
            override,
            propertyId,
            orgName,
            accessVehicle
        })
    );
    if (res) {
        yield put(allowGateAccessSuccess());
        yield put(
            openSnackbar({
                open: true,
                message: 'Success: Access has been granted!',
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
    } else {
        yield put(allowGateAccessFailure(error));
        yield put(
            openSnackbar({
                open: true,
                message: 'Error: An error has occurred, the gate may not have opened.',
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
        log('Allowing Gate Access: error granting access to gate (RTDB)', {
            error,
            accessType,
            callData,
            intercom,
            activePanel,
            userData,
            member,
            consumerId,
            visitor,
            register,
            emergency,
            override,
            propertyId,
            accessVehicle
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////// Disallow Gate Access /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const disallowingGateAccessRequest = async ({
    accessType,
    callData,
    intercom,
    activePanel,
    userData,
    member,
    consumerId,
    visitor,
    register,
    emergency,
    override,
    propertyId,
    orgName,
    accessVehicle
}) => {
    const company_name =
        visitor?.company_name && visitor?.company_name.trim() !== ''
            ? visitor.company_name
            : '';
    const name = visitor?.name && visitor?.name.trim() !== '' ? visitor.name : '';
    const org_id = activePanel ? activePanel.orgId : userData.active_org_id;
    const panel_id = activePanel ? activePanel?.id : '';
    const panel_name = activePanel ? activePanel?.name : '';
    const guard_access = emergency ? true : false; // *** Revisit this key when Reservations are being integrated and new confirmed guest is entering with no UID yet ***
    const new_guest_confirm = false; // *** Revisit this key when Reservations are being integrated ***

    const accessLogEntryRef = ref(rtdb, `access_log/failure`);
    const accessLogEntryObj = push(accessLogEntryRef);
    const logId = accessLogEntryObj.key;
    const accessLogRef = ref(rtdb, `access_log/failure/${logId}`);

    const orgAccessLogEntryRef = ref(rtdb, `orgs/${org_id}/access_log`);
    const orgAccessLogEntryObj = push(orgAccessLogEntryRef);
    const orgLogId = orgAccessLogEntryObj.key;
    const orgAccessLogRef = ref(rtdb, `orgs/${org_id}/access_log/${orgLogId}`);
    const visitorId = doc(visitorCollectionRef).id;
    const visitorDocRef = doc(visitorCollectionRef, visitorId);

    try {
        const jsDate = new Date().toISOString();
        const logData = {
            access_time: jsDate,
            accessed_time_seconds: timeStampNowSeconds(),
            emergency,
            guard_access,
            id: logId,
            new_guest_confirm,
            org_id,
            org_log_id: orgLogId,
            override,
            panel_id,
            visitor: company_name === '' && name === '' ? null : { company_name, name }
        };

        if (visitor) {
            const tempVisitorImage = visitor.photo_id;

            await setDoc(visitorDocRef, { ...visitor, id: visitorId, photo_id: null });
            const visitorPhotoId = await uploadVisitorPhotoId(visitorId, tempVisitorImage);

            if (visitorPhotoId) {
                await setDoc(visitorDocRef, { photo_id: visitorPhotoId }, { merge: true });
            }
        }

        await set(accessLogRef, { ...logData, handled: false });

        await set(orgAccessLogRef, {
            access_type: accessType,
            ...logData,
            company: emergency
                ? userData.company_name
                    ? userData.company_name
                    : member?.company_name || ''
                : member?.company_name || '',
            consumer_id: visitor ? visitorId : consumerId,
            creator_id: userData.uid,
            first_name: emergency || visitor ? userData.first_name : member.first_name,
            id: orgLogId,
            last_name: emergency || visitor ? userData.last_name : member.last_name,
            org_log_id: null,
            panel_name,
            property_id: propertyId ? propertyId : '',
            role:
                emergency || visitor
                    ? userData.role
                    : member?.properties[propertyId]?.role, // *** Revisit this key when Reservations are being integrated ***
            str: `${
                emergency || logData.visitor
                    ? capitalizeFirstLetterMulti(visitor.name)
                    : capitalizeFirstLetter(member.first_name)
            } ${
                emergency || logData.visitor
                    ? capitalizeFirstLetterMulti(visitor.company_name)
                    : capitalizeFirstLetter(member.last_name)
            } was Denied Entry at ${orgName}${
                panel_name.trim() !== '' ? ` - ${panel_name}` : ''
            }`,
            success: false,
            system_log_id: logId,
            vehicle: accessVehicle
        });

        return { res: true };
    } catch (error) {
        return { error };
    }
};

export function* disallowingGateAccess({ payload }) {
    const {
        accessType,
        callData,
        intercom,
        activePanel,
        userData,
        member,
        consumerId,
        visitor,
        register,
        emergency,
        override,
        propertyId,
        accessVehicle
    } = payload;
    const orgs = yield select(selectors._orgs);
    const orgName = activePanel
        ? orgs.filter(org => org.org_id === activePanel.orgId)[0].org_name
        : orgs.filter(org => org.org_id === userData.active_org_id)[0].org_name;
    const { res, error } = yield call(() =>
        disallowingGateAccessRequest({
            accessType,
            callData,
            intercom,
            activePanel,
            userData,
            member,
            consumerId,
            visitor,
            register,
            emergency,
            override,
            propertyId,
            orgName,
            accessVehicle
        })
    );
    if (res) {
        yield put(disallowGateAccessSuccess());
        yield put(
            openSnackbar({
                open: true,
                message: 'Success: Access has been denied!',
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
    } else {
        yield put(disallowGateAccessFailure(error));
        log('Disallowing Gate Access: error denying access to gate (RTDB)', {
            error,
            accessType,
            callData,
            intercom,
            activePanel,
            userData,
            member,
            consumerId,
            visitor,
            register,
            emergency,
            override,
            propertyId,
            accessVehicle
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* getPropertyMembers() {
    yield takeLatest(GET_PROPERTY_MEMBERS, propertyMembersCollectionWatch);
}

export function* getInvitedPropertyMembers() {
    yield takeLatest(GET_PROPERTY_MEMBERS, invitedPropertyMembersCollectionWatch);
}

export function* getMemberKeysCollection() {
    yield takeLatest(GET_MEMBER_KEYS, memberKeysCollectionWatch);
}

export function* allowGateAccess() {
    yield takeLatest(ALLOW_GATE_ACCESS, allowingGateAccess);
}

export function* disallowGateAccess() {
    yield takeLatest(DISALLOW_GATE_ACCESS, disallowingGateAccess);
}

export default function* rootSaga() {
    yield all([
        fork(getPropertyMembers),
        fork(getInvitedPropertyMembers),
        fork(getMemberKeysCollection),
        fork(allowGateAccess),
        fork(disallowGateAccess)
    ]);
}
