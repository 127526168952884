// React
import React, { useEffect, useState, useCallback } from 'react';

// Assets
import { DeleteFilled } from '@ant-design/icons';

// Components
import IconButton from 'components/@extended/IconButton';

// Packages
import { Loader } from 'components';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
    FormHelperText,
    FormControlLabel
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone-lite';

// Styles
import 'react-widgets/scss/styles.scss';

// Utils
import { countries, errorMessage, nameRegExp, roleTypes } from 'utils/constants';

const RequestDetailsModal = ({
    onClose,
    selectedRegister,
    removingRegisters,
    updatingRegister,
    members,
    pending,
    registrations,
    properties,
    success,
    loading,
    error
}) => {
    const [selectedProperty, setSelectedProperty] = useState(null);
    const submit = values => {
        const formattedPhone = phone => {
            return phone.replace(/[^0-9]/g, '').length > 10
                ? `${phone
                      .replace(/[^0-9]/g, '')
                      .substring(phone.replace(/[^0-9]/g, '').length - 10)}`
                : `${phone.replace(/[^0-9]/g, '')}`;
        };

        const property_id = findPropertyIdByAddress(values.address);

        const {
            address,
            email,
            first_name,
            last_name,
            phone,
            code,
            country,
            calls,
            invite,
            type
        } = values;

        const register = {
            address,
            email: email.trim().toLowerCase(),
            first_name: first_name.trim().toLowerCase(),
            id: selectedRegister.id,
            devices: selectedRegister?.devices || null,
            last_name: last_name.trim().toLowerCase(),
            permissions: { calls, invite },
            phone: {
                number: Number.parseInt(formattedPhone(phone.toString())),
                code: Number.parseInt(code.toString()),
                country
            },
            property_id,
            registered_at: selectedRegister.registered_at,
            type
        };
        updatingRegister({ register });
    };

    const getSelectedProperty = useCallback(
        id => {
            const property = properties.filter(prop => prop.id === id)[0];
            setSelectedProperty(property);
        },
        [properties]
    );

    useEffect(() => {
        if (selectedRegister && !selectedProperty) {
            getSelectedProperty(selectedRegister.property_id);
        }
    }, [selectedProperty, selectedRegister, getSelectedProperty]);

    useEffect(() => {
        if (success) {
            onClose();
        }
    }, [success, onClose]);

    const initialValues = {
        address: selectedProperty?.address || selectedRegister?.address, // get Address by prop id

        email: selectedRegister?.email ? selectedRegister.email.trim() : '',
        first_name: selectedRegister?.first_name?.trim() || '',

        last_name: selectedRegister?.last_name?.trim() || '',

        phone: selectedRegister?.phone?.number ? `${selectedRegister.phone.number}` : '',
        code: selectedRegister?.phone?.code ? `${selectedRegister.phone.code}` : '1',
        country: selectedRegister?.phone?.country
            ? `${selectedRegister.phone.country}`
            : 'US',
        calls: selectedRegister?.permissions.calls || false,
        invite: selectedRegister?.permissions.invite || false,
        type: selectedRegister?.type
    };

    const validationSchema = Yup.object().shape({
        address: Yup.string().required(errorMessage.address.required),
        first_name: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.firstName.valid)
            .required(errorMessage.firstName.required),
        last_name: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.lastName.valid)
            .required(errorMessage.lastName.required),
        email: Yup.string().email(errorMessage.email.valid),
        phone: Yup.string().required(errorMessage.mobilePhone.required),
        country: Yup.string().trim(),
        code: Yup.string().trim(),
        calls: Yup.boolean(),
        invite: Yup.boolean(),
        type: Yup.string().required('Type is required')
    });

    const validatePhoneEmail = ({ phone, code, country, email }) => {
        const propertyId = selectedProperty.id;

        const errorType = {
            member: 'Community Member',
            pending: 'Pending Invite',
            reg: 'Invite Request'
        };

        const checkExistingEmail = (type, email, data) => {
            return {
                result: data.some(
                    obj =>
                        obj.email === email.trim() &&
                        obj.property_ids.includes(propertyId)
                ),
                type
            };
        };

        const checkExistingPhone = (type, phone, data) => {
            return {
                result: data.some(
                    obj =>
                        obj.phone === phone.trim() &&
                        obj.property_ids.includes(propertyId)
                ),
                type
            };
        };

        const phoneSchema = Yup.string().phone(country);
        const isPhoneValid = phone ? phoneSchema.isValidSync(code + phone) : true;

        const phoneCheck = {
            member: checkExistingPhone('member', phone, members),
            pending: checkExistingPhone('pending', phone, pending),
            reg: checkExistingPhone('reg', phone, registrations)
        };
        const emailCheck = {
            member: checkExistingEmail('member', email, members),
            pending: checkExistingEmail('pending', email, pending),
            reg: checkExistingEmail('reg', email, registrations)
        };

        const emailError = Object.values(emailCheck).filter(err => err.result);
        const phoneError = Object.values(phoneCheck).filter(err => err.result);

        if (emailError.length) {
            return {
                email: !emailError.length
                    ? undefined
                    : `Email matches an existing ${
                          errorType[emailError[0].type]
                      } for this specific property`
            };
        } else if (phoneError.length) {
            return {
                phone: !phoneError.length
                    ? undefined
                    : `Phone number matches an existing ${
                          errorType[phoneError[0].type]
                      } for this specific property`
            };
        } else if (!isPhoneValid) {
            return {
                phone: isPhoneValid
                    ? undefined
                    : 'Mobile phone needs to be a valid number'
            };
        }
    };

    const findPropertyIdByAddress = address => {
        for (const id in properties) {
            if (Object.prototype.hasOwnProperty.call(properties, id)) {
                const property = properties[id];
                if (property.address === address) {
                    return properties[id].id;
                }
            }
        }
        return null;
    };

    const handleRemove = () => {
        removingRegisters({ registers: [selectedRegister] });
        onClose();
    };

    if (!selectedRegister || !selectedProperty) return <Loader />;

    return (
        <>
            <DialogTitle>{`Edit Member Registration`}</DialogTitle>
            <Divider />
            <Formik
                initialValues={initialValues}
                onSubmit={submit}
                validate={validatePhoneEmail}
                validationSchema={validationSchema}
                validateOnChange
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    touched,
                    values
                }) => (
                    <>
                        <DialogContent sx={{ p: 2.5, maxHeight: '70vh' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    <Stack spacing={0.5}>
                                        <InputLabel>Street Address</InputLabel>
                                        <Autocomplete
                                            id="address"
                                            name="address"
                                            options={Object.values(properties || {})}
                                            getOptionLabel={option => option.address}
                                            defaultValue={selectedProperty}
                                            onChange={(_, newValue) => {
                                                if (newValue) {
                                                    handleChange({
                                                        target: {
                                                            name: 'address',
                                                            value: newValue.address
                                                        }
                                                    });
                                                    setSelectedProperty(newValue);
                                                }
                                            }}
                                            onBlur={handleBlur}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    placeholder="1234 Main St"
                                                    fullWidth
                                                    autoComplete="shipping address-line1"
                                                    error={
                                                        touched.address &&
                                                        !!errors.address
                                                    }
                                                    helperText={
                                                        touched.address && errors.address
                                                    }
                                                    value={values.address}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Stack spacing={0.5}>
                                        <InputLabel>First Name</InputLabel>
                                        <TextField
                                            required
                                            id="firstNameBasic"
                                            name="first_name"
                                            placeholder="First Name"
                                            fullWidth
                                            autoComplete="given-name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.first_name && !!errors.first_name
                                            }
                                            helperText={
                                                touched.first_name && errors.first_name
                                            }
                                            value={values.first_name}
                                            inputProps={{
                                                style: { textTransform: 'capitalize' }
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Stack spacing={0.5}>
                                        <InputLabel>Last Name</InputLabel>
                                        <TextField
                                            required
                                            id="lastNameBasic"
                                            name="last_name"
                                            placeholder="Last name"
                                            fullWidth
                                            autoComplete="family-name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.last_name && !!errors.last_name
                                            }
                                            helperText={
                                                touched.last_name && errors.last_name
                                            }
                                            value={values.last_name}
                                            inputProps={{
                                                style: { textTransform: 'capitalize' }
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Stack spacing={0.5}>
                                        <InputLabel>Email</InputLabel>
                                        <TextField
                                            required
                                            id="countryBasic"
                                            name="email"
                                            placeholder="Email"
                                            fullWidth
                                            autoComplete="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.email && !!errors.email}
                                            helperText={touched.email && errors.email}
                                            value={values.email}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <Stack spacing={0.5}>
                                        <InputLabel htmlFor="phone">Area Code</InputLabel>
                                        <Select
                                            id="areaCodeSelect"
                                            name="country"
                                            onChange={e => {
                                                const selectedCountry = countries.find(
                                                    country =>
                                                        country.value === e.target.value
                                                );
                                                if (selectedCountry) {
                                                    setFieldValue(
                                                        'code',
                                                        selectedCountry.phoneCode
                                                    );
                                                    setFieldValue(
                                                        'country',
                                                        selectedCountry.value
                                                    );
                                                }
                                            }}
                                            value={values.country}
                                            label="Area Code"
                                        >
                                            {countries.map(country => (
                                                <MenuItem
                                                    key={country.value}
                                                    value={country.value}
                                                >
                                                    {country.flagIcon} +
                                                    {country.phoneCode}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Stack>
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    <Stack spacing={0.5}>
                                        <InputLabel>Mobile Phone</InputLabel>
                                        <TextField
                                            required
                                            id="phoneNumberBasic"
                                            name="phone"
                                            placeholder="Phone"
                                            fullWidth
                                            autoComplete="given-name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.phone && !!errors.phone}
                                            helperText={touched.phone && errors.phone}
                                            value={values.phone}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        sx={{ marginBottom: '20px' }}
                                    >
                                        <Stack spacing={0.5}>
                                            <InputLabel htmlFor="type">Type</InputLabel>
                                            <Select
                                                required
                                                id="typeSelect"
                                                name="type"
                                                sx={{
                                                    textTransform: 'capitalize'
                                                }}
                                                onChange={e => {
                                                    setFieldValue('type', e.target.value);
                                                    if (e.target.value === 'minor') {
                                                        setFieldValue('calls', false);
                                                        setFieldValue('invite', false);
                                                    }
                                                }}
                                                value={values.type}
                                                label="Type"
                                                error={Boolean(
                                                    touched.type && errors.type
                                                )}
                                            >
                                                {roleTypes.map(item => (
                                                    <MenuItem
                                                        key={item}
                                                        value={item}
                                                        sx={{
                                                            textTransform: 'capitalize'
                                                        }}
                                                    >
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {touched.type && errors.type ? (
                                                <FormHelperText
                                                    sx={{
                                                        color: '#F04134',
                                                        marginLeft: '16px !important'
                                                    }}
                                                >
                                                    {touched.type && errors.type}
                                                </FormHelperText>
                                            ) : null}
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid item xs={12} sm={12} sx={{ marginTop: '18%' }}>
                                        <Stack spacing={0.5}>
                                            <Box>
                                                <FormControlLabel
                                                    value="end"
                                                    control={
                                                        <Checkbox
                                                            checked={values.calls}
                                                            disabled={
                                                                values.type === 'minor'
                                                            }
                                                            onChange={() =>
                                                                setFieldValue(
                                                                    'calls',
                                                                    !values.calls
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            sx={{
                                                                color: '#8c8c8c'
                                                            }}
                                                        >
                                                            Calls
                                                        </Typography>
                                                    }
                                                    labelPlacement="start"
                                                    sx={{ ml: 0 }}
                                                />
                                            </Box>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid item xs={12} sm={12} sx={{ marginTop: '18%' }}>
                                        <Stack spacing={0.5}>
                                            <Box>
                                                <FormControlLabel
                                                    value="end"
                                                    control={
                                                        <Checkbox
                                                            checked={values.invite}
                                                            disabled={
                                                                values.type === 'minor'
                                                            }
                                                            onChange={() =>
                                                                setFieldValue(
                                                                    'invite',
                                                                    !values.invite
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            sx={{
                                                                color: '#8c8c8c'
                                                            }}
                                                        >
                                                            Invite
                                                        </Typography>
                                                    }
                                                    labelPlacement="start"
                                                    sx={{ ml: 0 }}
                                                />
                                            </Box>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ p: 2.5 }}>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item>
                                    <Tooltip title="Delete Registration" placement="top">
                                        <IconButton
                                            size="large"
                                            color="error"
                                            onClick={handleRemove}
                                        >
                                            <DeleteFilled />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Button color="error" onClick={onClose}>
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Update
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </>
    );
};

export default RequestDetailsModal;
