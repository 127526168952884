import axiosInstance from './axios-instance';
import { FormHelper } from '../../utils/helpers/form.helper';

class ApiClient {
    static async get(endpoint, queryParams = {}, config = {}) {
        return axiosInstance.get(endpoint, {
            params: queryParams,
            ...config
        });
    }

    static async post(endpoint, body, queryParams = {}) {
        return axiosInstance.post(endpoint, body, {
            params: queryParams
        });
    }

    static async postFormData(endpoint, body, queryParams = {}) {
        return axiosInstance.post(endpoint, body, {
            params: queryParams,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static async put(endpoint, body, queryParams = {}) {
        return axiosInstance.put(endpoint, body, {
            params: queryParams
        });
    }

    static async putFormData(endpoint, body, queryParams = {}) {
        const formData = FormHelper.generateFormData(body);

        return axiosInstance.put(endpoint, formData, {
            params: queryParams,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static async delete(endpoint, queryParams = {}) {
        return axiosInstance.delete(endpoint, {
            params: queryParams
        });
    }

    static async postFile(endpoint, file, headers = {}) {
        return axiosInstance.post(endpoint, file, {
            params: {},
            headers: {
                'Content-Type': 'application/octet-stream',
                ...headers,
                'Content-Disposition': `attachment; filename="${file.name}"`,
                'x-file-name': file.name,
                'x-file-type': file.type,
                'x-file-size': file.size
            }
        });
    }
}

export default ApiClient;
