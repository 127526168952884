// React
import React, { useState, useEffect, useCallback } from 'react';

// Assets
import {
    IdcardOutlined,
    CloseCircleOutlined,
    PlusCircleOutlined
} from '@ant-design/icons';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

// Firebase
import { timeStampNowSeconds } from 'config/firebase';

// Packages
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import { PatternFormat } from 'react-number-format';
import {
    Button,
    IconButton,
    Stack,
    Typography,
    List,
    ListItem,
    ListItemText,
    Grid,
    InputLabel,
    TextField,
    Select,
    MenuItem
} from '@mui/material';

// Styles
import '../../styles/image-zoom.css';

// Utils
import { unitedStates } from 'utils/constants';

const MemberDetails = ({
    member,
    setZoomActive,
    memberKeys,
    activePanel,
    setActivePanel,
    accessConfirm,
    setAccessConfirm,
    panels,
    propertyId,
    setOverride,
    handleGateAccess,
    setAccessVehicle,
    accessVehicle
}) => {
    const [zoomedId, setZoomedId] = useState(false);
    const [zoomedVehicle, setZoomedVehicle] = useState(false);
    const [access, setAccess] = useState(null);
    const [showAccess, setShowAccess] = useState(null);
    const [tag, setTag] = useState('');
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [year, setYear] = useState('');
    const [state, setState] = useState('');
    const [color, setColor] = useState('');
    const [vehicleNotes, setVehicleNotes] = useState('');
    const [vehicleOption, setVehicleOption] = useState(null);
    const [vehicles, setVehicles] = useState([]);
    const [existingVehicle, setExistingVehicle] = useState('');

    // const vehicle = member?.vehicles?.length
    //     ? member.vehicles.filter(vehicle => vehicle.primary)[0]
    //     : null;

    useEffect(() => {
        if (member && member.vehicles && member.vehicles.length) {
            const memberVehicles = member.vehicles.map(vehicle => {
                return {
                    key: vehicle.tag,
                    value: `${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.tag}`
                };
            });
            setVehicles([...memberVehicles]);
        }
    }, [member, setVehicles]);

    const getVehicle = () => {
        if (vehicleOption === 'no_vehicle') {
            if (vehicleNotes.trim() === '') return false;
            return {
                color: '',
                country: '',
                make: '',
                model: '',
                primary: false,
                state: '',
                tag: '',
                year: '',
                notes: vehicleNotes.trim()
            };
        } else if (vehicleOption === 'add_vehicle') {
            if (`${color}${make}${model}${state}${tag}${year}`.trim() === '')
                return false;
            return {
                color: color.trim(),
                country: state ? 'USA' : '',
                make: make.trim(),
                model: model.trim(),
                primary: false,
                state: state.trim(),
                tag: tag.toUpperCase().trim(),
                year: year.trim(),
                notes: vehicleNotes.trim()
            };
        } else if (vehicleOption === 'existing_vehicle') {
            const foundVehicle = member.vehicles.filter(
                memberVehicle =>
                    `${memberVehicle.year} ${memberVehicle.make} ${memberVehicle.model} ${memberVehicle.tag}`
                        .trim()
                        .toLowerCase() === existingVehicle.trim().toLowerCase()
            )[0];
            return {
                color: foundVehicle.color ? foundVehicle.color.trim() : '',
                country: foundVehicle.country ? foundVehicle.country.trim() : '',
                make: foundVehicle.make ? foundVehicle.make.trim() : '',
                model: foundVehicle.model ? foundVehicle.model.trim() : '',
                primary: foundVehicle.primary,
                state: foundVehicle.state ? foundVehicle.state.trim() : '',
                tag: foundVehicle.tag ? foundVehicle.tag.toUpperCase().trim() : '',
                year: foundVehicle.year ? foundVehicle.year.trim() : '',
                notes: vehicleNotes.trim()
            };
        } else {
            return false;
        }
    };

    const orgId = member.invited_at
        ? member.org_id
        : member?.properties[propertyId]?.org_id;

    const panelList = orgId ? panels[orgId] : [];

    const handleVehicleSelect = vehicle => {
        setVehicleOption('existing_vehicle');
        setExistingVehicle(vehicle);
    };

    const getAccess = useCallback((keys, member) => {
        if (keys && keys.length) {
            return keys.some(key => {
                const isValid = key => {
                    const today = new Date();
                    const startTime = new Date();
                    const endTime = new Date();
                    const nowMs = timeStampNowSeconds() * 1000;
                    const nowSec = timeStampNowSeconds();
                    const day = today.toLocaleString('en-US', { weekday: 'short' });
                    const dayStr = day.toLowerCase();
                    const beginSec = key.access_begins ? key.access_begins.seconds : null;
                    const expireSec = key.access_expires
                        ? key.access_expires.seconds
                        : null;

                    /**** Valid Day ****/
                    const includesDay = key?.access_days?.length
                        ? key.access_days.includes(dayStr)
                        : true;

                    /**** Valid Time Window by Day ****/
                    const start = key.access_start_time
                        ? startTime.setHours(
                              key.access_start_time.hours,
                              key.access_start_time.minutes,
                              0,
                              0
                          )
                        : key.access_start_time;

                    const end = key.access_end_time
                        ? endTime.setHours(
                              key.access_end_time.hours,
                              key.access_end_time.minutes,
                              0,
                              0
                          )
                        : key.access_end_time;

                    const inTimeWindow =
                        !start && !end ? true : nowMs >= start && nowMs <= end;

                    /**** Valid Time Window by Total Duration ****/
                    const inExpireWindow = !beginSec
                        ? !expireSec
                            ? true
                            : expireSec >= nowSec
                        : beginSec <= nowSec
                        ? expireSec
                            ? expireSec >= nowSec
                            : true
                        : false;

                    /**** Valid by Suspension ****/
                    const suspended = key.suspended;
                    return includesDay && inTimeWindow && inExpireWindow && !suspended;
                };
                const hasAccess = isValid(key) && !member.super_suspend;
                setShowAccess(true);
                setAccess(hasAccess);
                return hasAccess;
            });
        }
        setAccess(false);
        setShowAccess(true);
        return false;
    }, []);

    useEffect(() => {
        if (member) {
            if (member.invited_at) {
                setShowAccess(true);
            }
            if (memberKeys) {
                getAccess(memberKeys, member);
            }
        }
    }, [memberKeys, member, getAccess, setShowAccess]);

    useEffect(() => {
        if (showAccess) {
            setOverride(!access);
        }
    }, [access, showAccess, setOverride]);

    const handleZoom = useCallback(
        (type, state) => {
            setZoomActive(true);
            setZoomedId(type === 'id' ? state : false);
            setZoomedVehicle(type === 'id' ? false : state);
        },
        [setZoomActive]
    );

    const handleConfirmAccess = () => {
        if (accessConfirm) {
            setAccessVehicle(getVehicle());
        } else {
            setAccessConfirm(true);
        }
    };

    const handlePanelSelect = panel => {
        const panelData = {
            orgId,
            id: panel.id,
            name: panel.name
        };
        setActivePanel({
            ...panelData
        });
    };

    useEffect(() => {
        if (accessVehicle) {
            handleGateAccess();
        }
    }, [accessVehicle, handleGateAccess]);

    if (!showAccess) return null;

    return (
        <Stack
            sx={{
                pt: 1,
                pl: 3,
                pr: 3,
                justifyContent: 'space-between',
                minHeight: '90vh'
            }}
        >
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}
            >
                <Stack>
                    <Stack
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: '4.4vh',
                            width: '100%',
                            marginBottom: '2vh'
                        }}
                    >
                        <Stack
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                width: '100%'
                            }}
                        >
                            <Typography
                                sx={{
                                    width: '3.6vw',
                                    fontWeight: 700
                                }}
                                variant="h6"
                                component="span"
                            >
                                Phone:
                            </Typography>
                            <Typography variant="h6" component="span">
                                <PatternFormat
                                    value={member?.phone?.number ? member.phone.number : ''}
                                    displayType={'text'}
                                    format="###-###-####"
                                    mask="_"
                                />
                            </Typography>
                        </Stack>
                        <Stack
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                width: '100%'
                            }}
                        >
                            <Typography
                                sx={{
                                    width: '3.6vw',
                                    fontWeight: 700
                                }}
                                variant="h6"
                                component="span"
                            >
                                Email:
                            </Typography>
                            <Typography variant="h6" component="span">
                                {member.email}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            height: '1.8vh',
                            width: '100%'
                        }}
                    >
                        <Typography
                            sx={{
                                fontStyle: 'italic',
                                textTransform: 'capitalize',
                                fontSize: '13px',
                                color:
                                    access === false ||
                                    (!memberKeys &&
                                        !memberKeys?.length &&
                                        !member?.invited_at)
                                        ? 'rgba(230, 58, 58, 1)'
                                        : ''
                            }}
                            variant="h6"
                            component="span"
                        >
                            {member?.invited_at
                                ? 'Access Pending'
                                : access === false || (!memberKeys && !memberKeys?.length)
                                    ? 'Access Prohibited'
                                    : 'Access Allowed'}
                        </Typography>
                        <Typography
                            sx={{
                                fontStyle: 'italic',
                                textTransform: 'capitalize',
                                fontSize: '13px',
                                fontWeight: '600',
                                marginLeft: '1vw',
                                color:
                                    access === false || (!memberKeys && !memberKeys?.length)
                                        ? 'rgba(230, 58, 58, 1)'
                                        : ''
                            }}
                            variant="h6"
                            component="span"
                        >
                            {member.super_suspend ? '**Administrative Suspension**' : ''}
                        </Typography>
                    </Stack>
                    <Stack
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            height: '21vh',
                            width: '100%',
                            marginTop: '4vh'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '12px',
                                marginBottom: '1vh',
                                fontWeight: '700'
                            }}
                            component="span"
                            variant="body1"
                        >
                            Identification:
                        </Typography>
                        <Stack
                            sx={{
                                height: '16.5vh',
                                width: '100%',
                                borderRadius: '1em'
                            }}
                        >
                            {member?.photo_id ? (
                                <ControlledZoom isZoomed={zoomedId} onZoomChange={handleZoom}>
                                    <img
                                        style={{
                                            height: '18.5vh',
                                            width: '100%',
                                            borderRadius: '1em',
                                            objectFit: 'fill'
                                        }}
                                        src={member.photo_id}
                                        loading="lazy"
                                        alt={'identification'}
                                    />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            bottom: '1.5vh',
                                            right: '.75vw',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <IconButton
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '2.8vh',
                                                height: '2.8vh',
                                                backgroundColor: 'rgba(0, 0, 0, 0.36)',
                                                borderRadius: '8px',
                                                padding: 0,
                                                margin: 0,
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => handleZoom('id', !zoomedId)}
                                        >
                                            {!zoomedId ? (
                                                <ZoomOutMapIcon
                                                    style={{
                                                        width: '75%',
                                                        color: 'rgba(255,255,255,1)',
                                                        padding: 0,
                                                        margin: 0,
                                                        cursor: 'pointer'
                                                    }}
                                                />
                                            ) : (
                                                <ZoomInMapIcon
                                                    style={{
                                                        width: '75%',
                                                        color: 'rgba(255,255,255,1)',
                                                        padding: 0,
                                                        margin: 0,
                                                        cursor: 'pointer'
                                                    }}
                                                />
                                            )}
                                        </IconButton>
                                    </div>
                                </ControlledZoom>
                            ) : (
                                <Stack
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        width: '100%',
                                        borderRadius: '1em',
                                        backgroundColor: 'rgba(208, 208, 208, 1)'
                                    }}
                                >
                                    <IdcardOutlined
                                        style={{
                                            color: 'rgba(255,255,255,1)',
                                            fontSize: '5vw'
                                        }}
                                    />
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                    <Stack
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            width: '100%',
                            marginTop: '4vh'
                        }}
                    >
                        <Stack
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                width: '100%'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '12px',

                                    fontWeight: 700,
                                    marginRight: '.25vw'
                                }}
                                component="span"
                                variant="body1"
                                color="error"
                            >
                                *
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '12px',

                                    fontWeight: 700
                                }}
                                component="span"
                                variant="body1"
                            >
                                Vehicle:
                            </Typography>
                        </Stack>
                        <Stack
                            sx={{
                                width: '100%'
                            }}
                        >
                            <Grid container columnSpacing={2}>
                                {vehicles && vehicles.length ? (
                                    <Grid item xs={12} sm={12}>
                                        <Grid item xs={12} sm={12}>
                                            <Stack>
                                                <Select
                                                    displayEmpty
                                                    variant="outlined"
                                                    renderValue={value => {
                                                        if (value.trim() === '') {
                                                            return (
                                                                <Typography color="gray">
                                                                    Select Vehicle
                                                                </Typography>
                                                            );
                                                        }
                                                        return <>{value}</>;
                                                    }}
                                                    label={'Vehicle'}
                                                    name={'vehicle'}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 48 * 3.5 + 8
                                                            }
                                                        }
                                                    }}
                                                    onChange={e =>
                                                        handleVehicleSelect(e.target.value)
                                                    }
                                                    value={existingVehicle}
                                                >
                                                    {vehicles.map(vehicle => (
                                                        <MenuItem
                                                            key={vehicle.key}
                                                            value={vehicle.value}
                                                            sx={{
                                                                textTransform: 'capitalize'
                                                            }}
                                                        >
                                                            {vehicle.value}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                ) : null}
                                <Stack
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        height: '6vh',
                                        width: '100%',
                                        marginLeft: 2
                                    }}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        sx={{
                                            marginTop: '1vh',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Grid item xs={12} sm={5.8}>
                                            <IconButton
                                                sx={{
                                                    width: '100%',
                                                    height: '4.5vh',
                                                    borderRadius: '26.5px',
                                                    '&:active::after': {
                                                        borderRadius: '26.5px'
                                                    },
                                                    fontSize: '0.9375rem',
                                                    fontWeight: '600',
                                                    backgroundColor: 'rgba(58, 230, 137, 1)',
                                                    '&:hover': {
                                                        backgroundColor: '#13af74'
                                                    }
                                                }}
                                                onClick={() => {
                                                    setVehicleOption('add_vehicle');
                                                    setExistingVehicle('');
                                                }}
                                                variant="contained"
                                                aria-label="add"
                                            >
                                                <PlusCircleOutlined
                                                    style={{
                                                        color: 'rgba(255,255,255,1)',
                                                        fontSize: '1.5em'
                                                    }}
                                                />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={12} sm={5.8}>
                                            <Button
                                                sx={{
                                                    width: '100%',
                                                    height: '4.5vh',
                                                    borderRadius: '26.5px',
                                                    '&:active::after': {
                                                        borderRadius: '26.5px'
                                                    },
                                                    fontSize: '0.9375rem',
                                                    fontWeight: '600',
                                                    backgroundColor: 'rgba(230, 58, 58, 1)',
                                                    '&:hover': {
                                                        backgroundColor: '#af1313',
                                                    }
                                                }}
                                                onClick={() => {
                                                    setVehicleOption('no_vehicle');
                                                    setExistingVehicle('');
                                                }}
                                                variant="contained"
                                            >
                                                No Vehicle
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Grid>
                        </Stack>

                        <Stack
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: '1.5vh',
                                marginBottom: '2vh',
                                width: '100%'
                            }}
                        >
                            {vehicleOption && vehicleOption === 'add_vehicle' && (
                                <Grid container columnSpacing={2} rowSpacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <Stack>
                                            <InputLabel htmlFor={'tag'}>Plate #</InputLabel>
                                            <TextField
                                                name="tag"
                                                id={'tagInput'}
                                                placeholder="Plate/Tag #"
                                                inputProps={{
                                                    style: { textTransform: 'uppercase' }
                                                }}
                                                onChange={e => setTag(e.target.value)}
                                                value={tag}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack>
                                            <InputLabel htmlFor={'state'}>State</InputLabel>
                                            <Select
                                                variant="outlined"
                                                label={'State'}
                                                name={'state'}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 48 * 3.5 + 8
                                                        }
                                                    }
                                                }}
                                                onChange={e => setState(e.target.value)}
                                                value={state}
                                            >
                                                {unitedStates.map(state => (
                                                    <MenuItem
                                                        key={state.value}
                                                        value={state.value}
                                                    >
                                                        {state.value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack>
                                            <InputLabel htmlFor={'make'}>Make</InputLabel>
                                            <TextField
                                                name="make"
                                                onChange={e => setMake(e.target.value)}
                                                value={make}
                                                placeholder="Make"
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack>
                                            <InputLabel htmlFor={'model'}>Model</InputLabel>
                                            <TextField
                                                name="model"
                                                placeholder="Model"
                                                onChange={e => setModel(e.target.value)}
                                                value={model}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack>
                                            <InputLabel htmlFor={'color'}>Color</InputLabel>
                                            <TextField
                                                name="color"
                                                onChange={e => setColor(e.target.value)}
                                                value={color}
                                                placeholder="Color"
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack>
                                            <InputLabel htmlFor={'year'}>Year</InputLabel>
                                            <TextField
                                                name="year"
                                                placeholder="Year"
                                                onChange={e => setYear(e.target.value)}
                                                value={year}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            )}
                            {vehicleOption && vehicleOption === 'no_vehicle' && (
                                <Grid container columnSpacing={2} rowSpacing={1}>
                                    <Grid item xs={12} sm={12}>
                                        <Stack>
                                            <InputLabel htmlFor={'notes'}>
                                                No Vehicle Notes
                                            </InputLabel>
                                            <TextField
                                                name="vehicleNotes"
                                                id={'notes'}
                                                placeholder="Notes..."
                                                multiline
                                                rows={4}
                                                fullWidth
                                                onChange={e =>
                                                    setVehicleNotes(e.target.value)
                                                }
                                                value={vehicleNotes}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            )}
                        </Stack>
                        {/* {vehicle?.photo_id ? (
                        <>
                            <ControlledZoom
                                isZoomed={zoomedVehicle}
                                onZoomChange={handleZoom}
                            >
                                <img
                                    style={{
                                        height: '18.5vh',
                                        width: '100%',
                                        borderRadius: '2em',
                                        objectFit: 'fill'
                                    }}
                                    src={vehicle.photo_id}
                                    loading="lazy"
                                    alt={'vehicle'}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '1.5vh',
                                        right: '.75vw',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <IconButton
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '2.8vh',
                                            height: '2.8vh',
                                            backgroundColor: 'rgba(0, 0, 0, 0.36)',
                                            borderRadius: '8px',
                                            padding: 0,
                                            margin: 0
                                        }}
                                        onClick={() =>
                                            handleZoom('vehicle', !zoomedVehicle)
                                        }
                                    >
                                        {!zoomedId ? (
                                            <ZoomOutMapIcon
                                                style={{
                                                    width: '75%',
                                                    color: 'rgba(255,255,255,1)',
                                                    padding: 0,
                                                    margin: 0
                                                }}
                                            />
                                        ) : (
                                            <ZoomInMapIcon
                                                style={{
                                                    width: '75%',
                                                    color: 'rgba(255,255,255,1)',
                                                    padding: 0,
                                                    margin: 0
                                                }}
                                            />
                                        )}
                                    </IconButton>
                                </div>
                            </ControlledZoom>
                            <Stack style={{ marginTop: '1.5vh', marginBottom: '1vh' }}>
                                <Stack
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start'
                                    }}
                                >
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '50%'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                textTransform: 'capitalize',
                                                width: '25%'
                                            }}
                                            component="span"
                                            variant="body1"
                                        >
                                            {`Make: `}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                textTransform: 'capitalize'
                                            }}
                                            component="span"
                                            variant="body1"
                                        >
                                            {vehicle.make}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '50%'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                textTransform: 'capitalize',
                                                width: '25%'
                                            }}
                                            component="span"
                                            variant="body1"
                                        >
                                            {`Model: `}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                textTransform: 'capitalize'
                                            }}
                                            component="span"
                                            variant="body1"
                                        >
                                            {vehicle.model}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        marginTop: '.5vh'
                                    }}
                                >
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '50%'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                textTransform: 'capitalize',
                                                width: '25%'
                                            }}
                                            component="span"
                                            variant="body1"
                                        >
                                            {`Plate: `}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                textTransform: 'capitalize'
                                            }}
                                            component="span"
                                            variant="body1"
                                        >
                                            {`${vehicle.tag}/${vehicle.state}`}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '50%'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                textTransform: 'capitalize',
                                                width: '25%'
                                            }}
                                            component="span"
                                            variant="body1"
                                        >
                                            {`Color: `}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                width: '50%',
                                                textTransform: 'capitalize'
                                            }}
                                            component="span"
                                            variant="body1"
                                        >
                                            {vehicle.color}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </>
                    ) : (
                        <Stack
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%',
                                borderRadius: 5,
                                backgroundColor: 'rgba(208, 208, 208, 1)'
                            }}
                        >
                            <DirectionsCarIcon
                                style={{
                                    color: 'rgba(255,255,255,1)',
                                    fontSize: '5vw'
                                }}
                            />
                        </Stack>
                    )} */}
                    </Stack>
                    {accessConfirm && (
                        <Stack
                            sx={{
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                marginTop: 3,
                                marginBottom: 3,
                                backgroundColor: 'rgba(58, 230, 137, 0.14)',
                                height: '20vh',
                                width: '100%',
                                borderRadius: '9px',
                                border: '1px solid rgba(58, 230, 137, 1)'
                            }}
                        >
                            <Stack
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(58, 230, 137, 1)',
                                    height: '23%',
                                    width: '100%',
                                    borderTopLeftRadius: '9px',
                                    borderTopRightRadius: '9px',
                                    border: '1px solid rgba(58, 230, 137, 1)',
                                    margin: 0,
                                    padding: 0
                                }}
                            >
                                <Typography
                                    sx={{
                                        marginLeft: '4%',
                                        fontSize: '12px',
                                        fontWeight: '700',
                                        textTransform: 'capitalize',
                                        width: '80%'
                                    }}
                                    component="span"
                                    variant="body1"
                                >
                                    Select Gate
                                </Typography>
                                <IconButton
                                    onClick={() => setAccessConfirm(false)}
                                    aria-label="close"
                                >
                                    <CloseCircleOutlined
                                        style={{ color: 'rgba(255,255,255,1)' }}
                                    />
                                </IconButton>
                            </Stack>
                            <List
                                sx={{
                                    width: '100%',
                                    overflowY: 'auto',
                                    marginTop: '.5vh',
                                    maxHeight: '80%'
                                }}
                            >
                                {panelList.map(panel => (
                                    <ListItem
                                        key={panel.id}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            height: '3.5vh',
                                            width: '95%',
                                            backgroundColor:
                                                activePanel?.id === panel.id
                                                    ? 'rgba(129, 250, 185, 1)'
                                                    : 'transparent',
                                            borderRadius: '16px',
                                            border:
                                                activePanel?.id !== panel.id
                                                    ? '1px solid rgba(129, 250, 185, 1)'
                                                    : 'none',
                                            paddingX: '4%',
                                            marginLeft: '2.5%',
                                            marginBottom: '.5vh'
                                        }}
                                        button={true}
                                        disableGutters
                                        onClick={() => {
                                            handlePanelSelect(panel);
                                        }}
                                    >
                                        <ListItemText
                                            sx={{
                                                left: '4%'
                                            }}
                                            primary={
                                                <Typography
                                                    sx={{
                                                        display: 'block'
                                                    }}
                                                    component="span"
                                                    variant="body1"
                                                    color="text.primary"
                                                >
                                                    {panel.name}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Stack>
                    )}
                </Stack>
            </Stack>
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '6vh',
                    marginTop: 2
                }}
            >
                <Button
                    sx={{
                        width: '75%',
                        height: '4.5vh',
                        marginBottom: 3,
                        borderRadius: '26.5px',
                        '&:active::after': {
                            borderRadius: '26.5px'
                        },
                        fontSize: '0.9375rem',
                        fontWeight: '600',
                        backgroundColor:
                            access === false || (!memberKeys && !memberKeys?.length)
                                ? 'rgba(230, 58, 58, 1)'
                                : 'rgba(58, 230, 137, 1)'
                    }}
                    disabled={
                        member.super_suspend ||
                        (accessConfirm && !activePanel) ||
                        (accessConfirm && !getVehicle())
                    }
                    onClick={handleConfirmAccess}
                    variant="contained"
                >
                    {(access === false || (!memberKeys && !memberKeys?.length)) &&
                    !member.super_suspend
                        ? `${accessConfirm ? 'Confirm' : 'Grant'} Override Access`
                        : `${accessConfirm ? 'Confirm' : 'Grant'} Access`}
                </Button>
            </Stack>
        </Stack>
    );
};
export default MemberDetails;
