import {
    all,
    fork,
    take,
    cancelled,
    takeLatest,
    race,
    put,
    call,
    select
} from 'redux-saga/effects';

import * as selectors from './Selectors';

import {
    GET_USER_ORG,
    LOGOUT_USER,
    GET_ORGS_COLLECTION,
    SET_ACTIVE_USER_ORG,
    GET_ACTIVE_ORG_COLLECTION,
    CREATE_ORG,
    GET_DEFAULT_ROLES_COLLECTION,
    UPDATE_ORG,
    UPDATE_ORG_IMAGE,
    RESEND_MANAGER_INVITE,
    REMOVE_USER_FROM_ORG,
    VALIDATE_SHORT_CODE,
    ADD_TAG_MASTER_CAMERA,
    UPDATE_TAG_MASTER_CAMERA,
    DELETE_TAG_MASTER_CAMERA
} from '../actions/types';

import { eventChannel } from 'redux-saga';

import {
    storeOrgData,
    getOrgCollectionSuccess,
    getOrgCollectionFailure,
    settingActiveOrgSuccess,
    settingActiveOrgFailure,
    createOrgFailure,
    createOrgSuccess,
    getDefaultRolesSuccess,
    getDefaultRolesFailure,
    updateOrgSuccess,
    updateOrgFailure,
    updateOrgImageSuccess,
    updateOrgImageFailure,
    resendManagerInviteSuccess,
    setShortCodeValidationResult,
    addTagMasterCameraSuccess,
    addTagMasterCameraFailure,
    updateTagMasterCameraSuccess,
    updateTagMasterCameraFailure,
    deleteTagMasterCameraSuccess,
    deleteTagMasterCameraFailure
} from '../actions/Org';

import { openSnackbar } from 'store/actions/Snackbar';

import { settingActivePermissions } from 'store/actions/User';

import { getAllOrgProperties } from './Properties';

import { db, func, rtdb, storage } from 'config/firebase';

import { otcCollectionWatch } from './Otc';

// Loggers
import { log } from '../../utils/Loggers';
import {
    arrayUnion,
    collection,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    setDoc,
    updateDoc,
    where
} from 'firebase/firestore';
import {
    onValue,
    orderByChild,
    ref,
    query as rtdbQuery,
    ref as rtdbRef,
    set
} from 'firebase/database';
import { getDownloadURL, ref as sRef, uploadString } from 'firebase/storage';
import {
    addOrgToVirtualGuards,
    createNewAuthUsers,
    getExistingOrNewUser
} from './Managers';
import { httpsCallable } from 'firebase/functions';
import {
    getCommunityMembersSuccess,
    getImportedMembersSuccess,
    getInvitedMembersSuccess
} from 'store/actions/Managers';
import { updateAccessLog } from 'store/actions/AccessLog';
import { createPanelUserRequest } from './Panels';

const orgImagesRef = sRef(storage, 'org_images');

const orgsCollectionRef = collection(db, 'orgs');
const defaultRolesCollectionRef = collection(db, 'default_roles');

const sendgridEmailRequest = httpsCallable(func, 'sendgridEmailRequest');
const updateManagerRequest = httpsCallable(func, 'updateManagerRequest');

const syncTagMasterCameraOnAdd = httpsCallable(func, 'syncTagMasterCameraOnAdd');
const syncTagMasterCameraOnDelete = httpsCallable(func, 'syncTagMasterCameraOnDelete');

const notifyHardwareCrash = httpsCallable(func, 'notifyHardwareCrash');

const createCaOrgOperator = httpsCallable(func, 'createCaOrgOperator');

//////////////// Get User Organization ////////////////

const isSuper = role => {
    if (role === 'super_admin') return true;
    return false;
};

export function* orgCollectionWatch(user) {
    const activeOrg = yield select(selectors._activeOrg);
    let unsubscribeUserOrgData;
    const orgCollectionChannel = eventChannel(emit => {
        unsubscribeUserOrgData = isSuper(user.type)
            ? onSnapshot(orgsCollectionRef, querySnapshot => {
                  var org = [];
                  querySnapshot.forEach(doc => {
                      var contactName;
                      if (doc.data().users.length) {
                          const primary = doc
                              .data()
                              .users.filter(manager => manager.primary === true);
                          contactName = primary.length
                              ? `${primary[0].first_name} ${primary[0].last_name}`
                              : null;
                      }
                      org.push({ ...doc.data(), contact_name: contactName });
                  });
                  emit(org);
              })
            : onSnapshot(
                  query(orgsCollectionRef, where('org_id', 'in', Object.keys(user.orgs))),
                  querySnapshot => {
                      var org = [];
                      querySnapshot.forEach(doc => {
                          var contactName;
                          if (doc.data().users.length) {
                              const primary = doc
                                  .data()
                                  .users.filter(manager => manager.primary === true);
                              contactName = primary.length
                                  ? `${primary[0].first_name} ${primary[0].last_name}`
                                  : null;
                          }
                          org.push({ ...doc.data(), contact_name: contactName });
                      });
                      emit(org);
                  }
              );
        return unsubscribeUserOrgData;
    });

    try {
        while (true) {
            const { userSignOut, userOrgData } = yield race({
                userSignOut: take(LOGOUT_USER),
                userOrgData: take(orgCollectionChannel)
            });

            if (userSignOut) {
                orgCollectionChannel.close();
            } else {
                yield put(storeOrgData(userOrgData));
                if (!activeOrg && !isSuper(user.type)) {
                    const primaryOrg = userOrgData.find(
                        org => org.org_id === user.active_org_id
                    );
                    yield fork(settingActiveOrg, { payload: { ...primaryOrg } });
                    yield fork(orgInvitesCollectionWatch, { payload: { ...primaryOrg } });
                    yield fork(getAllOrgProperties, { payload: { ...primaryOrg } });
                }
            }
        }
    } catch (error) {
        log('Org Error: getting user org collection data (FS)', {
            error,
            user
        });
    } finally {
        unsubscribeUserOrgData();
        if (yield cancelled()) {
            orgCollectionChannel.close();
            unsubscribeUserOrgData();
        }
    }
}

export function* activeOrgCollectionWatch(id) {
    const activeOrgImports = rtdbRef(rtdb, `registration/${id}`);
    const activeOrgMembers = rtdbRef(rtdb, `orgs/${id}/members`);
    const activeOrgAccessLog = rtdbRef(rtdb, `orgs/${id}/access_log`);
    // const activeOrgPanels = rtdbRef(rtdb, `orgs/${id}/panels`);

    const OrgImportsChannel = eventChannel(emit => {
        const unsubscribeOrgImportsData = onValue(activeOrgImports, snapshot => {
            const imports = [];
            if (snapshot.exists()) {
                snapshot.forEach(childSnapshot => {
                    const data = {
                        exist: childSnapshot.val().exist
                            ? childSnapshot.val().exist
                            : false,
                        id: childSnapshot.val().id || childSnapshot.key,
                        first_name: childSnapshot.val().first_name.toLowerCase(),
                        last_name: childSnapshot.val().last_name.toLowerCase(),
                        email: childSnapshot.val().email
                            ? childSnapshot.val().email.toLowerCase()
                            : false,
                        phone: childSnapshot.val().phone
                            ? childSnapshot.val().phone
                            : false,
                        devices: childSnapshot.val().devices || null,
                        registered_at: childSnapshot.val().registered_at || false,
                        address: childSnapshot.val().address,
                        property_id: childSnapshot.val().property_id,
                        type: childSnapshot.val().type,
                        permissions: childSnapshot.val().permissions
                    };
                    imports.push(data);
                });
                emit(imports);
            }
        });
        return () => {
            unsubscribeOrgImportsData();
        };
    });

    const OrgMembersChannel = eventChannel(emit => {
        const unsubscribeOrgMembersData = onValue(activeOrgMembers, snapshot => {
            const members = [];
            snapshot.forEach(childSnapshot => {
                if (childSnapshot.exists()) {
                    const data = {
                        ...childSnapshot.val(),
                        first_name: childSnapshot.val().first_name.toLowerCase(),
                        last_name: childSnapshot.val().last_name.toLowerCase(),
                        email: childSnapshot.val().email
                            ? childSnapshot.val().email.toLowerCase()
                            : false
                    };
                    members.push(data);
                }
            });
            emit(members);
        });
        return () => {
            unsubscribeOrgMembersData();
        };
    });

    const OrgAccessLogChannel = eventChannel(emit => {
        const query = rtdbQuery(activeOrgAccessLog, orderByChild('access_time'));

        const unsubscribeOrgAccessLogData = onValue(query, snapshot => {
            if (snapshot && snapshot.exists()) {
                const log = snapshot.val();
                const accessLogList = Object.entries(log).map(([logItemId, record]) => ({
                    logItemId,
                    ...record
                }));

                accessLogList.sort((a, b) => b.access_time.localeCompare(a.access_time));
                emit(accessLogList);
            } else {
                emit([]);
            }
        });
        return () => {
            unsubscribeOrgAccessLogData();
        };
    });

    const detachSagaEmitters = () => {
        OrgImportsChannel.close();
        OrgMembersChannel.close();
        // OrgPanelsChannel.close();
        OrgAccessLogChannel.close();
    };

    try {
        while (true) {
            const {
                userSignOut,
                resettingOrg,
                orgImportsData,
                orgMembersData,
                // orgPanelsData,
                orgAccessLogData
            } = yield race({
                userSignOut: take(LOGOUT_USER),
                resettingOrg: take(SET_ACTIVE_USER_ORG),
                orgImportsData: take(OrgImportsChannel),
                orgMembersData: take(OrgMembersChannel),
                // orgPanelsData: take(OrgPanelsChannel),
                orgAccessLogData: take(OrgAccessLogChannel)
            });

            const currentImports = yield select(selectors._importedMembers);
            const currentMembers = yield select(selectors._communityMembers);
            const currentAccessLog = yield select(selectors._accessLog);
            const initialized = yield select(selectors._appInitialized);

            if (userSignOut) {
                detachSagaEmitters();
            } else if (resettingOrg) {
                if (initialized) {
                    if (
                        (currentImports && currentImports?.length) ||
                        (currentMembers && currentMembers?.length) ||
                        (currentAccessLog && currentAccessLog?.length)
                    ) {
                        detachSagaEmitters();
                    }
                }
            } else if (orgImportsData) {
                yield put(getImportedMembersSuccess(orgImportsData));
            } else if (orgMembersData) {
                yield put(getCommunityMembersSuccess(orgMembersData));
            } else if (orgAccessLogData) {
                yield put(updateAccessLog(orgAccessLogData));
            }
        }
    } catch (error) {
        //TODO: Error Handling
        log('Orgs Error: getting imports/members/access_log data (RTDB)', {
            error
        });
    } finally {
        // detachFBListeners(); // Detaching firebase listeners
        if (yield cancelled()) {
            detachSagaEmitters(); // Detaching saga event emitter
            // detachFBListeners(); // Detaching firebase listeners
        }
    }
}

export function* createOrgDocument({ payload }) {
    try {
        const newOrgId = doc(collection(db, 'orgs')).id;
        const newOrgRef = doc(db, 'orgs', `${newOrgId}`);

        const defaultModules = ['org_dashboard', 'settings'];

        const {
            orgName,
            address1,
            address2,
            city,
            state,
            zip,
            orgOfficePhone,
            modules,
            manager,
            stations,
            roles,
            short_code
        } = payload;

        const shortCodeQuery = query(
            collection(db, 'orgs'),
            where('short_code', '==', short_code)
        );
        const shortCodeSnapshot = yield call(getDocs, shortCodeQuery);

        if (!short_code || shortCodeSnapshot.size > 0) {
            throw new Error(
                `The short code "${short_code}" is already in use or invalid. Please choose a unique code.`
            );
        }

        const stationIds = Object.keys(stations);
        if (stationIds.length > 0) {
            yield all(
                stationIds.map((stationId, index) =>
                    call(createPanelUserRequest, stations[stationId], newOrgId, index)
                )
            );
        }

        const filteredStations = stationIds.reduce((acc, stationId) => {
            const { name, id } = stations[stationId];
            acc[stationId] = { operator: null, name, id };
            return acc;
        }, {});

        const my_vehicles = modules.includes('my_vehicles');
        const groupName = orgName.replace(/\s+/g, '').trim().slice(0, 50);

        const newOrgDocument = {
            org_id: newOrgId,
            org_name: orgName,
            device_config: {
                group_name: groupName
            },
            rentalManagerEnabled: false,
            status: 'Active',
            modules: [...defaultModules, ...modules],
            my_vehicles,
            users: [],
            stations: filteredStations,
            roles: roles || {},
            phone: {
                primary_ext: null,
                secondary_ext: null,
                secondary: null,
                fax: null,
                primary: orgOfficePhone?.number || null,
                code: orgOfficePhone?.code || null,
                country: orgOfficePhone?.country || null
            },
            address: {
                state: state || null,
                zip: zip || null,
                address_1: address1 || null,
                address_2: address2 || null,
                city: city || null,
                country: null
            },
            short_code
        };

        const orgManagerPermissions = roles['Org Manager'];

        const addressParts = [address1, address2, city, state]
            .filter(part => part)
            .join(' ');

        const addressString = addressParts.trim();

        const newOrg = {
            org_id: newOrgId,
            address: {
                state: state || null,
                zip: zip || null,
                address_1: address1 || null,
                address_2: address2 || null,
                city: city || null,
                country: null,
                latitude: null,
                longitude: null,
                primary: false,
                full_address: addressString
            },
            role: 'Org Manager',
            permissions: orgManagerPermissions
        };

        const device_module = modules.includes('devices');

        if (device_module) {
            const caOrgOperatorResult = yield call(createCaOrgOperator, {
                group_name: groupName,
                org_id: newOrgId,
                operation: 0
            });

            if (!caOrgOperatorResult?.data?.success) {
                yield put(
                    openSnackbar({
                        open: true,
                        message: `Failed: To Create CA Operator`,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }

        manager.primary = true;
        const addManager = yield handleAddingManager(manager, newOrgId, payload, newOrg);
        newOrgDocument.users.push(addManager);

        const streetNumber = address1?.split(/[^\d]+/)[0];

        const userData = {
            access_group: 1,
            access_key: true,
            address: address1 ?? '',
            directory: false,
            email: manager.email.toLowerCase(),
            first_name: manager.first_name?.toLowerCase() ?? '',
            last_name: manager.last_name?.toLowerCase() ?? '',
            phone: manager?.phone?.number
                ? manager.phone.code.toString() + manager.phone.number.toString()
                : null,
            role: 'operator',
            street_num: streetNumber ?? '',
            suspended: false,
            property_id: newOrgId,
            uid: addManager.id
        };
        yield addManagerOrgMember(userData, newOrgId);

        if (
            modules.includes('virtual_guard') &&
            modules.includes('ramco_virtual_guard')
        ) {
            const orgVirtualGuardPermissions = roles['Virtual Guard'];
            const vgUserOrg = newOrg;
            vgUserOrg.role = 'Virtual Guard';
            vgUserOrg.permissions = orgVirtualGuardPermissions;
            yield call(addOrgToVirtualGuards, newOrgId, vgUserOrg);
        }

        yield setDoc(newOrgRef, newOrgDocument)
            .then(() => {
                console.log('Added document with ID: ', newOrgId);
            })
            .catch(err => console.error(err));
        yield put(createOrgSuccess(orgName));
    } catch (err) {
        yield put(createOrgFailure(err));
    }
}

function* addManagerOrgMember(userData, orgId) {
    try {
        const rtdbMembersRef = ref(rtdb, `/orgs/${orgId}/members/${userData.uid}`);
        yield set(rtdbMembersRef, userData);
    } catch (error) {
        console.error('Error creating member in RTDB:', error);
        throw error;
    }
}

function* handleAddingManager(manager, orgId, org, newOrg) {
    const { newUser, existingUser } = yield getExistingOrNewUser(manager);
    const activeStationId = org?.stations
        ? Object.values(org.stations)?.[0]?.id ?? ''
        : '';

    if (manager.virtual_guard_permission) {
        newOrg.permissions.push('virtual_guard');
    } else {
        newOrg.permissions = newOrg.permissions.filter(
            permission => permission !== 'virtual_guard'
        );
    }

    if (existingUser) {
        const user = existingUser;
        const userDocRef = doc(db, 'users', `${user.uid}`);

        yield updateDoc(userDocRef, {
            org_ids: [...user.org_ids, orgId],
            orgs: {
                ...user?.orgs,
                [orgId]: newOrg
            }
        });
        return {
            id: user.uid,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            role: manager.role || 'Org Manager',
            active: true,
            phone: user?.phone || null,
            primary: manager.primary,
            type: manager?.type || user.type,
            virtual_guard_permission: manager.virtual_guard_permission || false
        };
    }
    if (newUser) {
        const cleanedNumber = newUser?.phone?.number.replace(/[^\d]/g, '');
        let phone = null;
        if (cleanedNumber !== '') {
            const parsedNumber = Number.parseInt(cleanedNumber, 10);
            if (!isNaN(parsedNumber)) {
                phone = { ...newUser.phone, number: parsedNumber };
            }
        }
        const processed = yield createNewAuthUsers([newUser]);
        const matchingAuthUser = processed[0];
        const emailData = {
            email: newUser.email,
            first_name: newUser.first_name,
            last_name: newUser.last_name,
            org_name: org.org_name,
            authLink: `https://sagesystems.io/accept-invitation?inviteCode=${matchingAuthUser.code}&email=${newUser.email}&type=manager`
        };
        const { success } = yield sendConfirmEmail([emailData]);
        if (success) {
            const userOrgs = {
                [orgId]: newOrg
            };

            const newUserDocument = {
                active_org_id: orgId,
                active_station_id: activeStationId,
                devices: {},
                email: matchingAuthUser.email,
                first_name: newUser.first_name || null,
                last_name: newUser.last_name || null,
                notifications: {
                    comunity_news: false,
                    guest_access: false,
                    vendor_access: false
                },
                org_ids: [orgId],
                orgs: userOrgs,
                phone: phone,
                phone_number: phone ? `${phone.code}${phone.number}` : null,
                primary_org: orgId,
                properties: null,
                property_ids: [],
                role: 'operator',
                type: newUser?.type || 'manager',
                uid: matchingAuthUser.id,
                avatar: null
            };
            const userDocRef = doc(db, 'users', `${matchingAuthUser.id}`);
            yield setDoc(userDocRef, newUserDocument);
            return {
                first_name: newUser.first_name,
                last_name: newUser.last_name,
                email: matchingAuthUser.email,
                role: newUser.role || 'Org Manager',
                active: false,
                phone: newUser.phone,
                primary: newUser.primary,
                id: matchingAuthUser.id,
                virtual_guard_permission: manager.virtual_guard_permission || false
            };
        }
    }
}

export function* updateOrgDocument({ payload }) {
    try {
        const {
            orgId,
            stations,
            modules,
            manager,
            roles,
            locations,
            device_facility_codes
        } = payload;

        const orgRef = doc(db, 'orgs', orgId);
        const orgSnapshot = yield getDoc(orgRef);
        const orgData = orgSnapshot.data();

        if (device_facility_codes) {
            yield updateOrgData(orgId, { device_facility_codes: device_facility_codes });
        }

        if (locations) {
            yield updateOrgData(orgId, { locations: locations });
        }

        if (stations) {
            yield updateOrgData(orgId, { stations: stations });
        }

        if (manager) {
            const users = orgData.users;
            const userOrgPermissions = orgData.roles[manager.role];

            const addressParts = [
                orgData?.address?.address_1,
                orgData?.address?.address_2,
                orgData?.address?.city,
                orgData?.address?.state
            ]
                .filter(part => part)
                .join(' ');

            const addressString = addressParts.trim();

            const newOrg = {
                org_id: orgId,
                role: manager.role,
                address: {
                    state: orgData?.address?.state || null,
                    zip: orgData?.address?.zip || null,
                    address_1: orgData?.address?.address_1 || null,
                    address_2: orgData?.address?.address_2 || null,
                    city: orgData?.address?.city || null,
                    country: null,
                    latitude: null,
                    longitude: null,
                    primary: false,
                    full_address: addressString
                },
                permissions: userOrgPermissions
            };

            if (users.length === 0) {
                manager.primary = true;
            } else {
                manager.primary = false;
            }

            const addManager = yield handleAddingManager(manager, orgId, orgData, newOrg);

            const existingUserIndex = orgData.users.findIndex(
                user => user.id === addManager.id
            );

            if (existingUserIndex !== -1) {
                users[existingUserIndex] = addManager;
            } else {
                users.push(addManager);
            }

            yield updateOrgData(orgId, { users: users });
        }

        if (roles || modules) {
            const differentRoles = Object.keys(roles).filter(role => {
                const orgPermissions = orgData.roles[role] || [];
                return !permissionsAreEqual(orgPermissions, roles[role]);
            });

            const usersWithDifferentRoles = orgData.users.filter(user =>
                differentRoles.includes(user.role)
            );

            yield updateUsersWithNewPermissions(usersWithDifferentRoles, orgId, roles);

            if (
                modules.includes('virtual_guard') &&
                modules.includes('ramco_virtual_guard') &&
                !orgData.modules.includes('ramco_virtual_guard')
            ) {
                const addressParts = [
                    orgData?.address?.address_1,
                    orgData?.address?.address_2,
                    orgData?.address?.city,
                    orgData?.address?.state
                ]
                    .filter(part => part)
                    .join(' ');

                const addressString = addressParts.trim();
                const orgVirtualGuardPermissions = roles['Virtual Guard'];

                const userOrg = {
                    org_id: orgId,
                    address: {
                        state: orgData?.address?.state || null,
                        zip: orgData?.address?.zip || null,
                        address_1: orgData?.address?.address_1 || null,
                        address_2: orgData?.address?.address_2 || null,
                        city: orgData?.address?.city || null,
                        country: null,
                        latitude: null,
                        longitude: null,
                        primary: false,
                        full_address: addressString
                    },
                    role: 'Virtual Guard',
                    permissions: orgVirtualGuardPermissions
                };
                ////////////////////// Right Here!!!
                yield call(addOrgToVirtualGuards, orgId, userOrg);
            }

            const device_module = modules.includes('devices');

            if (device_module && !orgData?.device_config.group_name) {
                const groupName = orgData?.org_name
                    .replace(/\s+/g, '')
                    .trim()
                    .slice(0, 50);

                const caOrgOperatorResult = yield call(createCaOrgOperator, {
                    group_name: groupName,
                    org_id: orgId,
                    operation: 0
                });

                if (!caOrgOperatorResult?.data?.success) {
                    yield put(
                        openSnackbar({
                            open: true,
                            message: `Failed: To Create CA Operator`,
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            }
                        })
                    );
                }
            }

            const my_vehicles = modules.includes('my_vehicles');

            const updatedRolesModules = {
                roles: roles || orgData.roles,
                modules: modules || orgData.modules,
                my_vehicles
            };

            yield updateOrgData(orgId, updatedRolesModules);
        }

        yield put(updateOrgSuccess(orgData.org_name));
    } catch (err) {
        yield put(updateOrgFailure(err));
    }
}

function permissionsAreEqual(oldPermissions, newPermissions) {
    const sortedOldPermissions = oldPermissions.sort();
    const sortedNewPermissions = newPermissions.sort();

    return (
        sortedOldPermissions.length === sortedNewPermissions.length &&
        sortedOldPermissions.every(
            (value, index) => value === sortedNewPermissions[index]
        )
    );
}

export function* updateUsersWithNewPermissions(users, orgId, roles) {
    for (const user of users) {
        const userRef = doc(db, 'users', user.id);
        const userSnapshot = yield getDoc(userRef);
        if (!userSnapshot.exists()) {
            console.warn(`User with ID ${user.id} does not exist.`);
            continue;
        }

        const userData = userSnapshot.data();

        const updatedUser = {
            ...userData,
            orgs: {
                ...userData.orgs,
                [orgId]: {
                    ...userData.orgs[orgId],
                    permissions: roles[user.role]
                }
            }
        };

        yield setDoc(userRef, updatedUser);
    }
}

export function* activateOrgUser(orgId, email) {
    const orgRef = doc(db, 'orgs', orgId);
    const orgSnapshot = yield getDoc(orgRef);

    const orgData = orgSnapshot.data();
    const updatedUsers = orgData.users.map(user => {
        if (user.email === email) {
            return {
                ...user,
                active: true
            };
        }
        return user;
    });

    yield updateOrgData(orgId, { users: updatedUsers });
}

export function* updateOrgData(orgId, updateData) {
    try {
        const orgRef = doc(db, 'orgs', orgId);

        yield updateDoc(orgRef, updateData);
        console.log(`Updated organization with ID: ${orgId}`);
        return true;
    } catch (error) {
        log('Org Error: updating organization data (FS)', {
            error,
            orgId,
            updateData
        });
        return false;
    }
}

export function* updateOrgWithImage({ payload }) {
    try {
        const { imgFile, orgId } = payload;
        const avatarRef = sRef(orgImagesRef, `${orgId}/image`);

        try {
            yield uploadString(avatarRef, imgFile, 'data_url');
        } catch (error) {
            throw new Error(`Uploading image failed`);
        }
        const imgUrl = yield getDownloadURL(avatarRef);

        yield updateOrgData(orgId, { image: imgUrl });

        if (imgUrl) {
            yield put(updateOrgImageSuccess(imgUrl));
            return true;
        }

        yield put(
            updateOrgImageFailure('Error! Avatar was not updated. Try again later.')
        );
        return false;
    } catch (error) {
        yield put(updateOrgImageFailure(`${error}`));
        return false;
    }
}

export function* getOrgsCollection() {
    try {
        const snapshot = yield call(getDocs, orgsCollectionRef);
        const allOrgs = snapshot.docs.map(doc => doc.data());
        yield put(getOrgCollectionSuccess(allOrgs));
    } catch (error) {
        log('Org Error: getting all orgs data (FS)', {
            error
        });
        yield put(getOrgCollectionFailure(error));
    }
}

// export function* getOrgByShortCode(shortCode) {
//     try {
//         const orgsCollectionRef = collection(db, 'orgs');
//         const q = query(orgsCollectionRef, where('short_code', '==', shortCode));
//         const querySnapshot = yield call(getDocs, q);

//         if (!querySnapshot.empty) {
//             const doc = querySnapshot.docs[0];
//             return doc.data();
//         } else {
//             return null;
//         }
//     } catch (err) {
//         log('Org Error: getting org by short code (FS)', {
//             error: err,
//             shortCode
//         });
//         return null;
//     }
// }

export function* getOrgById(orgId) {
    try {
        const orgDocRef = doc(db, 'orgs', orgId);
        const orgDoc = yield call(getDoc, orgDocRef);

        if (orgDoc.exists()) {
            return orgDoc.data();
        }
    } catch (err) {
        log('Org Error: getting org by id (FS)', {
            error: err,
            orgId
        });
    }
}

export async function updateOrgManagers(orgId, userId, userData) {
    try {
        const orgRef = doc(db, 'orgs', orgId);

        const orgSnapshot = await getDoc(orgRef);
        const orgData = orgSnapshot.data();

        const managersEmpty = !orgData?.managers.length;

        const updatedManagers = [
            ...(orgData?.users || []),
            {
                id: userId,
                first_name: userData.first_name,
                last_name: userData.last_name,
                email: userData.email,
                primary: managersEmpty
            }
        ];

        await setDoc(orgRef, { managers: updatedManagers }, { merge: true });
    } catch (err) {
        log('Org Error: updating org managers (FS)', {
            error: err,
            orgId,
            userId,
            userData
        });
    }
}

export function* resendManagerRequest({ payload }) {
    try {
        const { email, firstName, lastName, orgName, userId } = payload;

        const managerRequest = yield updateManagerRequest({ userId: userId });
        const code = managerRequest?.data?.code;
        const emailData = {
            email: email,
            first_name: firstName,
            last_name: lastName,
            org_name: orgName,
            authLink: `https://sagesystems.io/accept-invitation?inviteCode=${code}&email=${email}&type=manager`
        };

        const { success } = yield sendConfirmEmail([emailData]);

        if (success) {
            yield put(resendManagerInviteSuccess(email));
        } else {
            yield put(
                resendManagerInvite(
                    'There was error while trying to send invite - Try again later!'
                )
            );
        }
    } catch (err) {
        log('Org Error: resend manager invite (FS)', {
            error: err,
            ...payload
        });
        yield put(resendManagerInvite(err));
    }
}

export async function sendConfirmEmail(recipients) {
    try {
        const res = await sendgridEmailRequest({ recipients });
        return res?.data;
    } catch (error) {
        log('Org Error: send confirm email (CF)', {
            error,
            recipients
        });
    }
}

export function* getDefaultRolesCollection() {
    try {
        const snapshot = yield call(getDocs, defaultRolesCollectionRef);
        const defaultRoles = snapshot.docs.map(doc => doc.data());

        yield put(getDefaultRolesSuccess(defaultRoles[0].roles));
    } catch (error) {
        log('Org Error: get default roles collection (FS)', {
            error
        });
        yield put(getDefaultRolesFailure(error));
    }
}

export function* deleteOrgUser({ payload }) {
    try {
        const user = payload;

        const orgRef = doc(db, 'orgs', user.org_id);
        const orgSnapshot = yield getDoc(orgRef);

        const orgData = orgSnapshot.data();

        yield updateOrgData(user.org_id, {
            users: orgData.users.filter(orgUser => orgUser.id !== user.id)
        });

        const userDocRef = doc(db, 'users', `${user.id}`);
        const userSnapshot = yield getDoc(userDocRef);

        const userData = userSnapshot.data();
        const orgs = userData.orgs;
        delete orgs[user.org_id];
        yield setDoc(
            userDocRef,
            {
                orgs: orgs
            },
            { merge: true }
        );

        yield put(
            openSnackbar({
                open: true,
                message: 'Success: Manager has been removed!',
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
    } catch (err) {
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: ${err}`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////// Setting Active Org /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const settingActiveOrgRequest = ({ active_org_id, active_station_id, userId }) => {
    const userDocRef = doc(db, 'users', userId);

    return new Promise((resolve, reject) => {
        updateDoc(userDocRef, {
            active_org_id,
            active_station_id: active_station_id || null
        })
            .then(() => resolve({ res: true }))
            .catch(error => reject({ error }));
    });
};

export function* settingActiveOrg({ payload }) {
    const { org_id } = payload;
    const userData = yield select(selectors._userData);
    const active_org_id = org_id;
    const active_station_id = userData.active_station_id;
    const userId = userData.uid;

    const activePermissions = userData?.orgs[active_org_id]?.permissions || [];

    const { res, error } = yield call(() =>
        settingActiveOrgRequest({ active_org_id, active_station_id, userId })
    );

    if (res) {
        yield put(
            settingActiveOrgSuccess({
                activeOrg: payload
            })
        );
        yield put(
            settingActivePermissions({
                activePermissions: activePermissions
            })
        );
        yield fork(otcCollectionWatch, payload); // org otc data
    } else {
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: Org was not focused successfully.`,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                autoHideDuration: 3000
            })
        );
        log('Org Error: setting active user org ID (FS)', {
            error,
            org_id
        });
        yield put(settingActiveOrgFailure(error));
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////// Org Invites Collection ///////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* orgInvitesCollectionWatch({ payload }) {
    const org = payload;
    const invitors = org?.users?.length ? org.users.map(manager => manager.id) : [];
    let unsubscribeOrgInvitesData;

    // Create a Firebase query to retrieve org invites
    const orgInvitesQuery = query(
        collection(db, 'guest_invites'),
        where('org_id', '==', org.org_id),
        where('role', '==', 'resi')
    );

    const orgInvitesCollectionChannel = eventChannel(emit => {
        unsubscribeOrgInvitesData = onSnapshot(orgInvitesQuery, querySnapshot => {
            const invites = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
                first_name: doc.data().first_name.toLowerCase(),
                last_name: doc.data().last_name.toLowerCase()
            }));
            emit(invites);
        });

        return () => unsubscribeOrgInvitesData(); // Cleanup function to detach Firebase listener
    });

    try {
        while (true) {
            const { userSignOut, orgInvitesData } = yield race({
                userSignOut: take(LOGOUT_USER),
                orgInvitesData: take(orgInvitesCollectionChannel)
            });
            if (userSignOut) {
                orgInvitesCollectionChannel.close(); // Detach saga event emitter
            } else {
                yield put(getInvitedMembersSuccess(orgInvitesData));
            }
        }
    } catch (error) {
        log('Org Error: getting org invites collection data (FS)', {
            error,
            org,
            invitors
        });
    } finally {
        if (yield cancelled()) {
            orgInvitesCollectionChannel.close(); // Detach saga event emitter
        }
    }
}

function* validateShortCodeSaga(action) {
    const shortCode = action.payload;

    try {
        const shortCodeQuery = query(
            collection(db, 'orgs'),
            where('short_code', '==', shortCode)
        );
        const snapshot = yield call(getDocs, shortCodeQuery);

        if (snapshot.empty) {
            yield put(setShortCodeValidationResult(true, null));
        } else {
            yield put(setShortCodeValidationResult(false, 'Short code already in use.'));
        }
    } catch (error) {
        yield put(setShortCodeValidationResult(false, 'Validation failed.'));
    }
}

////////////////////////////////// Tag Master Cameras ////////////////////////////////////////

function* handleAddTagMasterCamera({ payload }) {
    const { orgId, cameraData } = payload;
    const orgDocRef = doc(db, 'orgs', orgId);

    try {
        const camera = {
            ...cameraData,
            id: doc(collection(db, `orgs/${orgId}/hardware`)).id
        };

        try {
            yield syncTagMasterCameraOnAdd({ orgId, camera });
        } catch (error) {
            const orgDocSnapshot = yield call(getDoc, orgDocRef);
            yield notifyHardwareCrash({
                org: orgDocSnapshot.data(),
                device: camera,
                errorDetails: error,
                severity: 'critical'
            });
        }

        yield call(updateDoc, orgDocRef, {
            hardware: arrayUnion(camera)
        });

        yield put(addTagMasterCameraSuccess(camera));
        yield put(
            openSnackbar({
                open: true,
                message: 'Tag Master Camera added successfully',
                variant: 'alert',
                alert: { color: 'success' }
            })
        );
    } catch (error) {
        yield put(addTagMasterCameraFailure(error.message));
        yield put(
            openSnackbar({
                open: true,
                message: 'Failed to add Tag Master Camera',
                variant: 'alert',
                alert: { color: 'error' }
            })
        );
    }
}

function* handleUpdateTagMasterCamera({ payload }) {
    const { orgId, cameraData } = payload;
    const orgDocRef = doc(db, 'orgs', orgId);

    try {
        const orgSnapshot = yield call(getDoc, orgDocRef);
        if (!orgSnapshot.exists()) {
            throw new Error('Organization document not found');
        }

        const orgData = orgSnapshot.data();

        const oldCamera = orgData.hardware.find(device => device.id === cameraData.id);

        if (
            oldCamera.ip_address !== cameraData.ip_address ||
            oldCamera.loc_id !== cameraData.loc_id
        ) {
            try {
                yield syncTagMasterCameraOnDelete({ orgId, camera: oldCamera });
                yield syncTagMasterCameraOnAdd({ orgId, camera: cameraData });
            } catch (error) {
                yield notifyHardwareCrash({
                    org: orgData,
                    device: oldCamera,
                    errorDetails: error,
                    severity: 'critical'
                });
            }
        }

        const updatedHardware = orgData.hardware.map(camera => {
            if (camera.id === cameraData.id) {
                return { ...camera, ...cameraData };
            }
            return camera;
        });

        yield call(updateDoc, orgDocRef, { hardware: updatedHardware });

        yield put(updateTagMasterCameraSuccess(cameraData));
        yield put(
            openSnackbar({
                open: true,
                message: 'Tag Master Camera updated successfully',
                variant: 'alert',
                alert: { color: 'success' }
            })
        );
    } catch (error) {
        yield put(updateTagMasterCameraFailure(error.message));
        yield put(
            openSnackbar({
                open: true,
                message: 'Failed to update Tag Master Camera',
                variant: 'alert',
                alert: { color: 'error' }
            })
        );
    }
}

function* handleDeleteTagMasterCamera({ payload }) {
    const { orgId, cameraId } = payload;
    const orgDocRef = doc(db, 'orgs', orgId);

    try {
        const orgDocSnapshot = yield call(getDoc, orgDocRef);

        if (orgDocSnapshot.exists()) {
            const orgData = orgDocSnapshot.data();

            const camera = orgData.hardware.find(device => device.id === cameraId);

            const updatedHardware = orgData.hardware.filter(
                camera => camera.id !== cameraId
            );

            yield syncTagMasterCameraOnDelete({ orgId, camera });

            try {
                yield syncTagMasterCameraOnDelete({ orgId, camera });
            } catch (error) {
                yield notifyHardwareCrash({
                    org: orgData,
                    device: camera,
                    errorDetails: error,
                    severity: 'critical'
                });
            }

            yield call(updateDoc, orgDocRef, {
                hardware: updatedHardware
            });

            yield put(deleteTagMasterCameraSuccess(cameraId));
            yield put(
                openSnackbar({
                    open: true,
                    message: 'Tag Master Camera deleted successfully',
                    variant: 'alert',
                    alert: { color: 'success' }
                })
            );
        } else {
            throw new Error('Organization not found');
        }
    } catch (error) {
        yield put(deleteTagMasterCameraFailure(error.message));
        yield put(
            openSnackbar({
                open: true,
                message: 'Failed to delete Tag Master Camera',
                variant: 'alert',
                alert: { color: 'error' }
            })
        );
    }
}

///////////// Action Creators For Root Saga //////////////////

export function* getAllOrgs() {
    yield takeLatest(GET_ORGS_COLLECTION, getOrgsCollection);
}

export function* createOrg() {
    yield takeLatest(CREATE_ORG, createOrgDocument);
}

export function* updateOrg() {
    yield takeLatest(UPDATE_ORG, updateOrgDocument);
}

export function* getOrgCollection() {
    yield takeLatest(GET_USER_ORG, orgCollectionWatch);
}

export function* getActiveOrgCollection() {
    yield takeLatest(GET_ACTIVE_ORG_COLLECTION, activeOrgCollectionWatch);
}

export function* setActiveOrg() {
    yield takeLatest(SET_ACTIVE_USER_ORG, settingActiveOrg);
}

export function* getDefaultRoles() {
    yield takeLatest(GET_DEFAULT_ROLES_COLLECTION, getDefaultRolesCollection);
}

export function* uploadOrgImage() {
    yield takeLatest(UPDATE_ORG_IMAGE, updateOrgWithImage);
}

export function* resendManagerInvite() {
    yield takeLatest(RESEND_MANAGER_INVITE, resendManagerRequest);
}

export function* getOrgInvitesCollection() {
    yield takeLatest(SET_ACTIVE_USER_ORG, orgInvitesCollectionWatch);
}

export function* removeOrgUser() {
    yield takeLatest(REMOVE_USER_FROM_ORG, deleteOrgUser);
}

export function* watchValidateShortCode() {
    yield takeLatest(VALIDATE_SHORT_CODE, validateShortCodeSaga);
}

export function* watchTagMasterCameraActions() {
    yield takeLatest(ADD_TAG_MASTER_CAMERA, handleAddTagMasterCamera);
    yield takeLatest(UPDATE_TAG_MASTER_CAMERA, handleUpdateTagMasterCamera);
    yield takeLatest(DELETE_TAG_MASTER_CAMERA, handleDeleteTagMasterCamera);
}

export default function* rootSaga() {
    yield all([
        fork(getOrgCollection),
        fork(getAllOrgs),
        fork(createOrg),
        fork(setActiveOrg),
        fork(getActiveOrgCollection),
        fork(getDefaultRoles),
        fork(uploadOrgImage),
        fork(resendManagerInvite),
        fork(updateOrg),
        fork(getOrgInvitesCollection),
        fork(removeOrgUser),
        fork(watchValidateShortCode),
        fork(watchTagMasterCameraActions)
    ]);
}
