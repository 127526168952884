export class FormHelper {
    static generateFormData(object) {
        const formData = new FormData();
        const entries = Object.entries(object);
        entries.forEach(entry => {
            FormHelper.addToPayload(formData, entry[1], entry[0], entry[0]);
        });
        return formData;
    }

    static addToPayload(formData, values, fieldName, nestedFieldName) {
        if (!values && values !== 0 && fieldName) {
            return formData;
        }

        if (Array.isArray(values) && values.length === 0) {
            return formData;
        }

        if (values instanceof File) {
            formData.append(fieldName, values);
        } else if (typeof values === 'object' && values !== null) {
            for (const key in values) {
                if (typeof values[key] === 'object' && values[key] !== null) {
                    FormHelper.addToPayload(
                        formData,
                        values[key],
                        fieldName,
                        `${nestedFieldName}[${key}]`
                    );
                } else {
                    formData.append(`${nestedFieldName}[${key}]`, values[key]);
                }
            }
        } else {
            formData.append(nestedFieldName, values);
        }

        return formData;
    }
}
