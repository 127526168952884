// React
import React, { useCallback, useEffect, useState } from 'react';

// Components
import InputFieldErrorMessage from '../common/InputFieldErrorMessage';

// Firebase
import { db } from 'config/firebase';
import { collection, doc } from 'firebase/firestore';

// Packages
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    InputLabel,
    Select,
    Stack,
    TextField,
    MenuItem,
    Typography
} from '@mui/material';

// Styles
import 'react-widgets/scss/styles.scss';

// Utils
import { preparePropertyAddress } from 'utils/Helpers';
import {
    errorMessage,
    latitudeRegExp,
    longitudeRegExp,
    nameRegExp,
    streetAddressRegExp,
    unitedStates
} from 'utils/constants';

const CreateUpdatePropertyModal = ({
    onClose,
    onCreateOrUpdate,
    property,
    properties,
    handleVisit
}) => {
    const [formattedProps, setFormattedProps] = useState([]);
    const [nearMatches, setNearMatches] = useState(null);

    const validationSchema = Yup.object().shape({
        address_1: Yup.string()
            .trim()
            .required(errorMessage.address.required)
            .matches(streetAddressRegExp, errorMessage.address.valid),
        address_2: Yup.string().trim(),
        city: Yup.string()
            .trim()
            .required(errorMessage.city.required)
            .matches(nameRegExp.format, errorMessage.city.valid),
        state: Yup.string().trim().required(errorMessage.state.required),
        zip: Yup.string().required(errorMessage.zip.required),
        status: Yup.string().trim(),
        latitude: Yup.string().matches(latitudeRegExp, errorMessage.latitude.valid),
        longitude: Yup.string().matches(longitudeRegExp, errorMessage.longitude.valid)
    });

    const isStreetPartialMatch = (word1, word2) => {
        return word1.includes(word2) || word2.includes(word1);
    };

    const getSimilarAddressNumberLine = (existing, incoming) => {
        const newAddressParts = incoming.address.split(' ');
        const newNextWord = newAddressParts[1]?.toLowerCase() || '';

        return existing.filter(property => {
            if (property.address_1 !== incoming.address_1) return false;

            const existingAddressParts = property.address.split(' ');
            const existingNextWord = existingAddressParts[1]?.toLowerCase() || '';

            return isStreetPartialMatch(existingNextWord, newNextWord);
        });
    };

    const getSimilarUnits = (existing, incoming) => {
        const newChars = new Set(incoming.address_2.toLowerCase().split(''));

        return existing.filter(property => {
            const existingChars = new Set(property.address_2.toLowerCase().split(''));
            return [...existingChars].some(char => newChars.has(char));
        });
    };

    const collectMatches = ({ address_1, address_2 }) => {
        const newPropAddress = preparePropertyAddress(
            `${address_1.trim().toLowerCase()} ${address_2.trim().toLowerCase()}`,
            null
        );

        const addNumFiltered = getSimilarAddressNumberLine(
            formattedProps,
            newPropAddress
        );

        if (addNumFiltered.length) {
            if (newPropAddress.address_2.trim() !== '') {
                const unitNumFiltered = getSimilarUnits(addNumFiltered, newPropAddress);

                return unitNumFiltered;
            } else {
                const filteredOfUnits = addNumFiltered.filter(
                    prop => prop.address_2.trim() === ''
                );
                return filteredOfUnits;
            }
        } else {
            return addNumFiltered;
        }
    };

    const formattingProperties = useCallback(
        properties => {
            if (properties && properties.length) {
                const formedProps = properties.map(prop => {
                    const { address } = prop;
                    return preparePropertyAddress(address, prop.id);
                });
                setFormattedProps(formedProps);
            }
        },
        [setFormattedProps]
    );

    useEffect(() => {
        if (properties && properties.length) {
            formattingProperties(properties);
        }
    }, [properties, formattingProperties]);

    const submitForm = formValues => {
        if (nearMatches === null) {
            const matches = collectMatches({
                address_1: formValues.address_1,
                address_2: formValues.address_2
            });
            if (property && property?.id) {
                const filteredMatches = matches.filter(match => match.id !== property.id);
                if (filteredMatches.length) {
                    setNearMatches(filteredMatches);
                } else {
                    onCreateOrUpdate({
                        address_1: formValues.address_1.trim(),
                        address_2: formValues.address_2.trim(),
                        city: formValues.city.trim(),
                        id: formValues.id,
                        state: formValues.state.trim(),
                        zip: formValues.zip.trim(),
                        status: formValues.status.trim(),
                        longitude:
                            formValues.longitude.trim() !== ''
                                ? parseFloat(formValues.longitude.trim())
                                : null,
                        latitude:
                            formValues.latitude.trim() !== ''
                                ? parseFloat(formValues.latitude.trim())
                                : null
                    });
                    onClose();
                }
            } else {
                if (matches.length) {
                    setNearMatches(matches);
                } else {
                    onCreateOrUpdate({
                        address_1: formValues.address_1.trim(),
                        address_2: formValues.address_2.trim(),
                        city: formValues.city.trim(),
                        id: formValues.id,
                        state: formValues.state.trim(),
                        zip: formValues.zip.trim(),
                        status: formValues.status.trim(),
                        longitude:
                            formValues.longitude.trim() !== ''
                                ? parseFloat(formValues.longitude.trim())
                                : null,
                        latitude:
                            formValues.latitude.trim() !== ''
                                ? parseFloat(formValues.latitude.trim())
                                : null
                    });
                    onClose();
                }
            }
        } else {
            onCreateOrUpdate({
                address_1: formValues.address_1.trim(),
                address_2: formValues.address_2.trim(),
                city: formValues.city.trim(),
                id: formValues.id,
                state: formValues.state.trim(),
                zip: formValues.zip.trim(),
                status: formValues.status.trim(),
                longitude:
                    formValues.longitude.trim() !== ''
                        ? parseFloat(formValues.longitude.trim())
                        : null,
                latitude:
                    formValues.latitude.trim() !== ''
                        ? parseFloat(formValues.latitude.trim())
                        : null
            });
            onClose();
        }
    };

    return (
        <>
            <DialogTitle>
                {property
                    ? 'Edit Property'
                    : nearMatches && nearMatches.length
                    ? `Similar ${
                          nearMatches.length > 1 ? 'Properties' : 'Property'
                      } Found`
                    : 'Create Property'}
            </DialogTitle>
            <Divider />
            <Formik
                initialValues={{
                    id: property?.id || doc(collection(db, 'properties')).id,
                    address_1: property?.address_1 || '',
                    address_2: property?.address_2 || '',
                    city: property?.city || '',
                    state: property?.state || '',
                    zip: property?.zip || '',
                    status: property?.status || 'active',
                    latitude: property?.latitude || '',
                    longitude: property?.longitude || ''
                }}
                onSubmit={submitForm}
                validationSchema={validationSchema}
                validateOnChange
            >
                {({
                    handleSubmit,
                    handleChange,
                    touched,
                    handleBlur,
                    errors,
                    values
                }) => (
                    <>
                        <DialogContent sx={{ p: 2.5, minHeight: 360 }}>
                            {!nearMatches || (nearMatches && nearMatches.length === 0) ? (
                                <Grid container spacing={3}>
                                    <Grid item xs={8}>
                                        <Stack spacing={0.5}>
                                            <InputLabel>Main Address</InputLabel>
                                            <TextField
                                                required
                                                id="address_1Basic"
                                                name="address_1"
                                                placeholder="Address line 1"
                                                fullWidth
                                                autoComplete="shipping address-line1"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    touched.address_1 &&
                                                    !!errors.address_1
                                                }
                                                helperText={
                                                    touched.address_1 && errors.address_1
                                                }
                                                value={values.address_1}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Stack spacing={0.5}>
                                            <InputLabel>Unit/Suite</InputLabel>
                                            <TextField
                                                id="address_2Basic"
                                                name="address_2"
                                                placeholder="Address line 2"
                                                fullWidth
                                                autoComplete="shipping address-line2"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    touched.address_2 &&
                                                    !!errors.address_2
                                                }
                                                helperText={
                                                    touched.address_2 && errors.address_2
                                                }
                                                value={values.address_2}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <Stack spacing={0.5}>
                                            <InputLabel>City</InputLabel>
                                            <TextField
                                                required
                                                id="cityBasic"
                                                name="city"
                                                placeholder="City"
                                                fullWidth
                                                autoComplete="shipping address-level2"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.city && !!errors.city}
                                                helperText={touched.city && errors.city}
                                                value={values.city}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Stack spacing={0.5}>
                                            <InputLabel htmlFor={'state'}>
                                                State
                                            </InputLabel>
                                            <Select
                                                required
                                                id="stateSelect"
                                                name={'state'}
                                                value={values.state}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={Boolean(
                                                    touched.state && !!errors.state
                                                )}
                                            >
                                                {unitedStates.map(state => (
                                                    <MenuItem
                                                        key={state.value}
                                                        value={state.value}
                                                    >
                                                        {state.value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <InputFieldErrorMessage
                                                touched={touched.state}
                                                error={errors.state}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Stack spacing={0.5}>
                                            <InputLabel>Zip</InputLabel>
                                            <TextField
                                                required
                                                id="zipBasic"
                                                name="zip"
                                                placeholder="Zip / Postal code"
                                                fullWidth
                                                autoComplete="shipping postal-code"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                    pattern: '[0-9]*'
                                                }}
                                                error={touched.zip && !!errors.zip}
                                                helperText={touched.zip && errors.zip}
                                                value={values.zip}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <Stack spacing={0.5}>
                                            <InputLabel>Latitude</InputLabel>
                                            <TextField
                                                required
                                                id="latitude"
                                                name="latitude"
                                                placeholder="Latitude"
                                                fullWidth
                                                autoComplete="number"
                                                onChange={handleChange}
                                                onBlur={() => {
                                                    if (values.latitude.trim() === '') {
                                                        handleChange({
                                                            target: {
                                                                name: 'latitude',
                                                                value: ''
                                                            }
                                                        });
                                                        handleBlur('latitude');
                                                    } else {
                                                        const lat =
                                                            values.latitude.replace(
                                                                /\s/g,
                                                                ''
                                                            );
                                                        handleChange({
                                                            target: {
                                                                name: 'latitude',
                                                                value: lat
                                                            }
                                                        });
                                                        handleBlur('latitude');
                                                    }
                                                }}
                                                error={
                                                    touched.latitude && !!errors.latitude
                                                }
                                                helperText={
                                                    touched.latitude && errors.latitude
                                                }
                                                value={values.latitude}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <Stack spacing={0.5}>
                                            <InputLabel>Longitude</InputLabel>
                                            <TextField
                                                required
                                                id="longitude"
                                                name="longitude"
                                                placeholder="Longitude"
                                                fullWidth
                                                onChange={handleChange}
                                                onBlur={() => {
                                                    if (values.longitude.trim() === '') {
                                                        handleChange({
                                                            target: {
                                                                name: 'longitude',
                                                                value: ''
                                                            }
                                                        });
                                                        handleBlur('longitude');
                                                    } else {
                                                        const lon =
                                                            values.longitude.replace(
                                                                /\s/g,
                                                                ''
                                                            );
                                                        handleChange({
                                                            target: {
                                                                name: 'longitude',
                                                                value: lon
                                                            }
                                                        });
                                                        handleBlur('longitude');
                                                    }
                                                }}
                                                error={
                                                    touched.longitude &&
                                                    !!errors.longitude
                                                }
                                                helperText={
                                                    touched.longitude && errors.longitude
                                                }
                                                value={values.longitude}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <Stack spacing={0.5}>
                                            <InputLabel>Status</InputLabel>
                                            <Select
                                                id="status"
                                                name="status"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.status}
                                                label="Status"
                                            >
                                                <MenuItem key="active" value="active">
                                                    Active
                                                </MenuItem>
                                                <MenuItem key="inactive" value="inactive">
                                                    Inactive
                                                </MenuItem>
                                            </Select>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            ) : (
                                <>
                                    <Typography variant="h5" sx={{ mb: 0 }}>
                                        {`We have located ${
                                            nearMatches.length > 1
                                                ? 'some existing properties that have a similar address.'
                                                : 'an existing property that has a similar address.'
                                        }`}
                                    </Typography>
                                    <Typography variant="h5" sx={{ mb: 4 }}>
                                        {property?.id
                                            ? `Before submitting your updates to this property, please ensure it is unique and not related to the ${
                                                  nearMatches.length > 1
                                                      ? 'properties'
                                                      : 'property'
                                              } seen below.`
                                            : `Before adding your new property, please ensure it is unique and not related to the ${
                                                  nearMatches.length > 1
                                                      ? 'properties'
                                                      : 'property'
                                              } seen below.`}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{ fontWeight: 'bold', mb: 4 }}
                                        textTransform={'capitalize'}
                                    >
                                        {`Entered Address: ${values.address_1}${
                                            values.address_2.trim() !== ''
                                                ? ` ${values.address_2}`
                                                : ''
                                        }`}
                                    </Typography>

                                    <Typography
                                        variant="h6"
                                        sx={{ mb: 1, color: 'red', fontWeight: 'bold' }}
                                        textTransform={'capitalize'}
                                    >
                                        {nearMatches.length > 1
                                            ? `Existing Property Addresses:`
                                            : `Existing Property Address:`}
                                    </Typography>
                                    {nearMatches.map(match => (
                                        <Stack
                                            key={match.id}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                height: '4vh',
                                                width: '100%',
                                                mb: 1
                                            }}
                                        >
                                            <Stack
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    height: '4vh',
                                                    width: '40%'
                                                }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        color: 'red',
                                                        mb: 0
                                                    }}
                                                    textTransform={'capitalize'}
                                                >
                                                    {`${match.address}`}
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    height: '4vh',
                                                    width: '60%'
                                                }}
                                            >
                                                <Button
                                                    size="small"
                                                    color="info"
                                                    variant="contained"
                                                    onClick={() =>
                                                        handleVisit({
                                                            original: { id: match.id }
                                                        })
                                                    }
                                                >
                                                    Visit
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    ))}
                                </>
                            )}
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ p: 2.5 }}>
                            <Grid container justifyContent="right" alignItems="center">
                                <Grid item>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Button
                                            color="error"
                                            onClick={() => {
                                                if (nearMatches) {
                                                    setNearMatches(null);
                                                } else {
                                                    onClose();
                                                }
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            {property ? 'Update' : 'Add'}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </>
    );
};

export default CreateUpdatePropertyModal;
