import ApiClient from './api-client/api-client';

export default class BaseService {
    static async get(endpoint, queryParams = {}, config = {}) {
        return ApiClient.get(endpoint, queryParams, config).then(
            response => response.data
        );
    }

    static async post(endpoint, body, queryParams = {}) {
        return ApiClient.post(endpoint, body, queryParams).then(
            response => response.data
        );
    }

    static async put(endpoint, body, queryParams = {}) {
        return ApiClient.put(endpoint, body, queryParams).then(response => response.data);
    }

    static async delete(endpoint) {
        return ApiClient.delete(endpoint).then(response => response.data);
    }

    static async postFile(endpoint, file) {
        return ApiClient.postFile(endpoint, file).then(response => response.data);
    }

    static async postFormData(endpoint, body, queryParams = {}) {
        return ApiClient.postFormData(endpoint, body, queryParams).then(
            response => response.data
        );
    }
}
