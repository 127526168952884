import {
    all,
    fork,
    take,
    cancelled,
    takeLatest,
    race,
    put,
    call
} from 'redux-saga/effects';

import { eventChannel } from 'redux-saga';

import {
    HANDLE_DEVICE,
    GET_DEVICE_OPERATIONS,
    LOGOUT_USER,
    GET_PROPERTY_MEMBERS,
    RESET_PROPERTY_MEMBERS,
    CHECK_DEVICE_EXISTS
} from '../actions/types';

import { rtdb, db, func } from 'config/firebase';
import {
    onValue,
    orderByChild,
    query as rtdbQuery,
    ref as rtdbRef
} from 'firebase/database';

import { collection, query, where, onSnapshot, getDocs } from 'firebase/firestore';

import {
    getDeviceOperationsSuccess,
    getDeviceOperationsFailure,
    getPropertyDevicesSuccess,
    getPropertyDevicesFailure,
    handleDeviceSuccess,
    handleDeviceFailure,
    setDeviceExistsValidationResult
} from '../actions/DeviceOperations';

import { openSnackbar } from 'store/actions/Snackbar';
import { errorMessage } from '../../utils/constants';

// Loggers
import { log } from '../../utils/Loggers';
import { calculateDeviceStatus } from '../../utils/devices/DeviceHelper';
import { httpsCallable } from 'firebase/functions';
import { getOperationString } from 'utils/Helpers';

const deviceCollectionRef = collection(db, 'devices');

const addEditCaDevices = httpsCallable(func, 'addEditCaDevices');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////// Watch Device Operations //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* deviceOperationsWatch() {
    const activeOperations = rtdbRef(rtdb, `device_operations`);

    const DeviceOperationsChannel = eventChannel(emit => {
        const query = rtdbQuery(activeOperations, orderByChild('created_at'));
        const unsubscribeDeviceOperationsData = onValue(query, snapshot => {
            const operations = [];
            if (snapshot.exists()) {
                snapshot.forEach(childSnapshot => {
                    operations.push(childSnapshot.val());
                });
                emit(operations);
            } else {
                emit([]);
            }
        });
        return () => {
            unsubscribeDeviceOperationsData();
        };
    });

    const detachSagaEmitters = () => {
        DeviceOperationsChannel.close();
    };

    try {
        while (true) {
            const { userSignOut, operationsData } = yield race({
                userSignOut: take(LOGOUT_USER),
                operationsData: take(DeviceOperationsChannel)
            });

            if (userSignOut) {
                detachSagaEmitters();
            } else if (operationsData) {
                yield put(getDeviceOperationsSuccess(operationsData));
            }
        }
    } catch (error) {
        yield put(getDeviceOperationsFailure(error));
        //TODO: Error Handling
        log('Device Operations Error: getting operations data (RTDB)', {
            error
        });
    } finally {
        // detachFBListeners(); // Detaching firebase listeners
        if (yield cancelled()) {
            detachSagaEmitters(); // Detaching saga event emitter
            // detachFBListeners(); // Detaching firebase listeners
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////// Complete Device Operation /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// const completeDeviceOperationRequest = async ({ operation, type }) => {
//     const propertyDocRef = doc(propertiesCollectionRef, operation.property_id);
//     const rtdbOperationRef = rtdbRef(rtdb, `/device_operations/${operation.device_id}`);

//     try {
//         if (type === 'add') {
//             //     const propertyDoc = await getDoc(propertyDocRef);
//             //     if (propertyDoc.exists()) {
//             //         const property = propertyDoc.data();
//             //         const devices = property.devices.map(device => {
//             //             if (operation.device_id === device.id) {
//             //                 return {
//             //                     ...device,
//             //                     status:
//             //                         device.status === 'pending' ? 'enabled' : device.status
//             //                 };
//             //             } else {
//             //                 return { ...device };
//             //             }
//             //         });
//             //         if (devices) {
//             //             await updateDoc(propertyDocRef, {
//             //                 devices
//             //             });
//             //         }
//             //     }
//         }
//         await set(rtdbOperationRef, null);
//         return { res: true };
//     } catch (error) {
//         throw new Error(error);
//     }
// };

// export function* completeDeviceOperation({ payload }) {
//     const { operation } = payload;
//     const { type } = operation;
//     const { res, error } = yield call(() =>
//         completeDeviceOperationRequest({
//             operation,
//             type
//         })
//     );
//     if (res) {
//         yield put(
//             openSnackbar({
//                 open: true,
//                 message: `Success: ${type.toUpperCase()} Device Operation has been completed`,
//                 variant: 'alert',
//                 alert: {
//                     color: 'success'
//                 }
//             })
//         );
//     } else {
//         yield put(
//             openSnackbar({
//                 open: true,
//                 message: `Failed: '${type}' Device Operation was not completed successfully.`,
//                 variant: 'alert',
//                 alert: {
//                     color: 'error'
//                 }
//             })
//         );

//         log(`Device Operation Error: completing member device operation (FS/RTDB)`, {
//             error,
//             operation
//         });
//     }
// }

export function* handlingDevices({ payload }) {
    console.log('payload:', payload);
    const result = yield call(addEditCaDevices, payload);
    console.log('result', result);
    const operationStatus = getOperationString(payload.device.operation);

    if (result?.data?.success) {
        yield put(handleDeviceSuccess());
        yield put(
            openSnackbar({
                open: true,
                message: `Success: Device has been ${operationStatus}!`,
                variant: 'alert',
                alert: {
                    color: 'success'
                }
            })
        );
    } else {
        yield put(handleDeviceFailure());
        yield put(
            openSnackbar({
                open: true,
                message: `Failed: ${result?.data?.message}`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////// Get Property Devices ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* propertyDevicesCollectionWatch({ payload }) {
    const { propertyId } = payload;
    let unsubscribePropertyDevicesCollectionData;

    const propertyDevicesCollectionQuery = query(
        deviceCollectionRef,
        where('property_id', '==', propertyId)
    );

    const propertyDevicesCollectionChannel = eventChannel(emit => {
        unsubscribePropertyDevicesCollectionData = onSnapshot(
            propertyDevicesCollectionQuery,
            querySnapshot => {
                const propertyDevices = [];
                querySnapshot.forEach(doc => {
                    const device = doc.data();

                    device.status = calculateDeviceStatus(device);

                    propertyDevices.push(device);
                });
                if (!querySnapshot.size) {
                    emit([]);
                } else {
                    emit(propertyDevices);
                }
            }
        );
        return unsubscribePropertyDevicesCollectionData;
    });

    try {
        while (true) {
            const { userSignOut, resetPropertyMembers, propertyDevicesCollectionData } =
                yield race({
                    userSignOut: take(LOGOUT_USER),
                    resetPropertyMembers: take(RESET_PROPERTY_MEMBERS),
                    propertyDevicesCollectionData: take(propertyDevicesCollectionChannel)
                });

            if (userSignOut || resetPropertyMembers) {
                propertyDevicesCollectionChannel.close();
            } else {
                yield put(getPropertyDevicesSuccess(propertyDevicesCollectionData));
            }
        }
    } catch (error) {
        log('Virtual Guard Error: watching property members by property ID (FS)', {
            error,
            propertyId
        });
        yield put(getPropertyDevicesFailure(error));
    } finally {
        unsubscribePropertyDevicesCollectionData();
        if (yield cancelled()) {
            propertyDevicesCollectionChannel.close();
            unsubscribePropertyDevicesCollectionData();
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////// Check Device Exists /////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* checkDeviceExists({ payload }) {
    const { deviceNumber, orgId } = payload;

    try {
        const deviceQuery = query(
            collection(db, 'devices'),
            where('device_number', '==', deviceNumber),
            where('org_id', '==', orgId)
        );

        const snapshot = yield call(getDocs, deviceQuery);

        if (snapshot.empty) {
            yield put(setDeviceExistsValidationResult(true, null));
        } else {
            yield put(
                setDeviceExistsValidationResult(
                    false,
                    errorMessage.device.device_number.used
                )
            );
        }
    } catch (error) {
        yield put(setDeviceExistsValidationResult(false, errorMessage.validation_failed));
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* getDeviceOperations() {
    yield takeLatest(GET_DEVICE_OPERATIONS, deviceOperationsWatch);
}

export function* completingDeviceOperation() {
    yield takeLatest(HANDLE_DEVICE, handlingDevices);
}

export function* getPropertyDevices() {
    yield takeLatest(GET_PROPERTY_MEMBERS, propertyDevicesCollectionWatch);
}

export function* watchCheckDeviceExists() {
    yield takeLatest(CHECK_DEVICE_EXISTS, checkDeviceExists);
}

export default function* rootSaga() {
    yield all([
        fork(getDeviceOperations),
        fork(completingDeviceOperation),
        fork(getPropertyDevices),
        fork(watchCheckDeviceExists)
    ]);
}
