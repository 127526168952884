import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

// project import
import { connect } from 'react-redux';
import { selectProperty } from 'store/actions/Properties';
import { userRoles } from 'utils/constants';

// ==============================|| RESI GUARD ||============================== //

const ResiGuard = ({
    children,
    userData,
    selectedProperty,
    selectProperty,
    authUser
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isChecking, setIsChecking] = useState(true);

    useEffect(() => {
        if (userData && userData.properties && !selectedProperty) {
            const userProperties = [...Object.values(userData?.properties)];
            selectProperty(userProperties[0]);
        }
    }, [userData, selectedProperty, selectProperty]);

    useEffect(() => {
        if (!authUser) {
            navigate('/login', { replace: true });
        }
    }, [authUser, navigate]);

    useEffect(() => {
        if (userData) {
            if (
                userData?.photo_id === null &&
                location.pathname !== '/upload-identification'
            ) {
                navigate('/upload-identification', { replace: true });
            } else if (
                userData?.photo_id !== null &&
                location.pathname === '/upload-identification'
            ) {
                navigate('/', { replace: true });
            }
            setIsChecking(false);
        }
    }, [userData, navigate, location]);

    if (!authUser || isChecking) {
        return null;
    }

    if (userData && userData?.role !== userRoles.RESIDENT) {
        return <Navigate to="/maintenance/404" state={{ from: location }} />;
    }

    if (userData?.photo_id === null && location.pathname !== '/upload-identification') {
        return null;
    }

    if (userData?.photo_id !== null && location.pathname === '/upload-identification') {
        return null;
    }

    return children;
};

ResiGuard.propTypes = {
    children: PropTypes.node,
    userData: PropTypes.object,
    selectedProperty: PropTypes.object,
    selectProperty: PropTypes.func,
    authUser: PropTypes.object
};

const mapStateToProps = ({ properties, user, auth }) => {
    const { authUser } = auth;
    const { selectedProperty } = properties;
    const { userData } = user;

    return { selectedProperty, userData, authUser };
};

export default connect(mapStateToProps, { selectProperty })(ResiGuard);
