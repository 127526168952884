import React, { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import ResiLayout from 'layout/ResiLayout';
import ResiGuard from 'utils/route-guard/ResiGuard';

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));

// render - login
const ManageGuests = Loadable(lazy(() => import('pages/resi/ManageGuests')));
const AddGuest = Loadable(lazy(() => import('pages/resi/AddGuest')));
const EditGuest = Loadable(lazy(() => import('pages/resi/EditGuest')));
const AddOtc = Loadable(lazy(() => import('pages/resi/AddOtc')));
const ReviokeOtc = Loadable(lazy(() => import('pages/resi/ReviokeOtc')));
const MyFamily = Loadable(lazy(() => import('pages/resi/MyFamily')));
const FamilyAdd = Loadable(lazy(() => import('pages/resi/FamilyAdd')));
const FamilyEdit = Loadable(lazy(() => import('pages/resi/FamilyEdit')));
const UploadIdentification = Loadable(lazy(() => import('pages/resi/UploadIdentification')));

// ==============================|| AUTH ROUTING ||============================== //

const ResiRoutes = {
    path: '/',
    children: [
        {
            path: '*',
            element: <MaintenanceError />
        },
        {
            path: '/',
            element: (
                <ResiGuard>
                    <ResiLayout />
                </ResiGuard>
            ),
            children: [
                {
                    path: '/',
                    element: <ManageGuests />
                },
                {
                    path: 'upload-identification',
                    element: <UploadIdentification />
                },
                {
                    path: 'manage-guests',
                    element: <ManageGuests />
                },
                {
                    path: 'add-guest',
                    element: <AddGuest />
                },
                {
                    path: 'edit-guest/:id',
                    element: <EditGuest />
                },
                {
                    path: 'add-one-time-access-code',
                    element: <AddOtc />
                },
                {
                    path: 'revoke-one-time-access-code/:id',
                    element: <ReviokeOtc />
                },
                {
                    path: 'my-guest',
                    element: <AddGuest />
                },
                {
                    path: 'my-family',
                    element: <MyFamily />
                },
                {
                    path: 'my-family-add',
                    element: <FamilyAdd />
                },
                {
                    path: 'my-family-edit/:id',
                    element: <FamilyEdit />
                }
            ]
        }
    ]
};

export default ResiRoutes;
