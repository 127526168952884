import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { VIRTUAL_GUARD } from 'utils/route-constants';
import { useLocation } from 'react-router-dom';
import Profile from './Profile';
import MobileSection from './MobileSection';
import ScannerConfiguration from './ScannerConfiguration';

const HeaderContent = () => {
    const matchesXs = useMediaQuery(theme => theme.breakpoints.down('md'));
    const location = useLocation();

    const isVirtualGuardRoute = location.pathname === VIRTUAL_GUARD;

    return (
        <>
            {!matchesXs && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {isVirtualGuardRoute && <ScannerConfiguration />}
                    <Profile />
                </Box>
            )}

            {matchesXs && <MobileSection />}
        </>
    );
};

export default HeaderContent;
