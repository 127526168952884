import React from 'react';
import {
    Alert,
    Button,
    Chip,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputLabel,
    Link,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../../../config/firebase';
import wsClient from '../../../../../utils/web-socket-client';
import { errorMessage } from '../../../../../utils/constants';

const validationSchema = Yup.object({
    ipAddress: Yup.string()
        .required(errorMessage.scanner.ipAddress.required)
        .matches(
            /^(25[0-5]|2[0-4][0-9]|1?[0-9]{1,2})(\.(25[0-5]|2[0-4][0-9]|1?[0-9]{1,2})){3}$/,
            errorMessage.scanner.ipAddress.invalid
        ),
    port: Yup.number()
        .typeError(errorMessage.scanner.port.type)
        .required(errorMessage.scanner.port.required)
        .min(1, errorMessage.scanner.port.min)
        .max(65535, errorMessage.scanner.port.max)
});

export default function ScannerDetailsModal({ onSave, isConnected, onClose }) {
    const [isConnecting, setIsConnecting] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            ipAddress: '127.0.0.1',
            port: '2080'
        },
        validationSchema,
        onSubmit: values => {
            setIsConnecting(true);

            const handleConnected = () => {
                setIsConnecting(false);
                cleanup();
                onClose();
            };

            const handleDisconnected = () => {
                setIsConnecting(false);
                cleanup();
            };

            const cleanup = () => {
                wsClient.removeConnectListener(handleConnected);
                wsClient.removeDisconnectListener(handleDisconnected);
            };

            wsClient.onConnect(handleConnected);
            wsClient.onDisconnect(handleDisconnected);

            if (onSave) {
                onSave(values.ipAddress, values.port);
            }
        }
    });

    const handleStop = () => {
        wsClient.disconnect();
        onClose();
    };

    const downloadSageWebSocketServerApp = async () => {
        const DOWNLOAD_PATHS = {
            mac: 'scanner_ws_installation/macOS/Sage WebSocket Scanner Server-1.0.0-arm64.dmg',
            win: 'scanner_ws_installation/windows/Sage WebSocket Scanner Server Setup 1.0.0.exe'
        };

        const userAgent = navigator.userAgent.toLowerCase();
        let platform;

        if (userAgent.includes('mac')) {
            platform = 'mac';
        } else if (userAgent.includes('win')) {
            platform = 'win';
        }

        if (platform) {
            const storageRef = ref(storage, DOWNLOAD_PATHS[platform]);
            return await getDownloadURL(storageRef);
        } else {
            throw new Error('Unsupported platform');
        }
    };

    return (
        <>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
                Sage WebSocket Scanner Server
                {!isConnected ? (
                    <Chip
                        color="error"
                        label="Disconnected"
                        size="small"
                        variant="light"
                    />
                ) : (
                    <Chip
                        color="primary"
                        label="Connected"
                        size="small"
                        variant="light"
                    />
                )}
            </DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent dividers>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <Stack spacing={0.5}>
                                <InputLabel>IP Address</InputLabel>
                                <TextField
                                    name="ipAddress"
                                    variant="outlined"
                                    placeholder="IP Address"
                                    fullWidth
                                    disabled={isConnected}
                                    value={formik.values.ipAddress}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.ipAddress &&
                                        Boolean(formik.errors.ipAddress)
                                    }
                                    helperText={
                                        formik.touched.ipAddress
                                            ? formik.errors.ipAddress || ' '
                                            : ' '
                                    }
                                    InputProps={{
                                        readOnly: true // Temporary
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={4}>
                            <Stack spacing={0.5}>
                                <InputLabel>Port</InputLabel>
                                <TextField
                                    name="port"
                                    variant="outlined"
                                    placeholder="Port"
                                    fullWidth
                                    disabled={isConnected}
                                    value={formik.values.port}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.port && Boolean(formik.errors.port)
                                    }
                                    helperText={
                                        formik.touched.port
                                            ? formik.errors.port || ' '
                                            : ' '
                                    }
                                    InputProps={{
                                        readOnly: true // Temporary
                                    }}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    <Alert severity="info" variant="outlined">
                        <Typography variant="caption" display="block" align="justify">
                            In order to connect to the WebSocket server, you need to
                            download and install the server application on your local
                            machine, where you will use the scanner. If you haven’t
                            downloaded it yet,{' '}
                            <Link
                                color="#26B0BA"
                                sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                                onClick={async () => {
                                    const url = await downloadSageWebSocketServerApp();
                                    window.open(url, '_blank', 'noopener,noreferrer');
                                }}
                            >
                                click here
                            </Link>{' '}
                            to download it.
                        </Typography>
                    </Alert>
                </DialogContent>
                <DialogActions sx={{ py: 2, px: 2.5 }}>
                    <Grid container justifyContent="right" alignItems="center">
                        <Grid item>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Button color="error" onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button
                                    color="error"
                                    variant="contained"
                                    disabled={!isConnected}
                                    onClick={handleStop}
                                >
                                    Stop
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={isConnected || isConnecting}
                                >
                                    {isConnecting ? 'Connecting...' : 'Connect'}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>
        </>
    );
}
