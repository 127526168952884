import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_NEST_API
});

axiosInstance.interceptors.request.use(
    config => {
        config.headers = {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: axios.defaults.headers.common.Authorization,
            ...config.headers
        };

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        console.log('error', error);

        if (error.response && error.response.status === 500) {
            return Promise.reject({
                ...error.response.data,
                message: undefined
            });
        }

        return Promise.reject(error.response?.data);
    }
);

export default axiosInstance;
