// React
import React, { useState, useEffect } from 'react';

// Assets

// Firebase
import { fsTimeStampNow } from 'config/firebase';

// Packages
import { Button, Stack, OutlinedInput } from '@mui/material';

// Styles
import '../../styles/image-zoom.css';

// Utils
import { visitorSchema } from 'utils/constants';

const QuickAccess = ({ activePanel, orgs, setVisitor, userData, setAccessVehicle }) => {
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [tag, setTag] = useState('');
    const [orgId, setOrgId] = useState(null);

    useEffect(() => {
        if (orgs && orgs?.length && activePanel) {
            if (!orgId) setOrgId(activePanel.orgId);
        }
    }, [orgs, activePanel, orgId, setOrgId]);

    const handleConfirmAccess = () => {
        const fsTime = fsTimeStampNow();
        const visitor = Object.assign(visitorSchema, {
            approval: {
                approved_by: userData.uid,
                name: `${userData.first_name} ${userData.last_name}`,
                type: 'visitor'
            },
            denial: null,
            access: true,
            company_name: company.trim() !== '' ? company.trim().toLowerCase() : null,
            created_at: fsTime,
            id: null,
            name: name.trim().toLowerCase(),
            photo_id: null,
            property_id: userData.active_org_id,
            vehicle: {
                color: null,
                country: null,
                make: null,
                model: null,
                primary: null,
                state: null,
                tag: tag.trim().length > 0 ? tag.trim().toUpperCase() : null,
                updated_at: fsTime,
                year: null
            }
        });
        setAccessVehicle({
            color: '',
            country: '',
            make:'',
            model: '',
            primary: false,
            state: '',
            tag: tag.trim().length > 0 ? tag.toUpperCase() : '',
            year: '',
            notes: 'Quick Access'
        });
        setVisitor(visitor);
    };

    const handleTag = text => {
        const formattedTag = text.replace(/[^a-zA-Z0-9]/g, '');
        setTag(formattedTag);
    };

    return (
        <Stack sx={{ pt: 1, pl: 3, pr: 3 }}>
            <Stack
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <OutlinedInput
                    sx={{ marginBottom: '2vh' }}
                    inputProps={{
                        sx: {
                            height: '3vh',
                            width: '100%',
                            backgroundColor: 'rgba(242, 242, 242, 1)',
                            borderRadius: '4px',
                            border: '1px solid rgba(239, 239, 239, 1)'
                        }
                    }}
                    type="text"
                    placeholder="Name"
                    onChange={e => setName(e.target.value)}
                    value={name}
                />
                <OutlinedInput
                    sx={{ marginBottom: '2vh' }}
                    inputProps={{
                        sx: {
                            height: '3vh',
                            width: '100%',
                            backgroundColor: 'rgba(242, 242, 242, 1)',
                            borderRadius: '4px',
                            border: '1px solid rgba(239, 239, 239, 1)'
                        }
                    }}
                    type="text"
                    placeholder="Company"
                    onChange={e => setCompany(e.target.value)}
                    value={company}
                />
                <OutlinedInput
                    inputProps={{
                        sx: {
                            height: '3vh',
                            width: '100%',
                            backgroundColor: 'rgba(242, 242, 242, 1)',
                            borderRadius: '4px',
                            border: '1px solid rgba(239, 239, 239, 1)',
                            textTransform: 'uppercase'
                        },
                        maxLength: 8
                    }}
                    type="text"
                    placeholder="Vehicle Plate/Tag"
                    onChange={e => handleTag(e.target.value)}
                    value={tag}
                />
            </Stack>
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'absolute',
                    bottom: '2vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%'
                }}
            >
                <Button
                    sx={{
                        width: '45%',
                        height: '4.5vh',
                        borderRadius: '26.5px',
                        '&:active::after': {
                            borderRadius: '26.5px'
                        },
                        fontSize: '0.9375rem',
                        fontWeight: '600',
                        backgroundColor: 'rgba(58, 230, 137, 1)',
                        '&:disabled': {
                            backgroundColor: 'rgba(58, 230, 137, .7)'
                        },
                        marginRight: '2.5%'
                    }}
                    disabled={name.trim() === ''}
                    onClick={handleConfirmAccess}
                    variant="contained"
                >
                    {'Grant Access'}
                </Button>
            </Stack>
        </Stack>
    );
};
export default QuickAccess;
