// Redux, Redux-Sagas
import { all, call, cancelled, fork, put, take, takeLatest } from 'redux-saga/effects';

import { eventChannel } from 'redux-saga';

// import * as selectors from './Selectors';
// Firebase
import { auth, authTools, func, funcTools } from 'config/firebase';

// Actions, Action Types
import {
    CHECK_USER_AUTHENTICATED,
    CREATE_AUTH_USER,
    EXECUTE_RESET_PASSWORD_FLIGHT,
    LOGIN_USER_EMAIL_PASSWORD,
    LOGOUT_USER,
    NON_AUTH_RESET_USER_PASSWORD,
    RESEND_INVITE_CODE,
    RESET_INITIAL_MANAGER_PASSWORD,
    UPDATE_AUTH_USER_EMAIL,
    UPDATE_AUTH_USER_PASSWORD,
    VALIDATE_INVITE_CODE,
    VERIFY_ACTION_CODE
} from '../actions/types';

import {
    actionCodeValidationFailure,
    actionCodeValidationSuccess,
    emailUpdateFailure,
    emailUpdateSuccess,
    loginUserFailure,
    logoutUserSuccess,
    passwordUpdateFailure,
    passwordUpdateSuccess,
    resendInviteCodeFailure,
    resendInviteCodeSuccess,
    resetPasswordFailure,
    resetPasswordSuccess,
    sendResetPasswordLinkFailure,
    sendResetPasswordLinkSuccess,
    updateAuthUserToken,
    userIsAuthenticated,
    userNotAuthenticated,
    validateInviteCodeFailure,
    validateInviteCodeSuccess
} from '../actions/Auth';

// Constants
import { errorMessage } from '../../utils/constants';

import { userCollectionWatch } from './User';
import { httpsCallable } from 'firebase/functions';
import { activateOrgUser } from './Org';

// Loggers
import { log } from '../../utils/Loggers';
import { apiPost } from 'api';
import { generateInviteCode } from 'utils/Helpers';
import {AuthHelper} from '../../utils/helpers/auth.helper';

const executeResetPassword = funcTools.httpsCallable(func, 'resetPasswordRequest');
const changeInitialManagerPassword = httpsCallable(func, 'changeInitialManagerPassword');
const createNewAuthUser = httpsCallable(func, 'createAccessWithPasswordAndEmailRequest');

////////////////////////////////////// Checking/Watching for Authenticated User ///////////////////////////////////////

export function* userAuthWatch() {
    let unsubscribeAuth;
    const userAuthChannel = eventChannel(emit => {
        unsubscribeAuth = authTools.onAuthStateChanged(auth, user => {
            if (user) {
                user.exists = true;
                emit({ user });
            } else {
                user = { exists: false };
                emit({ user });
            }
        });
        return unsubscribeAuth;
    });
    try {
        while (true) {
            const authenticated = yield take(userAuthChannel);
            if (authenticated.user.exists === true) {
                yield put(userIsAuthenticated(authenticated.user));
                yield fork(userCollectionWatch, authenticated.user);
                userAuthChannel.close(); //Unregistering as we dont need to listen continuously
            } else {
                yield put(userNotAuthenticated());
                userAuthChannel.close();
            }
        }
    } catch (error) {
        console.log('Auth Error: getting user auth channel', {
            error
        });
    } finally {
        unsubscribeAuth(); // Detach firebase listener
        // Detach saga event emitter if the saga was cancelled
        if (yield cancelled()) {
            userAuthChannel.close();
            unsubscribeAuth();
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////// Log In With Email and Password ////////////////////////////////////////////

function createTokenChannel() {
    return eventChannel(emit => {
        return auth.onIdTokenChanged(user => {
            if (user) {
                user.getIdToken().then(token => {
                    emit(token);
                });
            }
        });
    });
}

function* watchTokenRefresh() {
    const tokenChannel = yield call(createTokenChannel);
    while (true) {
        const newToken = yield take(tokenChannel);
        AuthHelper.setAccessToken(newToken);
        yield put(updateAuthUserToken(newToken));
    }
}

export const loginUserEmailPasswordRequest = async (email, password, operator) =>
    operator
        ? { authenticated: true }
        : await authTools
              .signInWithEmailAndPassword(auth, email, password)
              .then(response => {
                  AuthHelper.setAccessToken(response.user.accessToken);
                  return { authenticated: response };
              })
              .catch(error => {
                  return { error };
              });

export function* loginUserEmailPassword({ payload }) {
    const { email, password } = payload;
    const { authenticated, error } = yield call(() =>
        loginUserEmailPasswordRequest(email, password)
    );
    if (authenticated) {
        yield put(userIsAuthenticated(authenticated.user));
        yield fork(userCollectionWatch, authenticated.user);
        yield fork(watchTokenRefresh, authenticated.user);
        window.location.href = '/';
    } else {
        let errorObj;
        if (error.code === 'auth/wrong-password') {
            errorObj = {
                error,
                message: errorMessage.emailPasswordCombo
            };
            log('Auth Error: signing in with username & password', {
                error,
                email,
                password
            });
        } else if (error.code === 'auth/user-not-found') {
            errorObj = {
                error,
                message: errorMessage.emailNoUser
            };
            log('Auth Error: signing in with nonexistent account', {
                error,
                email,
                password
            });
        }
        yield put(loginUserFailure(errorObj));
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////////////////// Reset User Password Request Link Const and Func ///////////////////////////////////

const sendResetUserPasswordLinkRequest = async email => {
    const request = await executeResetPassword({ email });
    return { resetPasswordLink: request };
};

function* sendResetUserPasswordLink({ payload }) {
    const { email } = payload;
    const { resetPasswordLink, error } = yield call(
        sendResetUserPasswordLinkRequest,
        email
    );
    if (resetPasswordLink) {
        yield put(sendResetPasswordLinkSuccess());
    } else {
        if (error.code === 'auth/user-not-found') {
            console.log(
                'Reset Password Link Error: email not associated with existing account',
                {
                    error,
                    email
                }
            );
            yield put(sendResetPasswordLinkFailure());
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////// Reset User Password Request Const and Func /////////////////////////////////////

const verifyActionCodeRequest = oobCode => {
    return authTools
        .verifyPasswordResetCode(auth, oobCode)
        .then(email => {
            return { validCode: email };
        })
        .catch(error => {
            return { error };
        });
};

function* verifyActionCode({ payload }) {
    const { oobCode } = payload;
    const { validCode, error } = yield call(verifyActionCodeRequest, oobCode);
    if (validCode) {
        yield put(actionCodeValidationSuccess());
    } else {
        console.log('Reset Password Link Error: link has expired or is invalid', {
            error,
            oobCode
        });
        yield put(actionCodeValidationFailure());
    }
}

const resetUserPasswordRequest = async (oobCode, password) => {
    return authTools
        .confirmPasswordReset(auth, oobCode, password)
        .then(() => {
            return { resetPassword: true };
        })
        .catch(error => {
            return { error };
        });
};

function* resetUserPassword({ payload }) {
    const { oobCode, password } = payload;
    const { resetPassword, error } = yield call(
        resetUserPasswordRequest,
        oobCode,
        password
    );
    if (resetPassword) {
        yield put(resetPasswordSuccess());
    } else {
        console.log('Reset Password Error: unsuccessful password reset', {
            error,
            oobCode,
            password
        });
        yield put(resetPasswordFailure());
        // if (error.code === 'auth/user-not-found') {
        //     // yield put(sendResetPasswordLinkFailure());
        //     //Log sentry of people trying to request password reset with no actual account
        // }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////// Reset Manager password on initial invite /////////////////////////////////////

export async function resetManagerPasswordRequest(payload) {
    const { password, email } = payload;
    try {
        const res = await changeInitialManagerPassword({ newPassword: password, email });
        return res?.data;
    } catch (error) {
        console.error(error);
    }
}

function* resetInitialManagerPassword({ payload }) {
    const { password, email, orgId } = payload;
    const { success } = yield resetManagerPasswordRequest({ email, password });

    if (success) {
        yield activateOrgUser(orgId, email);
        yield put(resetPasswordSuccess());
    } else {
        console.log('Reset Password Error: unsuccessful password reset', {
            email,
            password
        });
        yield put(resetPasswordFailure());
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////// Create User With Email and Password Const and Func ///////////////////////////////////

// const createUserWithEmailPasswordRequest = async (email, password) =>
//   await auth.createUserWithEmailAndPassword(email, password)
//     .then(authUser => authUser)
//     .catch(error => error);

// function* createUserWithEmailPassword({ payload }) {
//   const { email, password } = payload;
//   try {
//     const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
//     if (signUpUser.message) {
//       console.log(signUpUser.message)
//       // yield put(showAuthMessage(signUpUser.message));
//     } else {
//       yield put(userSignUpSuccess(signUpUser));
//     }
//   } catch (error) {
//     console.log(error)
//     // yield put(showAuthMessage(error));
//   }
// }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////// Log User Out ////////////////////////////////////////////////

const loggingUserOutRequest = async () =>
    await authTools
        .signOut(auth)
        .then(() => true)
        .catch(error => error);

function* loggingUserOut() {
    try {
        const logoutUser = yield call(loggingUserOutRequest);
        if (logoutUser?.message) {
            console.log(logoutUser.message);
        } else {
            yield put(logoutUserSuccess());
        }
    } catch (error) {
        console.log('Auth Error: user unsuccessful in logging out', {
            error
        });
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export async function verifyAuth(email, password) {
    try {
        const auth = await authTools.getAuth();
        const currentAuthUser = auth.currentUser;

        if (!currentAuthUser) {
            throw new Error('Auth link may be expired.');
        } else {
            const credential = await authTools.EmailAuthProvider.credential(
                email,
                password
            );
            return authTools
                .reauthenticateWithCredential(currentAuthUser, credential)
                .then(res => {
                    return res;
                })
                .catch(err => {
                    console.log(err);
                    return err;
                });
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}

export function* updateUserPassword({ payload }) {
    try {
        const auth = yield authTools.getAuth();
        const currentAuthUser = auth.currentUser;

        if (!currentAuthUser) {
            throw new Error('Auth link may be expired.');
        }

        const updated = yield;
        authTools
            .updatePassword(currentAuthUser, payload)
            .then(() => true)
            .catch(err => {
                throw err;
            });

        if (updated) {
            yield put(passwordUpdateSuccess());
        }
    } catch (error) {
        yield put(passwordUpdateFailure(`Password was not set. ${error}`));
        return false;
    }
}

export function* updateUserEmail({ payload }) {
    try {
        const currentAuthUser = yield auth.currentUser;

        if (!currentAuthUser) {
            throw new Error('Auth link may be expired.');
        }

        const updated = yield authTools
            .updateEmail(currentAuthUser, payload)
            .then(() => true)
            .catch(err => {
                throw err;
            });

        if (updated) {
            yield put(emailUpdateSuccess());
            return true;
        }
        yield put(emailUpdateFailure('Email was not updated. Try again later.'));
        return false;
    } catch (error) {
        yield put(emailUpdateFailure(`Email was not updated. ${error}`));
        return false;
    }
}

export function* validateInviteCode({ payload }) {
    try {
        const res = yield apiPost(
            `/guest-invites/phone-validate`,
            {
                phone_number: payload
            },
            true
        );
        if (res.data) {
            yield put(validateInviteCodeSuccess(res.data));
        } else {
            yield put(
                validateInviteCodeFailure(
                    res?.error?.response?.data?.error ||
                        'Invite code validation failed. Please try again later.'
                )
            );
        }
    } catch (error) {
        validateInviteCodeFailure(error);
    }
}

export function* addAuthUser(newUser) {
    const newAuthUsers = yield call(createNewAuthUser, { members: [newUser] });
    if (newAuthUsers?.data?.processed.length > 0) {
        return newAuthUsers?.data?.processed[0].id;
    } else {
        throw new Error('Failed to create new authentication user');
    }
}

// func refreshGuestInvite(inviteCode: String, contactValue: String, isEmailSelected: Bool, completion: @escaping (Result<Data?, Error>) -> Void) {
//     let endpoint = "/guest-invites/refresh"
//     let urlString = baseURLString + endpoint

//     guard let url = URL(string: urlString) else {
//         completion(.failure(NSError(domain: "Invalid URL", code: 0, userInfo: nil)))
//         return
//     }

//     var request = URLRequest(url: url)
//     request.httpMethod = "POST"
//     request.setValue("application/json", forHTTPHeaderField: "Content-Type")

//     var parameters: [String: Any] = [
//         "invite_code": inviteCode
//     ]

//     if isEmailSelected {
//         parameters["email"] = contactValue
//     } else {
//         parameters["phone_number"] = contactValue
//     }

//     do {
//         request.httpBody = try JSONSerialization.data(withJSONObject: parameters)
//     } catch {
//         completion(.failure(error))
//         return
//     }

//     URLSession.shared.dataTask(with: request) { data, response, error in
//         if let error = error {
//             completion(.failure(error))
//         } else if let data = data {
//             completion(.success(data))
//         } else {
//             completion(.success(nil))
//         }
//     }.resume()
// }
export function* resendInviteCode({ payload }) {
    const { email, phoneNumber } = payload;
    try {
        const inviteCode = generateInviteCode();

        const apiPayload = {
            invite_code: inviteCode
        };

        if (email) {
            apiPayload.email = email;
        } else if (phoneNumber) {
            apiPayload.phone_number = phoneNumber;
        }
        const res = yield apiPost(`/guest-invites/refresh`, apiPayload, true);

        if (res.data) {
            yield put(resendInviteCodeSuccess());
        } else {
            yield put(
                resendInviteCodeFailure(
                    res?.error?.response?.data?.error ||
                        'Resend invite code validation failed. Please try again later.'
                )
            );
        }
    } catch (error) {
        resendInviteCodeFailure(error);
    }
}

/////////////////////////////////////////// Function Generators For Root Saga /////////////////////////////////////////

export function* getUserAuth() {
    yield takeLatest(CHECK_USER_AUTHENTICATED, userAuthWatch);
}

export function* loginUser() {
    yield takeLatest(LOGIN_USER_EMAIL_PASSWORD, loginUserEmailPassword);
}

// export function* createUserAccount() {
//   yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
// }

export function* sendingResetPasswordLink() {
    yield takeLatest(NON_AUTH_RESET_USER_PASSWORD, sendResetUserPasswordLink);
}

export function* logOutUser() {
    yield takeLatest(LOGOUT_USER, loggingUserOut);
}

export function* verifyingActionCode() {
    yield takeLatest(VERIFY_ACTION_CODE, verifyActionCode);
}

export function* resettingUserPassword() {
    yield takeLatest(EXECUTE_RESET_PASSWORD_FLIGHT, resetUserPassword);
}

export function* updateAuthPassword() {
    yield takeLatest(UPDATE_AUTH_USER_PASSWORD, updateUserPassword);
}

export function* updateAuthEmail() {
    yield takeLatest(UPDATE_AUTH_USER_EMAIL, updateUserEmail);
}

export function* resetManagerPassword() {
    yield takeLatest(RESET_INITIAL_MANAGER_PASSWORD, resetInitialManagerPassword);
}

export function* validateInviteCodeRequest() {
    yield takeLatest(VALIDATE_INVITE_CODE, validateInviteCode);
}

export function* addAuthUserRequest() {
    yield takeLatest(CREATE_AUTH_USER, addAuthUser);
}

export function* resendInviteCodeRequest() {
    yield takeLatest(RESEND_INVITE_CODE, resendInviteCode);
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////// Root Saga //////////////////////////////////////////////////////

export default function* rootSaga() {
    yield all([
        fork(loginUser),
        fork(getUserAuth),
        // fork(createUserAccount),
        fork(sendingResetPasswordLink),
        fork(logOutUser),
        fork(verifyingActionCode),
        fork(resettingUserPassword),
        fork(updateAuthPassword),
        fork(updateAuthEmail),
        fork(resetManagerPassword),
        fork(validateInviteCodeRequest),
        fork(addAuthUserRequest),
        fork(resendInviteCodeRequest)
    ]);
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
