import React, { useEffect, useState } from 'react';
import { Badge, Box, IconButton, Tooltip } from '@mui/material';
import { ScanOutlined } from '@ant-design/icons';
import ScannerDetailsModal from './ScannerDetailsModal';
import { openCompactModal } from '../../../../../utils/modal/modal.helper';
import wsClient from '../../../../../utils/web-socket-client';

const ScannerConfiguration = () => {
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        setIsConnected(wsClient.isConnected());

        const handleConnect = () => setIsConnected(true);
        const handleDisconnect = () => setIsConnected(false);

        wsClient.onConnect(handleConnect);
        wsClient.onDisconnect(handleDisconnect);

        return () => {
            wsClient.removeConnectListener(handleConnect);
            wsClient.removeDisconnectListener(handleDisconnect);
        };
    }, []);

    const handleOpenScannerModal = () => {
        openCompactModal(ScannerDetailsModal, {
            onSave: (ip, port) => {
                localStorage.setItem('scanner_ip', ip);
                localStorage.setItem('scanner_port', port);
                wsClient.connect(ip, port);
            },
            isConnected
        });
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <Tooltip title="Sage WebSocket Scanner Server Configuration" placement="left">
                <IconButton
                    color="secondary"
                    sx={{ color: 'text.primary' }}
                    aria-label="open profile"
                    aria-haspopup="true"
                    onClick={handleOpenScannerModal}
                >
                    <Badge
                        color={isConnected ? 'primary' : 'error'}
                        badgeContent={''}
                        sx={{
                            '& .MuiBadge-badge': {
                                width: 12,
                                height: 12,
                                minWidth: 0,
                                padding: 0,
                                borderRadius: '50%'
                            }
                        }}
                    >
                        <ScanOutlined />
                    </Badge>
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default ScannerConfiguration;
