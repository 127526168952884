import axios from '../axios';

export class AuthHelper {
    static async logoutUser() {
        localStorage.removeItem('accessToken');
        delete axios.defaults.headers.common.Authorization;
    }

    static getAccessToken() {
        return localStorage.getItem('accessToken');
    }

    static setAccessToken(accessToken) {
        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }
}
