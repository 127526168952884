import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect } from 'react';
import { login, logout, resetPassword } from 'store/slices/authSlice';
import { connect } from 'react-redux';
import axios from 'axios';
import { AuthHelper } from '../utils/helpers/auth.helper';

const AuthContext = createContext(null);

export const AuthProvider = ({ authUser, children }) => {
    useEffect(() => {
        const accessToken = AuthHelper.getAccessToken();

        if (accessToken) {
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        }
    }, []);

    return (
        <AuthContext.Provider
            value={{
                ...authUser,
                login,
                resetPassword,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node,
    authUser: PropTypes.object
};

const mapStateToProps = ({ auth }) => {
    const { authUser } = auth;
    return { authUser };
};

export default connect(mapStateToProps, {})(AuthProvider);

export function useAuth() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}
