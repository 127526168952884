// React
import React, { useState, useEffect, useCallback } from 'react';

// Assets
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { ScanOutlined } from '@ant-design/icons';

// Firebase
import { fsTimeStampNow } from 'config/firebase';

// Packages
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import { PatternFormat } from 'react-number-format';
import {
    Button,
    IconButton,
    Stack,
    Typography,
    List,
    ListItem,
    ListItemText,
    InputLabel,
    TextField,
    Grid,
    MenuItem,
    Select
} from '@mui/material';
import { Loader } from '../index';

// Styles
import '../../styles/image-zoom.css';

// Utils
import { visitorSchema, reasons, unitedStates } from 'utils/constants';
import wsClient from 'utils/web-socket-client';

// Image Recognition
import ImageRecognitionService from '../../services/image-recognition.service';

const NewVisitor = ({
    activePanel,
    setActivePanel,
    setZoomActive,
    accessConfirm,
    setAccessConfirm,
    denyConfirm,
    setDenyConfirm,
    members,
    orgs,
    panels,
    propertyId,
    setVisitor,
    userData,
    handleGateAccess,
    handleDisallowGateAccess,
    setAccessVehicle
}) => {
    const [zoomedId, setZoomedId] = useState(false);
    // const [zoomedVehicle, setZoomedVehicle] = useState(false);

    const [name, setName] = useState('');
    const [image, setImage] = useState(null);
    const [visitorImage, setVisitorImage] = useState(null);

    const [company, setCompany] = useState('');
    const [option, setOption] = useState(null);
    const [options, setOptions] = useState(null);
    const [reason, setReason] = useState(null);
    const [orgId, setOrgId] = useState(null);
    const [tag, setTag] = useState('');
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [year, setYear] = useState('');
    const [state, setState] = useState('');
    const [color, setColor] = useState('');
    const [vehicleNotes, setVehicleNotes] = useState('');
    const [vehicleOption, setVehicleOption] = useState(null);

    const [isScanning, setIsScanning] = useState(false);

    useEffect(() => {
        if (members && members.length) {
            const residents = members.filter(
                member =>
                    member?.properties &&
                    member?.properties[propertyId]?.role === 'resi' &&
                    !member?.super_suspend
            );
            const memberOptions = residents.map(resident => {
                return {
                    id: resident.uid,
                    name: `${resident.first_name} ${resident.last_name}`,
                    phone: { number: resident.phone?.number, xt: null }
                };
            });
            if (!orgId) setOrgId(residents[0]?.properties[propertyId]?.org_id);

            setOptions([...memberOptions]);
        } else {
            if (orgs && orgs?.length && activePanel) {
                const org = orgs.filter(org => org.org_id === activePanel.orgId)[0];
                const orgPhone = org?.phone?.primary;
                const orgXt = org?.phone?.primary_ext;

                setOptions([
                    {
                        id: 1,
                        name: 'Grounds Maintenance',
                        phone: { number: orgPhone, xt: orgXt }
                    },
                    {
                        id: 2,
                        name: 'Clubhouse/Office',
                        phone: { number: orgPhone, xt: orgXt }
                    },
                    { id: 3, name: 'Other', phone: { number: orgPhone, xt: orgXt } }
                ]);
                if (!orgId) setOrgId(activePanel.orgId);
            }
        }
    }, [
        members,
        setOptions,
        orgs,
        activePanel,
        propertyId,
        orgId,
        setOrgId,
        setDenyConfirm,
        setAccessConfirm
    ]);

    const panelList = activePanel
        ? panels[activePanel.orgId]
        : orgId
        ? panels[orgId]
        : [];

    const confirmOptions = accessConfirm ? panelList : reasons;

    // const getAccess = useCallback((keys, member) => {
    //     if (keys && keys.length) {
    //         return keys.some(key => {
    //             const isValid = key => {
    //                 const today = new Date();
    //                 const startTime = new Date();
    //                 const endTime = new Date();
    //                 const nowMs = timeStampNowSeconds() * 1000;
    //                 const nowSec = timeStampNowSeconds();
    //                 const day = today.toLocaleString('en-US', { weekday: 'short' });
    //                 const dayStr = day.toLowerCase();
    //                 const beginSec = key.access_begins ? key.access_begins.seconds : null;
    //                 const expireSec = key.access_expires
    //                     ? key.access_expires.seconds
    //                     : null;

    //                 /**** Valid Day ****/
    //                 const includesDay = key?.access_days?.length
    //                     ? key.access_days.includes(dayStr)
    //                     : true;

    //                 /**** Valid Time Window by Day ****/
    //                 const start = key.access_start_time
    //                     ? startTime.setHours(
    //                           key.access_start_time.hours,
    //                           key.access_start_time.minutes,
    //                           0,
    //                           0
    //                       )
    //                     : key.access_start_time;

    //                 const end = key.access_end_time
    //                     ? endTime.setHours(
    //                           key.access_end_time.hours,
    //                           key.access_end_time.minutes,
    //                           0,
    //                           0
    //                       )
    //                     : key.access_end_time;

    //                 const inTimeWindow =
    //                     !start && !end ? true : nowMs >= start && nowMs <= end;

    //                 /**** Valid Time Window by Total Duration ****/
    //                 const inExpireWindow = !beginSec
    //                     ? !expireSec
    //                         ? true
    //                         : expireSec >= nowSec
    //                     : beginSec <= nowSec
    //                     ? expireSec
    //                         ? expireSec >= nowSec
    //                         : true
    //                     : false;

    //                 /**** Valid by Suspension ****/
    //                 const suspended = key.suspended;
    //                 return includesDay && inTimeWindow && inExpireWindow && !suspended;
    //             };
    //             const hasAccess = isValid(key) && !member.super_suspend;
    //             setShowAccess(true);
    //             setAccess(hasAccess);
    //             return hasAccess;
    //         });
    //     }
    //     setAccess(false);
    //     setShowAccess(true);
    //     return false;
    // }, []);

    // useEffect(() => {
    //     if (member) {
    //         if (member.invited_at) {
    //             setShowAccess(true);
    //         }
    //         if (memberKeys) {
    //             getAccess(memberKeys, member);
    //         }
    //     }
    // }, [memberKeys, member, getAccess, setShowAccess]);

    // useEffect(() => {
    //     if (showAccess) {
    //         setOverride(!access);
    //     }
    // }, [access, showAccess, setOverride]);

    const handleZoom = useCallback(
        (type, state) => {
            setZoomActive(true);
            setZoomedId(type === 'id' ? state : false);
            //setZoomedVehicle(type === 'id' ? false : state);
        },
        [setZoomActive]
    );

    const getVehicle = () => {
        if (vehicleOption === 'no_vehicle') {
            if (vehicleNotes.trim() === '') return false;
            return {
                color: '',
                country: '',
                make: '',
                model: '',
                primary: false,
                state: '',
                tag: '',
                year: '',
                notes: vehicleNotes.trim()
            };
        } else if (vehicleOption === 'add_vehicle') {
            if (`${color}${make}${model}${state}${tag}${year}`.trim() === '')
                return false;
            return {
                color: color.trim(),
                country: state ? 'USA' : '',
                make: make.trim(),
                model: model.trim(),
                primary: false,
                state: state.trim(),
                tag: tag.toUpperCase().trim(),
                year: year.trim(),
                notes: vehicleNotes.trim()
            };
        } else {
            return false;
        }
    };

    const handleConfirmAccess = () => {
        if (accessConfirm) {
            const fsTime = fsTimeStampNow();
            const visitor = Object.assign(visitorSchema, {
                approval: {
                    approved_by: option.id.length > 1 ? option.id : userData.uid,
                    name:
                        option.id.length > 1
                            ? option.name
                            : `${userData.first_name} ${userData.last_name}`,
                    type: option.id.length > 1 ? 'resi' : option.name
                }, // { approved_by: resi or guard UID, name: name of resi or guard, type: resi, Grounds Maintenance, Clubhouse/Office, Other},
                denial: null, // { denied_by: guard UID, name: resi or guard, type: Resident Refusal, Not on List - No Answer, No Identification}
                access: true,
                company_name: company.trim() !== '' ? company.trim().toLowerCase() : null,
                created_at: fsTime,
                id: null,
                name: name.trim().toLowerCase(),
                photo_id: visitorImage || null,
                property_id: propertyId,
                vehicle: {
                    color: color.trim().length > 0 ? color : null,
                    country: state.trim().length > 0 ? 'USA' : null,
                    make: make.trim().length > 0 ? make : null,
                    model: model.trim().length > 0 ? model : null,
                    primary: null,
                    state: state.trim().length > 0 ? state : null,
                    tag: tag.trim().length > 0 ? tag.toUpperCase() : null,
                    updated_at: fsTime,
                    year: year.trim().length > 0 ? year : null,
                    notes: vehicleNotes.trim()
                }
            });
            setAccessVehicle({
                color: color.trim().length > 0 ? color : '',
                country: state.trim().length > 0 ? 'USA' : '',
                make: make.trim().length > 0 ? make : '',
                model: model.trim().length > 0 ? model : '',
                primary: false,
                state: state.trim().length > 0 ? state : '',
                tag: tag.trim().length > 0 ? tag.toUpperCase() : '',
                year: year.trim().length > 0 ? year : '',
                notes: vehicleNotes.trim()
            });
            setVisitor(visitor);
        } else {
            setAccessConfirm(true);
        }
    };

    const handleDenyAccess = () => {
        if (denyConfirm) {
            const fsTime = fsTimeStampNow();
            const visitor = Object.assign(visitorSchema, {
                approval: null, // { approved_by: resi or guard UID, name: name of resi or guard, type: resi, Grounds Maintenance, Clubhouse/Office, Other},
                denial: {
                    denied_by: option.id.length > 1 ? option.id : userData.uid,
                    name:
                        option.id.length > 1
                            ? option.name
                            : `${userData.first_name} ${userData.last_name}`,
                    type: reason.name
                }, // { denied_by: guard UID, name: resi or guard, type: Resident Refusal, Not on List - No Answer, No Identification}
                access: false,
                company_name: company.trim() !== '' ? company.trim().toLowerCase() : null,
                created_at: fsTime,
                id: null,
                name: name.trim().toLowerCase(),
                photo_id: visitorImage || null,
                property_id: propertyId,
                vehicle: {
                    color: color.trim().length > 0 ? color : null,
                    country: state.trim().length > 0 ? 'USA' : null,
                    make: make.trim().length > 0 ? make : null,
                    model: model.trim().length > 0 ? model : null,
                    primary: null,
                    state: state.trim().length > 0 ? state : null,
                    tag: tag.trim().length > 0 ? tag.toUpperCase() : null,
                    updated_at: fsTime,
                    year: year.trim().length > 0 ? year : null,
                    notes: vehicleNotes.trim()
                }
            });
            setAccessVehicle({
                color: color.trim().length > 0 ? color : '',
                country: state.trim().length > 0 ? 'USA' : '',
                make: make.trim().length > 0 ? make : '',
                model: model.trim().length > 0 ? model : '',
                primary: false,
                state: state.trim().length > 0 ? state : '',
                tag: tag.trim().length > 0 ? tag.toUpperCase() : '',
                year: year.trim().length > 0 ? year : '',
                notes: vehicleNotes.trim()
            });
            setVisitor(visitor);
        } else {
            setDenyConfirm(true);
        }
    };

    const handlePanelSelect = panel => {
        const panelData = {
            orgId: panel.orgId ? panel.orgId : orgId,
            id: panel.id,
            name: panel.name
        };
        setActivePanel({
            ...panelData
        });
    };

    const handleReasonSelect = reason => {
        setReason({
            ...reason
        });
    };

    useEffect(() => {
        const handleMessage = async eventData => {
            try {
                const data = JSON.parse(eventData);
                console.log('📡 WebSocket message received:', data);

                if (data.status === 'connection_opened') {
                    setIsScanning(true);
                    console.log('🔄 Connection opened, resetting image and name.');

                    setImage(null);
                    setName('');
                    return;
                }

                if (data.status === 'end_of_transmission') {
                    console.log('✅ Transmission complete.');
                    return;
                }

                if (data.fileName && data.content && data.fileName.endsWith('.jpg')) {
                    const uint8Array = new Uint8Array(data.content.data);
                    const blob = new Blob([uint8Array], { type: 'image/jpeg' });
                    const scannedImageURL = URL.createObjectURL(blob);

                    try {
                        const fullNameResp = await ImageRecognitionService.getFullName(blob);
                        if (fullNameResp) {
                            setName(fullNameResp);
                        }
                    } catch (nameError) {
                        console.error('❌ Failed to recognize full name from image:', nameError);
                    } finally {
                        setImage(scannedImageURL);
                        setVisitorImage(blob);
                        setIsScanning(false);
                    }
                }
            } catch (error) {
                console.error('🚨 Error parsing WebSocket message:', error);
            }
        };

        wsClient.addMessageListener(handleMessage);
        return () => {
            wsClient.removeMessageListener(handleMessage);
        };
    }, []);

    return (
        <>
            {isScanning && <Loader />}
            <Stack
                sx={{
                    pt: 1,
                    pl: 3,
                    pr: 3,
                    justifyContent: 'space-between',
                    minHeight: '90vh'
                }}
            >
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    }}
                >
                    {options && options.length > 0 && (
                        <Stack
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                                marginBottom: '2vh'
                            }}
                        >
                            <List
                                sx={{
                                    width: '100%',
                                    overflowY: 'auto',
                                    maxHeight: '10vh'
                                }}
                            >
                                {options &&
                                    options.map(op => (
                                        <ListItem
                                            key={`${op.name}${op.phone.number}`}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '2.5vh',
                                                marginBottom: '.5vh',
                                                backgroundColor:
                                                    option?.id === op.id
                                                        ? 'rgba(129, 250, 185, 1)'
                                                        : 'transparent',
                                                '&:hover': {
                                                    backgroundColor:
                                                        'rgba(129, 250, 185, 0.6)'
                                                }
                                            }}
                                            button={true}
                                            disableGutters
                                            onClick={() => setOption(op)}
                                        >
                                            <ListItemText
                                                sx={{
                                                    left: '4%'
                                                }}
                                                primary={
                                                    <Stack
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                width: '50%'
                                                            }}
                                                            component="span"
                                                            variant="body1"
                                                            color="text.primary"
                                                            textTransform="capitalize"
                                                        >
                                                            {op.name}
                                                        </Typography>
                                                        <Stack
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent:
                                                                    'flex-start',
                                                                alignItems: 'center',
                                                                width: '50%'
                                                            }}
                                                        >
                                                            {op.phone.number && (
                                                                <Typography
                                                                    sx={{
                                                                        marginRight:
                                                                            '.5vw'
                                                                    }}
                                                                    component="span"
                                                                    variant="body1"
                                                                    color="text.primary"
                                                                >
                                                                    <PatternFormat
                                                                        value={
                                                                            op.phone
                                                                                .number
                                                                        }
                                                                        displayType={
                                                                            'text'
                                                                        }
                                                                        format="###-###-####"
                                                                        mask="_"
                                                                    />
                                                                </Typography>
                                                            )}
                                                            {op.phone.xt && (
                                                                <Typography
                                                                    component="span"
                                                                    variant="body1"
                                                                    color="text.primary"
                                                                >
                                                                    {`XT - ${op.phone.xt}`}
                                                                </Typography>
                                                            )}
                                                        </Stack>
                                                    </Stack>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                            </List>
                        </Stack>
                    )}
                    <Stack
                        sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <TextField
                            sx={{
                                marginBottom: '2vh',
                                marginTop: '2vh'
                            }}
                            inputProps={{
                                sx: {
                                    height: '3vh',
                                    width: '100%',
                                    borderRadius: '4px',
                                    border: '1px solid rgba(239, 239, 239, 1)'
                                }
                            }}
                            type="text"
                            placeholder="Name"
                            onChange={e => setName(e.target.value)}
                            value={name}
                        />
                        <TextField
                            inputProps={{
                                sx: {
                                    height: '3vh',
                                    width: '100%',
                                    borderRadius: '4px',
                                    border: '1px solid rgba(239, 239, 239, 1)'
                                }
                            }}
                            type="text"
                            placeholder="Company"
                            onChange={e => setCompany(e.target.value)}
                            value={company}
                        />
                    </Stack>
                    <Stack
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            width: '100%',
                            marginTop: '4vh'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '12px',
                                marginBottom: '1vh',
                                fontWeight: '700'
                            }}
                            component="span"
                            variant="body1"
                        >
                            Identification:
                        </Typography>
                        <Stack
                            sx={{
                                height: '16.5vh',
                                width: '100%',
                                borderRadius: '1em'
                            }}
                        >
                            {image ? (
                                <ControlledZoom
                                    isZoomed={zoomedId}
                                    onZoomChange={handleZoom}
                                >
                                    <img
                                        style={{
                                            height: '18.5vh',
                                            width: '100%',
                                            borderRadius: '2em',
                                            objectFit: 'fill'
                                        }}
                                        id="scannedImage"
                                        loading="lazy"
                                        alt="Identification"
                                        src={image}
                                    />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            bottom: '1.5vh',
                                            right: '.75vw',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <IconButton
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '2.8vh',
                                                height: '2.8vh',
                                                backgroundColor: 'rgba(0, 0, 0, 0.36)',
                                                borderRadius: '8px',
                                                padding: 0,
                                                margin: 0
                                            }}
                                            onClick={() => handleZoom('id', !zoomedId)}
                                        >
                                            {!zoomedId ? (
                                                <ZoomOutMapIcon
                                                    style={{
                                                        width: '75%',
                                                        color: 'rgba(255,255,255,1)',
                                                        padding: 0,
                                                        margin: 0
                                                    }}
                                                />
                                            ) : (
                                                <ZoomInMapIcon
                                                    style={{
                                                        width: '75%',
                                                        color: 'rgba(255,255,255,1)',
                                                        padding: 0,
                                                        margin: 0
                                                    }}
                                                />
                                            )}
                                        </IconButton>
                                    </div>
                                </ControlledZoom>
                            ) : (
                                <Button
                                    sx={{
                                        height: '100%',
                                        width: '100%',
                                        backgroundColor: 'rgba(208, 208, 208, 1)',
                                        borderRadius: '1em',
                                        '&:active::after': {
                                            borderRadius: '1em'
                                        },
                                        '&:hover': {
                                            backgroundColor: 'rgba(200, 200, 200, 1)'
                                        }
                                    }}
                                >
                                    <Stack
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            width: '100%'
                                        }}
                                    >
                                        <ScanOutlined
                                            style={{
                                                color: 'rgba(255,255,255,1)',
                                                fontSize: '5vw'
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: '18px',
                                                fontWeight: 'bold',
                                                width: '100%',
                                                textAlign: 'center',
                                                color: 'rgba(255,255,255,1)'
                                            }}
                                            component="span"
                                            variant="body1"
                                            textTransform="capitalize"
                                        >
                                            {isScanning ? 'Scanning...' : 'Scan Photo ID'}
                                        </Typography>
                                    </Stack>
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                    <Stack
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            width: '100%',
                            marginTop: '4vh'
                        }}
                    >
                        <Stack
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                width: '100%',
                                marginBottom: '1vh'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: 700,
                                    marginRight: '.25vw'
                                }}
                                component="span"
                                variant="body1"
                                color="error"
                            >
                                *
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: 700
                                }}
                                component="span"
                                variant="body1"
                            >
                                Vehicle:
                            </Typography>
                        </Stack>
                        <Stack
                            sx={{
                                width: '100%'
                            }}
                        >
                            <Stack
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    height: '6vh',
                                    width: '100%'
                                }}
                            >
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <IconButton
                                            sx={{
                                                width: '100%',
                                                height: '4.5vh',
                                                borderRadius: '26.5px',
                                                '&:active::after': {
                                                    borderRadius: '26.5px'
                                                },
                                                fontSize: '0.9375rem',
                                                fontWeight: '600',
                                                backgroundColor: 'rgba(58, 230, 137, 1)',
                                                '&:hover': {
                                                    backgroundColor: '#13af74'
                                                }
                                            }}
                                            onClick={() => {
                                                setVehicleOption('add_vehicle');
                                            }}
                                            variant="contained"
                                            aria-label="add"
                                        >
                                            <PlusCircleOutlined
                                                style={{
                                                    color: 'rgba(255,255,255,1)',
                                                    fontSize: '1.5em'
                                                }}
                                            />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            sx={{
                                                width: '100%',
                                                height: '4.5vh',
                                                borderRadius: '26.5px',
                                                '&:active::after': {
                                                    borderRadius: '26.5px'
                                                },
                                                fontSize: '0.9375rem',
                                                fontWeight: '600',
                                                backgroundColor: 'rgba(230, 58, 58, 1)',
                                                '&:hover': {
                                                    backgroundColor: '#af1313'
                                                }
                                            }}
                                            onClick={() => {
                                                setVehicleOption('no_vehicle');
                                            }}
                                            variant="contained"
                                        >
                                            No Vehicle
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Stack>
                            {/* {!accessConfirm && !denyConfirm && ( */}
                            <Stack style={{ marginTop: '1.5vh', marginBottom: '3vh' }}>
                                {vehicleOption && vehicleOption === 'add_vehicle' && (
                                    <Grid container columnSpacing={2} rowSpacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <Stack>
                                                <InputLabel htmlFor={'tag'}>
                                                    Plate #
                                                </InputLabel>
                                                <TextField
                                                    name="tag"
                                                    id={'tagInput'}
                                                    placeholder="Plate/Tag #"
                                                    inputProps={{
                                                        style: {
                                                            textTransform: 'uppercase'
                                                        }
                                                    }}
                                                    onChange={e => setTag(e.target.value)}
                                                    value={tag}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Stack>
                                                <InputLabel htmlFor={'state'}>
                                                    State
                                                </InputLabel>
                                                <Select
                                                    label={'State'}
                                                    name={'state'}
                                                    variant="outlined"
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 48 * 3.5 + 8
                                                            }
                                                        }
                                                    }}
                                                    onChange={e =>
                                                        setState(e.target.value)
                                                    }
                                                    value={state}
                                                >
                                                    {unitedStates.map(state => (
                                                        <MenuItem
                                                            key={state.value}
                                                            value={state.value}
                                                        >
                                                            {state.value}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Stack>
                                                <InputLabel htmlFor={'make'}>
                                                    Make
                                                </InputLabel>
                                                <TextField
                                                    name="make"
                                                    onChange={e =>
                                                        setMake(e.target.value)
                                                    }
                                                    value={make}
                                                    placeholder="Make"
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Stack>
                                                <InputLabel htmlFor={'model'}>
                                                    Model
                                                </InputLabel>
                                                <TextField
                                                    name="model"
                                                    placeholder="Model"
                                                    onChange={e =>
                                                        setModel(e.target.value)
                                                    }
                                                    value={model}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Stack>
                                                <InputLabel htmlFor={'color'}>
                                                    Color
                                                </InputLabel>
                                                <TextField
                                                    name="color"
                                                    onChange={e =>
                                                        setColor(e.target.value)
                                                    }
                                                    value={color}
                                                    placeholder="Color"
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Stack>
                                                <InputLabel htmlFor={'year'}>
                                                    Year
                                                </InputLabel>
                                                <TextField
                                                    name="year"
                                                    placeholder="Year"
                                                    onChange={e =>
                                                        setYear(e.target.value)
                                                    }
                                                    value={year}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                )}
                                {vehicleOption && vehicleOption === 'no_vehicle' && (
                                    <Grid container columnSpacing={2} rowSpacing={1}>
                                        <Grid item xs={12} sm={12}>
                                            <Stack>
                                                <InputLabel htmlFor={'notes'}>
                                                    No Vehicle Notes
                                                </InputLabel>
                                                <TextField
                                                    name="vehicleNotes"
                                                    id={'notes'}
                                                    placeholder="Reason..."
                                                    multiline
                                                    rows={4}
                                                    fullWidth
                                                    onChange={e =>
                                                        setVehicleNotes(e.target.value)
                                                    }
                                                    value={vehicleNotes}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                )}
                            </Stack>
                            {/* )} */}
                            {/* {vehicle?.photo_id ? (
                        <>
                            <ControlledZoom
                                isZoomed={zoomedVehicle}
                                onZoomChange={handleZoom}
                            >
                                <img
                                    style={{
                                        height: '18.5vh',
                                        width: '100%',
                                        borderRadius: '2em',
                                        objectFit: 'fill'
                                    }}
                                    src={vehicle.photo_id}
                                    loading="lazy"
                                    alt={'vehicle'}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '1.5vh',
                                        right: '.75vw',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <IconButton
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '2.8vh',
                                            height: '2.8vh',
                                            backgroundColor: 'rgba(0, 0, 0, 0.36)',
                                            borderRadius: '8px',
                                            padding: 0,
                                            margin: 0
                                        }}
                                        onClick={() =>
                                            handleZoom('vehicle', !zoomedVehicle)
                                        }
                                    >
                                        {!zoomedId ? (
                                            <ZoomOutMapIcon
                                                style={{
                                                    width: '75%',
                                                    color: 'rgba(255,255,255,1)',
                                                    padding: 0,
                                                    margin: 0
                                                }}
                                            />
                                        ) : (
                                            <ZoomInMapIcon
                                                style={{
                                                    width: '75%',
                                                    color: 'rgba(255,255,255,1)',
                                                    padding: 0,
                                                    margin: 0
                                                }}
                                            />
                                        )}
                                    </IconButton>
                                </div>
                            </ControlledZoom>
                            <Stack style={{ marginTop: '1.5vh', marginBottom: '1vh' }}>
                                <Stack
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start'
                                    }}
                                >
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '50%'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                width: '25%'
                                            }}
                                            component="span"
                                            variant="body1"
                                            textTransform="capitalize"
                                        >
                                            {`Make: `}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                            }}
                                            component="span"
                                            variant="body1"
                                            textTransform="capitalize"
                                        >
                                            {vehicle.make}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '50%'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                width: '25%'
                                            }}
                                            component="span"
                                            variant="body1"
                                            textTransform="capitalize"
                                        >
                                            {`Model: `}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '12px'
                                            }}
                                            component="span"
                                            variant="body1"
                                            textTransform="capitalize"
                                        >
                                            {vehicle.model}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        marginTop: '.5vh'
                                    }}
                                >
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '50%'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                width: '25%'
                                            }}
                                            component="span"
                                            variant="body1"
                                            textTransform="capitalize"
                                        >
                                            {`Plate: `}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '12px'
                                            }}
                                            component="span"
                                            variant="body1"
                                            textTransform="capitalize"
                                        >
                                            {`${vehicle.tag}/${vehicle.state}`}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '50%'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                width: '25%'
                                            }}
                                            component="span"
                                            variant="body1"
                                            textTransform="capitalize"
                                        >
                                            {`Color: `}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                width: '50%'
                                            }}
                                            component="span"
                                            variant="body1"
                                            textTransform="capitalize"
                                        >
                                            {vehicle.color}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </>
                    ) : ( */}
                            {/* <Button
                        sx={{
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(208, 208, 208, 1)',
                            borderRadius: '2em',
                            '&:active::after': {
                                borderRadius: '2em'
                            },
                            '&:hover': {
                                backgroundColor: 'rgba(200, 200, 200, 1)'
                            }
                        }}
                    >
                        <Stack
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%'
                            }}
                        >
                            <AddAPhotoIcon
                                style={{
                                    color: 'rgba(255,255,255,1)',
                                    fontSize: '7vw'
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: '24px',
                                    width: '100%',
                                    textAlign: 'center',
                                    color: 'rgba(255,255,255,1)'
                                }}
                                component="span"
                                variant="body1"
                                textTransform="capitalize"
                            >
                                Capture Vehicle
                            </Typography>
                        </Stack>
                    </Button> */}
                            {/* )} */}
                        </Stack>
                    </Stack>

                    {(accessConfirm || denyConfirm) && (
                        <Stack
                            sx={{
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                marginTop: 3,
                                marginBottom: 3,
                                backgroundColor: accessConfirm
                                    ? 'rgba(58, 230, 137, 0.14)'
                                    : 'rgba(230, 58, 58, 0.13)',
                                height: '20vh',
                                width: '100%',
                                borderRadius: '9px',
                                border: `1px solid ${
                                    accessConfirm
                                        ? 'rgba(58, 230, 137, 1)'
                                        : 'rgba(230, 58, 58, 1)'
                                }`
                            }}
                        >
                            <Stack
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    backgroundColor: accessConfirm
                                        ? 'rgba(58, 230, 137, 1)'
                                        : 'rgba(230, 58, 58, 1)',
                                    height: '23%',
                                    width: '100%',
                                    borderTopLeftRadius: '9px',
                                    borderTopRightRadius: '9px',
                                    border: `1px solid ${
                                        accessConfirm
                                            ? 'rgba(58, 230, 137, 1)'
                                            : 'rgba(230, 58, 58, 0.51)'
                                    }`,
                                    margin: 0,
                                    padding: 0
                                }}
                            >
                                <Typography
                                    sx={{
                                        marginLeft: '4%',
                                        fontSize: '12px',
                                        fontWeight: '700',
                                        width: '80%'
                                    }}
                                    component="span"
                                    variant="body1"
                                    textTransform="capitalize"
                                >
                                    {accessConfirm ? 'Select Gate' : 'Select Reason'}
                                </Typography>
                                <IconButton
                                    onClick={() => {
                                        if (accessConfirm) {
                                            setAccessConfirm(false);
                                        } else {
                                            setDenyConfirm(false);
                                        }
                                    }}
                                    aria-label="close"
                                >
                                    <CloseCircleOutlined
                                        style={{ color: 'rgba(255,255,255,1)' }}
                                    />
                                </IconButton>
                            </Stack>
                            <List
                                sx={{
                                    width: '100%',
                                    overflowY: 'auto',
                                    marginTop: '.5vh',
                                    maxHeight: '70%'
                                }}
                            >
                                {confirmOptions?.map(option => (
                                    <ListItem
                                        key={option.id}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            height: '3.5vh',
                                            width: '95%',
                                            backgroundColor:
                                                accessConfirm &&
                                                activePanel?.id === option.id
                                                    ? 'rgba(129, 250, 185, 1)'
                                                    : denyConfirm &&
                                                      reason?.id === option.id
                                                    ? 'rgba(252, 169, 169, 1)'
                                                    : 'transparent',
                                            borderRadius: '16px',
                                            border:
                                                accessConfirm &&
                                                activePanel?.id !== option.id
                                                    ? '1px solid rgba(129, 250, 185, 1)'
                                                    : denyConfirm &&
                                                      reason?.id !== option.id
                                                    ? '1px solid rgba(252, 169, 169, 1)'
                                                    : 'none',
                                            paddingX: '4%',
                                            marginLeft: '2.5%',
                                            marginBottom: '.5vh'
                                        }}
                                        button={true}
                                        disableGutters
                                        onClick={() => {
                                            accessConfirm
                                                ? handlePanelSelect(option)
                                                : handleReasonSelect(option);
                                        }}
                                    >
                                        <ListItemText
                                            sx={{
                                                left: '4%'
                                            }}
                                            primary={
                                                <Typography
                                                    sx={{
                                                        display: 'block'
                                                    }}
                                                    component="span"
                                                    variant="body1"
                                                    color="text.primary"
                                                >
                                                    {option.name}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Stack>
                    )}
                </Stack>
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        height: '6vh',
                        marginTop: 2
                    }}
                >
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Button
                                sx={{
                                    width: '100%',
                                    height: '4.5vh',
                                    marginBottom: 3,
                                    borderRadius: '26.5px',
                                    '&:active::after': {
                                        borderRadius: '26.5px'
                                    },
                                    fontSize: '0.9375rem',
                                    fontWeight: '600',
                                    backgroundColor: 'rgba(58, 230, 137, 1)',
                                    '&:disabled': {
                                        backgroundColor: 'rgba(58, 230, 137, .7)'
                                    },
                                    '&:hover': {
                                        backgroundColor: '#13af74'
                                    }
                                }}
                                disabled={
                                    name.trim() === '' ||
                                    !option ||
                                    denyConfirm ||
                                    (accessConfirm && !activePanel) ||
                                    (accessConfirm && !getVehicle())
                                }
                                onClick={handleConfirmAccess}
                                variant="contained"
                            >
                                {accessConfirm ? 'Confirm' : 'Grant Access'}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                sx={{
                                    width: '100%',
                                    height: '4.5vh',
                                    marginBottom: 3,
                                    borderRadius: '26.5px',
                                    '&:active::after': {
                                        borderRadius: '26.5px'
                                    },
                                    fontSize: '0.9375rem',
                                    fontWeight: '600',
                                    backgroundColor: 'rgba(230, 58, 58, 1)',
                                    '&:disabled': {
                                        backgroundColor: 'rgba(230, 58, 58, .7)'
                                    },
                                    '&:hover': {
                                        backgroundColor: '#af1313'
                                    }
                                }}
                                disabled={
                                    name.trim() === '' ||
                                    !option ||
                                    accessConfirm ||
                                    (denyConfirm && !reason) ||
                                    (denyConfirm && !getVehicle())
                                }
                                onClick={handleDenyAccess}
                                variant="contained"
                            >
                                {denyConfirm ? 'Confirm' : 'Deny Access'}
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
        </>
    );
};
export default NewVisitor;
