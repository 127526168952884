import store from 'store';
import { openSnackbar } from 'store/actions/Snackbar';

class WebSocketClient {
    static instance = null;
    socket = null;
    listeners = new Set();
    connectListeners = new Set();
    disconnectListeners = new Set();
    ip = null;
    port = null;
    isManuallyClosed = false;

    static getInstance() {
        if (!WebSocketClient.instance) {
            WebSocketClient.instance = new WebSocketClient();
        }
        return WebSocketClient.instance;
    }

    connect(ip, port) {
        this.ip = ip;
        this.port = port;
        this.isManuallyClosed = false;

        const url = `ws://${ip}:${port}`;
        console.log('Attempting to connect to', url);
        this.socket = new WebSocket(url);

        this.socket.onopen = () => {
            console.log('✅ WebSocket connected');

            this.connectListeners.forEach(cb => cb());
        };

        this.socket.onmessage = event => {
            this.listeners.forEach(cb => cb(event.data));
        };

        this.socket.onclose = () => {
            console.warn('⚠️ WebSocket closed');

            this.disconnectListeners.forEach(cb => cb());
        };

        this.socket.onerror = error => {
            console.error('❌ WebSocket error:', error);

            store.dispatch(
                openSnackbar({
                    open: true,
                    message:
                        'Unable to connect to the Sage WebSocket Scanner server! Please try again.',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    },
                    close: false
                })
            );

            this.socket.close();
        };
    }

    disconnect() {
        this.isManuallyClosed = true;
        if (this.socket) {
            this.socket.close();
            localStorage.removeItem('scanner_ip');
            localStorage.removeItem('scanner_port');
            console.log('Disconnected');
        }
    }

    initFromLocalStorage() {
        const ip = localStorage.getItem('scanner_ip');
        const port = localStorage.getItem('scanner_port');
        if (ip && port) {
            this.connect(ip, port);
        }
    }

    send(data) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(data);
        } else {
            console.warn('⚠️ WebSocket is not connected. Cannot send data.');
        }
    }

    addMessageListener(callback) {
        this.listeners.add(callback);
    }

    removeMessageListener(callback) {
        this.listeners.delete(callback);
    }

    isConnected() {
        return this.socket && this.socket.readyState === WebSocket.OPEN;
    }

    onConnect(callback) {
        this.connectListeners.add(callback);
    }

    onDisconnect(callback) {
        this.disconnectListeners.add(callback);
    }

    removeConnectListener(callback) {
        this.connectListeners.delete(callback);
    }

    removeDisconnectListener(callback) {
        this.disconnectListeners.delete(callback);
    }
}

export default WebSocketClient.getInstance();
