import BaseService from './base.service';

export default class ImageRecognitionService {
    static ENDPOINT = '/image-recognitions';

    static async getFullName(file) {
        const endpoint = `${this.ENDPOINT}/full-name`;
        const formData = new FormData();
        formData.append('file', file);

        return BaseService.postFormData(endpoint, formData);
    }
}